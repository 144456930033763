import locale from '@aligov/global-locale';
import getCurrentLocales from './getCurrentLocales';
export var LANGUAGE_KEY_NAME;
(function (LANGUAGE_KEY_NAME) {
  LANGUAGE_KEY_NAME["ZhCN"] = "zh-CN";
  LANGUAGE_KEY_NAME["EnUs"] = "en-US";
})(LANGUAGE_KEY_NAME || (LANGUAGE_KEY_NAME = {}));
/**
 * get current language
 */
var getCurrentLanguage = function getCurrentLanguage() {
  var lang = LANGUAGE_KEY_NAME.EnUs;
  return lang;
};
/**
 * change current language
 * @param {string} langTag language tag config above
 */
var changeLanguage = function changeLanguage(langTag) {
  locale.setLang(langTag);
};
var formatMessage = function formatMessage(_ref, locales) {
  var id = _ref.id,
    defaultMessage = _ref.defaultMessage,
    variable = _ref.variable;
  if (variable) {
    // todo 变量转换
  }
  var lang = LANGUAGE_KEY_NAME.EnUs;
  var currrentLocales = getCurrentLocales(locales, lang);
  return (currrentLocales === null || currrentLocales === void 0 ? void 0 : currrentLocales[id]) || defaultMessage || id;
};
export { formatMessage, getCurrentLanguage, changeLanguage };