import $i18n from '../../i18n';
import { defaultConfig } from './registerTransform';
var _defaultConfig$advanc = defaultConfig.advanced,
  icon = _defaultConfig$advanc.icon,
  keyshape = _defaultConfig$advanc.keyshape,
  label = _defaultConfig$advanc.label,
  badge = _defaultConfig$advanc.badge;
var registerMeta = function registerMeta(context) {
  var schemaData = context.schemaData;
  var schema = {
    type: 'object',
    properties: {
      size: {
        title: $i18n.get({
          id: 'basic.elements.SimpleNode.registerMeta.Size',
          dm: 'Size'
        }),
        type: 'number',
        'x-decorator': 'FormItem',
        'x-component': 'NumberPicker',
        default: defaultConfig.size
      },
      color: {
        title: $i18n.get({
          id: 'basic.elements.SimpleNode.registerMeta.Color',
          dm: 'Color'
        }),
        type: 'string',
        'x-decorator': 'FormItem',
        'x-component': 'ColorInput',
        default: defaultConfig.color
      },
      label: {
        title: $i18n.get({
          id: 'basic.elements.SimpleNode.registerMeta.Text',
          dm: 'Text'
        }),
        type: 'string',
        'x-decorator': 'FormItem',
        'x-component': 'GroupSelect',
        'x-component-props': {
          mode: 'multiple',
          schemaData: schemaData.nodes
        }
      },
      advancedPanel: {
        type: 'void',
        'x-decorator': 'FormItem',
        'x-component': 'FormCollapse',
        'x-component-props': {
          className: 'gi-assets-elements-advance-panel',
          // style: { background: 'blue' },
          ghost: true
        },
        properties: {
          advanced: {
            type: 'object',
            'x-component': 'FormCollapse.CollapsePanel',
            'x-component-props': {
              header: $i18n.get({
                id: 'basic.elements.SimpleNode.registerMeta.AdvancedConfiguration',
                dm: 'Advanced Configuration'
              }),
              // 暂时不SettingsAdvanced Configuration默认Fold，No则下面的 visible 控制就失效了
              key: 'advanced-panel'
            },
            properties: {
              panel: {
                type: 'void',
                'x-decorator': 'FormItem',
                'x-component': 'FormCollapse',
                'x-component-props': {
                  className: 'gi-assets-elements-panel',
                  style: {
                    // background: 'red',
                    // margin: '-16px',
                  },
                  ghost: true
                },
                properties: {
                  icon: {
                    type: 'object',
                    'x-decorator': 'FormItem',
                    'x-component': 'FormCollapse.CollapsePanel',
                    'x-component-props': {
                      header: $i18n.get({
                        id: 'basic.elements.SimpleNode.registerMeta.Icon',
                        dm: 'Icon'
                      }),
                      key: 'icon-panel'
                    },
                    properties: {
                      visible: {
                        type: 'boolean',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleNode.registerMeta.Visible',
                          dm: 'Visible'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'Switch',
                        'x-reactions': [{
                          target: 'advanced.icon.type',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }, {
                          target: 'advanced.icon.value',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }, {
                          target: 'advanced.icon.fill',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }, {
                          target: 'advanced.icon.size',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }]
                      },
                      type: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleNode.registerMeta.Type',
                          dm: 'Type'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'Select',
                        enum: [{
                          label: $i18n.get({
                            id: 'basic.elements.SimpleNode.registerMeta.Text',
                            dm: 'Text'
                          }),
                          value: 'text'
                        }, {
                          label: $i18n.get({
                            id: 'basic.elements.SimpleNode.registerMeta.FontIcon',
                            dm: 'Font icon'
                          }),
                          value: 'font'
                        }],
                        default: icon.type
                      },
                      value: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleNode.registerMeta.Icon',
                          dm: 'Icon'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'IconPicker',
                        default: icon.value
                      },
                      fill: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleNode.registerMeta.Color',
                          dm: 'Color'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'ColorInput',
                        default: icon.fill
                      }
                      // size: {
                      //   type: 'string',
                      //   title: 'Size',
                      //   'x-decorator': 'FormItem',
                      //   'x-component': 'NumberPicker',
                      //   default: icon.size,
                      // },
                    }
                  },
                  keyshape: {
                    type: 'object',
                    'x-decorator': 'FormItem',
                    'x-component': 'FormCollapse.CollapsePanel',
                    'x-component-props': {
                      header: $i18n.get({
                        id: 'basic.elements.SimpleNode.registerMeta.Node',
                        dm: 'Node'
                      }),
                      key: 'keyshape-panel'
                    },
                    properties: {
                      fillOpacity: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleNode.registerMeta.Transparency',
                          dm: 'Transparency'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'NumberPicker',
                        max: 1,
                        min: 0,
                        default: keyshape.fillOpacity
                      }
                    }
                  },
                  label: {
                    type: 'object',
                    'x-component': 'FormCollapse.CollapsePanel',
                    'x-component-props': {
                      header: $i18n.get({
                        id: 'basic.elements.SimpleNode.registerMeta.Text',
                        dm: 'Text'
                      }),
                      key: 'label-panel'
                    },
                    properties: {
                      visible: {
                        type: 'boolean',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleNode.registerMeta.Switch',
                          dm: 'Switch'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'Switch',
                        default: label.visible,
                        'x-reactions': [{
                          target: 'advanced.label.fill',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }, {
                          target: 'advanced.label.fontSize',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }, {
                          target: 'advanced.label.position',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }]
                      },
                      fill: {
                        title: $i18n.get({
                          id: 'basic.elements.SimpleNode.registerMeta.Color',
                          dm: 'Color'
                        }),
                        type: 'string',
                        'x-decorator': 'FormItem',
                        'x-component': 'ColorInput',
                        default: label.fill
                      },
                      fontSize: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleNode.registerMeta.Size',
                          dm: 'Size'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'NumberPicker',
                        max: 100,
                        min: 12,
                        default: label.fontSize
                      },
                      position: {
                        title: $i18n.get({
                          id: 'basic.elements.SimpleNode.registerMeta.Location',
                          dm: 'Location'
                        }),
                        type: 'string',
                        'x-decorator': 'FormItem',
                        'x-component': 'Select',
                        enum: [{
                          label: $i18n.get({
                            id: 'basic.elements.SimpleNode.registerMeta.Top',
                            dm: 'Top'
                          }),
                          value: 'top'
                        }, {
                          label: $i18n.get({
                            id: 'basic.elements.SimpleNode.registerMeta.Bottom',
                            dm: 'Bottom'
                          }),
                          value: 'bottom'
                        }, {
                          label: $i18n.get({
                            id: 'basic.elements.SimpleNode.registerMeta.LeftSide',
                            dm: 'Left side'
                          }),
                          value: 'left'
                        }, {
                          label: $i18n.get({
                            id: 'basic.elements.SimpleNode.registerMeta.RightSide',
                            dm: 'Right side'
                          }),
                          value: 'right'
                        }, {
                          label: $i18n.get({
                            id: 'basic.elements.SimpleNode.registerMeta.Middle',
                            dm: 'Middle'
                          }),
                          value: 'center'
                        }],
                        default: label.position
                      }
                    }
                  },
                  badge: {
                    type: 'object',
                    'x-component': 'FormCollapse.CollapsePanel',
                    'x-component-props': {
                      header: $i18n.get({
                        id: 'basic.elements.SimpleNode.registerMeta.Logo',
                        dm: 'Logo'
                      }),
                      key: 'badge-panel'
                    },
                    properties: {
                      visible: {
                        type: 'boolean',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleNode.registerMeta.Visible',
                          dm: 'Visible'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'Switch',
                        default: badge.visible,
                        'x-reactions': [{
                          target: 'advanced.badge.type',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }, {
                          target: 'advanced.badge.value',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }]
                      },
                      type: {
                        title: $i18n.get({
                          id: 'basic.elements.SimpleNode.registerMeta.Type',
                          dm: 'Type'
                        }),
                        type: 'string',
                        'x-decorator': 'FormItem',
                        'x-component': 'Select',
                        enum: [{
                          label: $i18n.get({
                            id: 'basic.elements.SimpleNode.registerMeta.FieldMapping',
                            dm: 'Field Mapping'
                          }),
                          value: 'mapping'
                        }, {
                          label: $i18n.get({
                            id: 'basic.elements.SimpleNode.registerMeta.Text',
                            dm: 'Text'
                          }),
                          value: 'text'
                        }, {
                          label: $i18n.get({
                            id: 'basic.elements.SimpleNode.registerMeta.FontIcon',
                            dm: 'Font icon'
                          }),
                          value: 'font'
                        }],
                        default: badge.type
                      },
                      value: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleNode.registerMeta.Text',
                          dm: 'Text'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'Input',
                        default: badge.value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };
  return schema;
};
export default registerMeta;