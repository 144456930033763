import { useContext } from '@antv/gi-sdk';
import React from 'react';
/**
 * 清除所有的Style
 * @param graph G6的Graph实例
 * @type Graph
 */
export var clearAllStates = function clearAllStates(graph) {
  graph.setAutoPaint(false);
  graph.getNodes().forEach(function (node) {
    graph.clearItemStates(node);
  });
  graph.getEdges().forEach(function (edge) {
    graph.clearItemStates(edge);
  });
  graph.paint();
  graph.setAutoPaint(true);
};
/**
 *
 * @returns 点击Canvas的交互逻辑
 */
var CanvasClick = function CanvasClick() {
  var _useContext = useContext(),
    graph = _useContext.graph;
  React.useEffect(function () {
    var handleClear = function handleClear() {
      clearAllStates(graph);
    };
    graph.on('canvas:click', handleClear);
    return function () {
      graph.off('canvas:click', handleClear);
    };
  }, [graph]);
  return null;
};
export default CanvasClick;