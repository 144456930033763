import { Select, Typography } from 'antd';
import React from 'react';
import { GI_SITE } from '../../services/const';
import $i18n from '../../i18n';
import './index.less';

const EnvInfo = ({ IS_ONLINE_ENV }) => {
  if (IS_ONLINE_ENV) {
    return (
      <div style={{ width: '500px' }}>
        <p>
          {$i18n.get({
            id: 'gi-site.components.DataModeCard.YouAreCurrentlyInGraphinsight',
            dm: 'You are currently in GraphInsight',
          })}
          <Typography.Text type="success">
            {$i18n.get({ id: 'gi-site.components.DataModeCard.OnlineEnvironment', dm: 'Online Environment 」' })}
          </Typography.Text>
        </p>
        <p>
          {$i18n.get({
            id: 'gi-site.components.DataModeCard.SupportsUsersInTheDomain',
            dm: '✅Supports users in the domain. Data is stored in the cloud and can be viewed and shared according to permissions.',
          })}
        </p>
        <p>
          {$i18n.get({
            id: 'gi-site.components.DataModeCard.SupportsUsersInTheDomain.1',
            dm: '✅Supports users in the domain to add, view, or use business precipitation charts to analyze assets based on permissions.',
          })}
        </p>
        <p>
          {$i18n.get({
            id: 'gi-site.components.DataModeCard.SupportsDomainUsersOdpsData',
            dm: '✅Supports domain users, ODPS data sources, and multiple graph computing engines.',
          })}
        </p>
      </div>
    );
  }
  return (
    <div style={{ width: '500px' }}>
      <p>
        {$i18n.get({ id: 'gi-site.components.DataModeCard.YouAreCurrentlyInG', dm: 'You are currently in G6VP' })}
        <Typography.Text type="success">
          {$i18n.get({ id: 'gi-site.components.DataModeCard.LocalEnvironment', dm: 'Local environment 」' })}
        </Typography.Text>
      </p>
      <p>
        {$i18n.get({
          id: 'gi-site.components.DataModeCard.DataSecurityAllDataUpload',
          dm: '✅Data Security: All data (upload data, canvas operation configuration) is stored in your local browser IndexDB, please feel free to use',
        })}
      </p>
      <p>
        {$i18n.get({
          id: 'gi-site.components.DataModeCard.SupportedScaleThereIsNo',
          dm: '✅Supported scale: there is no limit to uploading files locally, but currently only network analysis within 100000 nodes is supported. Please process the data in advance.',
        })}
      </p>
      <p>
        {$i18n.get({
          id: 'gi-site.components.DataModeCard.OnlineSharingDataIsLocal',
          dm: '❌Online sharing: Data is local to users, so online URL sharing is not supported.',
        })}
      </p>
      <p>
        {$i18n.get({
          id: 'gi-site.components.DataModeCard.ProjectSharingProvidesProjectDownload',
          dm: '✅Project sharing: provides project Download and recovery functions, which can be shared through project files.',
        })}
      </p>
    </div>
  );
};

const DataModeCard = () => {
  const defaultValue = GI_SITE.IS_OFFLINE ? 'OFFLINE' : 'ONLINE';
  const handleChange = value => {
    localStorage.setItem('GI_SITE_ENV', value);
    window.location.reload();
  };

  return (
    // <Popover content={<EnvInfo IS_ONLINE_ENV={!GI_SITE.IS_OFFLINE} />} title="Ring境说明" trigger="hover">
    // {/* <Button icon={<DatabaseOutlined />}>{title}</Button> */}
    <Select
      className="gi-select"
      defaultValue={defaultValue}
      style={{ width: 'fit-content', marginRight: '5px' }}
      onChange={handleChange}
      options={[
        {
          value: 'OFFLINE',
          label: $i18n.get({ id: 'gi-site.components.DataModeCard.LocalStorage', dm: 'Local Storage' }),
        },
        {
          value: 'ONLINE',
          label: $i18n.get({ id: 'gi-site.components.DataModeCard.CloudStorage', dm: 'Cloud storage' }),
        },
      ]}
    />

    // </Popover>
  );
};
export default DataModeCard;
