export var Save = {
  name: 'Save share',
  req: "\n  export interface SaveReqParams {\n    /** Save\u7684Name */\n    name: string;\n    /** Save\u7684Description */\n    description: string;\n    /** Save\u7684Table Mode\uFF0C\u5E26Layout\u4FE1\u606F **/\n    data: GraphinData;\n    /** GISDK\u7684Configuration\uFF0C\u53EF\u4EE5\u8FD8\u539FCanvasStatus */\n    config: GIConfig;\n    /** \u56FE\u7684 Schema \u7ED3\u6784 */\n    schemaData: GraphSchemaData;\n    /** Canvas\u7684\u622A\u56FE */\n    cover: string;\n    /** Save\u7684\u65F6\u95F4 */\n    gmtCreate: Date;\n  }\n  ",
  res: "\n  export interface SaveResParams {\n    /** SaveYesNo\u6210\u529F */\n    success: boolean;\n    /** Save\u8DF3\u8F6C\u7684URLAddress **/\n    data: string;\n    /** Save\u540E\uFF0C\u751F\u6210\u7684UUID */\n    shareId: string;\n  }\n  ",
  service: function service(params, localData) {
    var uuid = "rp_".concat(Math.random().toString(36).substr(2));
    var href = window.location.origin + '/#/share/' + uuid;
    //  window.localforage Yes G6VP 平台提供的全局变量，Details参考：https://github.com/localForage/localForage
    //@ts-ignore
    var _window = window,
      GI_REPORT_DB = _window.GI_REPORT_DB;
    GI_REPORT_DB.setItem(uuid, {
      id: uuid,
      type: 'save',
      params: JSON.stringify(params)
    });
    return new Promise(function (resolve) {
      return resolve({
        success: true,
        data: href,
        shareId: uuid
      });
    });
  }
};