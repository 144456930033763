function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import locale from '@aligov/global-locale';
import stringFormat from '@aligov/global-string-format';
import strings from './strings';
var language; // Current language
var intl; // Instance of intl-universal. Create by provideIntl
/**
 * update instance of intl universal
 */
function update() {
  var lang = 'en-US';
  language = lang;
  intl = stringFormat.init(lang, strings);
}
/**
 * change current language
 * @param {string} langTag language tag config above
 */
function change(langTag) {
  locale.setLang(langTag);
  update();
}
/**
 * Format string by key
 * For example:
 * $i18n.get('jsx.home.title'),
 * $i18n.get({
 *    id: 'jsx.home.hello',
 *    defaultMessage: 'Hello {name}' // not required
 * },{
 *  name: 'Alice'
 * })
 * More syntax: https://formatjs.io/guides/message-syntax/
 * @param {string|object} id key or object
 * @param {object} variable variable for id
 * @return {string} format message
 */
function get(id) {
  var variable = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!intl) update();
  if (typeof id === 'string') {
    return stringFormat.format({
      id: id
    }, variable);
  } else if (_typeof(id) === 'object' && id.dm) {
    id.defaultMessage = id.dm;
  }
  return stringFormat.format({
    id: id.id,
    defaultString: id.dm
  }, variable);
}
export default {
  get: get,
  update: update,
  change: change,
  language: language
};