import Component from './Component';
import registerMeta from './registerMeta';
/**   index.md 中解析得到默认值，也可用户手动修改 */
import $i18n from '../../i18n';
var info = {
  id: 'GraphAnnotation',
  name: $i18n.get({
    id: 'advance.components.GraphAnnotation.Menu',
    dm: 'MENU'
  }),
  category: 'elements-interaction',
  desc: $i18n.get({
    id: 'advance.components.GraphAnnotation.FigureAnnotationOftenIntegratedIn',
    dm: 'Figure annotation, often integrated in the right-click menu'
  }),
  cover: 'http://xxxx.jpg',
  icon: 'icon-pushpin',
  type: 'GIAC_MENU',
  docs: 'https://www.yuque.com/antv/gi/wefpto855v7x79dd'
};
export default {
  info: info,
  component: Component,
  registerMeta: registerMeta
};