import $i18n from '../../i18n';
var registerMeta = function registerMeta(context) {
  var _a, _b, _c, _d, _e, _f;
  var schemaData = context.schemaData,
    keys = context.keys;
  var nodeProperties = schemaData.nodes.reduce(function (acc, cur) {
    return Object.assign(Object.assign({}, acc), cur.properties);
  }, {});
  var presetOptions = [{
    label: $i18n.get({
      id: 'basic.layouts.Force2.registerMeta.GridLayout',
      dm: 'Grid Layout'
    }),
    value: 'grid'
  }, {
    label: $i18n.get({
      id: 'basic.layouts.Force2.registerMeta.CircularLayout',
      dm: 'Circular layout'
    }),
    value: 'circular'
  }, {
    label: $i18n.get({
      id: 'basic.layouts.Force2.registerMeta.ConcentricCircleLayout',
      dm: 'Concentric circle layout'
    }),
    value: 'concentric'
  }, {
    label: $i18n.get({
      id: 'basic.layouts.Force2.registerMeta.DirectedLayering',
      dm: 'Directed layering'
    }),
    value: 'dagre'
  }];
  var weightScaleOptions = [{
    label: $i18n.get({
      id: 'basic.layouts.Force2.registerMeta.OriginalValue',
      dm: 'Original value'
    }),
    value: 1
  }, {
    label: $i18n.get({
      id: 'basic.layouts.Force2.registerMeta.Square',
      dm: 'Square'
    }),
    value: 'sqrt'
  }, {
    label: $i18n.get({
      id: 'basic.layouts.Force2.registerMeta.Square.1',
      dm: 'Square'
    }),
    value: 'sqr'
  }, {
    label: $i18n.get({
      id: 'basic.layouts.Force2.registerMeta.Reciprocal',
      dm: 'Reciprocal'
    }),
    value: 'reciprocal'
  }, {
    label: 'log2',
    value: 'log2'
  }, {
    label: 'log10',
    value: 'log10'
  }];
  var directedWeightOptions = keys.filter(function (k) {
    return nodeProperties[k] === 'number';
  }).map(function (k) {
    return {
      label: k,
      value: k
    };
  });
  return {
    edgeStrength: {
      type: 'number',
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.EdgeGravityCoefficient',
        dm: 'Edge gravity coefficient'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {
        step: 1
      },
      default: 200
    },
    nodeStrength: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.RepulsionCoefficientBetweenNodes',
        dm: 'Repulsion coefficient between nodes'
      }),
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {},
      default: 1000
    },
    damping: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.DampingCoefficient',
        dm: 'Damping coefficient'
      }),
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {
        step: 0.1,
        min: 0,
        max: 1
      },
      default: 0.8
    },
    animate: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.EnableAnimation',
        dm: 'Enable animation'
      }),
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: ((_b = (_a = context.data) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b.length) > 800 ? false : true
    },
    preset: {
      type: 'object',
      properties: {
        type: {
          type: 'string',
          title: $i18n.get({
            id: 'basic.layouts.Force2.registerMeta.FrontLayout',
            dm: 'Front Layout'
          }),
          'x-component': 'Select',
          'x-decorator': 'FormItem',
          'x-component-props': {
            options: presetOptions
          },
          default: 'concentric'
        },
        width: {
          title: $i18n.get({
            id: 'basic.layouts.Force2.registerMeta.PresetLayoutWidth',
            dm: 'Preset layout width'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 800
        },
        height: {
          title: $i18n.get({
            id: 'basic.layouts.Force2.registerMeta.PresetLayoutHeight',
            dm: 'Preset layout height'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 800
        }
      }
    },
    defSpringLenCfg: {
      type: 'object',
      properties: {
        minLimitDegree: {
          title: $i18n.get({
            id: 'basic.layouts.Force2.registerMeta.MinimumLimitDegree',
            dm: 'Minimum limit degree'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 5
        },
        maxLimitLength: {
          title: $i18n.get({
            id: 'basic.layouts.Force2.registerMeta.MaximumLimitSideLength',
            dm: 'Maximum limit side length'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 500
        },
        defaultSpring: {
          title: $i18n.get({
            id: 'basic.layouts.Force2.registerMeta.DefaultSideLength',
            dm: 'Default side length'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 100
        }
      }
    },
    clusterNodeStrength: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.NodeClusteringStrength',
        dm: 'Node clustering strength'
      }),
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {},
      default: 35
    },
    minMovement: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.MinimumDistanceOfIterationStop',
        dm: 'Minimum distance of iteration stop'
      }),
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {},
      default: ((_d = (_c = context.data) === null || _c === void 0 ? void 0 : _c.nodes) === null || _d === void 0 ? void 0 : _d.length) > 200 ? 10 : 2
    },
    distanceThresholdMode: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.IterationStopJudgmentBasis',
        dm: 'Iteration stop judgment basis'
      }),
      type: 'string',
      'x-component': 'Select',
      'x-decorator': 'FormItem',
      'x-component-props': {
        options: [{
          label: $i18n.get({
            id: 'basic.layouts.Force2.registerMeta.Average',
            dm: 'Average'
          }),
          value: 'mean'
        }, {
          label: $i18n.get({
            id: 'basic.layouts.Force2.registerMeta.MinimumValue',
            dm: 'Minimum value'
          }),
          value: 'min'
        }, {
          label: $i18n.get({
            id: 'basic.layouts.Force2.registerMeta.Maximum',
            dm: 'Maximum'
          }),
          value: 'max'
        }]
      },
      default: 'max'
    },
    maxSpeed: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.InitialVelocity',
        dm: 'Initial velocity'
      }),
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {},
      default: 1000
    },
    centripetalOptions: {
      type: 'object',
      properties: {
        leaf: {
          title: $i18n.get({
            id: 'basic.layouts.Force2.registerMeta.LeafNode',
            dm: 'Leaf node'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 2
        },
        single: {
          title: $i18n.get({
            id: 'basic.layouts.Force2.registerMeta.IsolatedNode',
            dm: 'Isolated node'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 2
        },
        others: {
          title: $i18n.get({
            id: 'basic.layouts.Force2.registerMeta.OtherNodes',
            dm: 'Other nodes'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 1
        }
        // center: {
        //   title: 'Central Node',
        //   type: 'string',
        //   'x-decorator': 'FormItem',
        //   'x-component': 'Input.TextArea',
        //   default: `(_node) => {
        //     return {
        //       x: 400,
        //       y: 200,
        //     };
        //   }`,
        // },
      }
    },
    advanceWeight: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.AdvancedWeightConfiguration',
        dm: 'Advanced weight configuration'
      }),
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: false,
      'x-reactions': ['edgeWeightField', 'edgeWeightFieldScale', 'nodeWeightFromType', 'nodeWeightField', 'nodeWeightFieldFromEdge', 'nodeWeightFieldScale', 'directed', 'directedFromType', 'directedInWeightField', 'directedOutWeightField', 'directedAmountFromEdge', 'directedIsLog', 'directedMultiple'].map(function (target) {
        return {
          target: target,
          fulfill: {
            state: {
              visible: '{{$self.value}}'
            }
          }
        };
      })
    },
    edgeWeightField: {
      type: 'string',
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.GravityWeightAssociation',
        dm: 'Gravity weight Association'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'GroupSelect',
      'x-component-props': {
        mode: 'multiple',
        schemaData: schemaData.edges
      }
    },
    edgeWeightFieldScale: {
      type: 'string',
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.GravityWeightAssociation.1',
        dm: 'Gravity weight Association'
      }),
      'x-component': 'Select',
      'x-decorator': 'FormItem',
      'x-component-props': {
        options: weightScaleOptions
      },
      default: 1
    },
    nodeWeightFromType: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.RepulsionAssociationType',
        dm: 'Repulsion Association type'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component-props': {},
      'x-component': 'Radio.Group',
      enum: [{
        label: $i18n.get({
          id: 'basic.layouts.Force2.registerMeta.NodeProperties',
          dm: 'Node properties'
        }),
        value: 'node'
      }, {
        label: $i18n.get({
          id: 'basic.layouts.Force2.registerMeta.AddAndAddRelatedEdge',
          dm: 'Add and add related edge attributes'
        }),
        value: 'edge'
      }],
      default: 'node',
      'x-reactions': [{
        target: 'nodeWeightField',
        fulfill: {
          state: {
            visible: '{{$self.value === "node"}}'
          }
        }
      }, {
        target: 'nodeWeightFieldFromEdge',
        fulfill: {
          state: {
            visible: '{{$self.value === "edge"}}'
          }
        }
      }]
    },
    nodeWeightField: {
      type: 'string',
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.RepulsionWeightAssociation',
        dm: 'Repulsion weight Association'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'GroupSelect',
      'x-component-props': {
        mode: 'multiple',
        schemaData: schemaData.nodes
      },
      showInPanel: {
        conditions: [['.nodeWeightFromType', '$eq', 'node']]
      }
    },
    nodeWeightFieldFromEdge: {
      type: 'string',
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.RepulsionWeightAssociation',
        dm: 'Repulsion weight Association'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'GroupSelect',
      'x-component-props': {
        mode: 'multiple',
        schemaData: schemaData.edges
      },
      showInPanel: {
        conditions: [['.nodeWeightFromType', '$eq', 'edge']]
      }
    },
    nodeWeightFieldScale: {
      type: 'string',
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.RepulsiveWeightAssociation',
        dm: 'Repulsive weight Association'
      }),
      'x-component': 'Select',
      'x-decorator': 'FormItem',
      'x-component-props': {
        options: weightScaleOptions
      },
      default: 1
    },
    directed: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.EnableDirectionalForce',
        dm: 'Enable directional force'
      }),
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: false,
      'x-reactions': [{
        target: 'directedFromType',
        fulfill: {
          state: {
            visible: '{{$self.value}}'
          }
        }
      }, {
        target: 'directedIsLog',
        fulfill: {
          state: {
            visible: '{{$self.value}}'
          }
        }
      }, {
        target: 'directedMultiple',
        fulfill: {
          state: {
            visible: '{{$self.value}}'
          }
        }
      }]
    },
    directedFromType: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.CapitalAssociationElements',
        dm: 'Capital Association elements'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component-props': {},
      'x-component': 'Radio.Group',
      enum: [{
        label: $i18n.get({
          id: 'basic.layouts.Force2.registerMeta.NodeProperties',
          dm: 'Node properties'
        }),
        value: 'node'
      }, {
        label: $i18n.get({
          id: 'basic.layouts.Force2.registerMeta.AddAndAddRelatedEdge',
          dm: 'Add and add related edge attributes'
        }),
        value: 'edge'
      }],
      default: 'node',
      'x-reactions': [{
        dependencies: ['directed'],
        target: 'directedInWeightField',
        fulfill: {
          state: {
            visible: '{{$deps[0] && $self.value === "node"}}'
          }
        }
      }, {
        dependencies: ['directed'],
        target: 'directedOutWeightField',
        fulfill: {
          state: {
            visible: '{{$deps[0] && $self.value === "node"}}'
          }
        }
      }, {
        dependencies: ['directed'],
        target: 'directedAmountFromEdge',
        fulfill: {
          state: {
            visible: '{{$deps[0] && $self.value === "edge"}}'
          }
        }
      }]
    },
    directedInWeightField: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.EnterWeightField',
        dm: 'Enter weight field'
      }),
      'x-component': 'Select',
      'x-decorator': 'FormItem',
      'x-component-props': {
        mode: 'multiple',
        schemaData: schemaData.edges
      },
      default: (_e = directedWeightOptions[0]) === null || _e === void 0 ? void 0 : _e.value
    },
    directedOutWeightField: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.OutputWeightField',
        dm: 'Output weight field'
      }),
      'x-component': 'Select',
      'x-decorator': 'FormItem',
      'x-component-props': {
        mode: 'multiple',
        schemaData: schemaData.edges
      },
      default: (_f = directedWeightOptions[0]) === null || _f === void 0 ? void 0 : _f.value
    },
    directedAmountFromEdge: {
      type: 'string',
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.RelatedEdgeProperties',
        dm: 'Related edge properties'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'GroupSelect',
      'x-component-props': {
        mode: 'multiple',
        schemaData: schemaData.edges
      }
    },
    directedIsLog: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.LogMapping',
        dm: 'log mapping'
      }),
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: true
    },
    directedMultiple: {
      title: $i18n.get({
        id: 'basic.layouts.Force2.registerMeta.MultipleMapping',
        dm: 'Multiple mapping'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Input',
      default: '0.1'
    }
  };
};
export default registerMeta;