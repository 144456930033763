function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
export var stringify = function stringify(obj) {
  var placeholder = '____PLACEHOLDER____';
  var fns = [];
  var json = JSON.stringify(obj, function (key, value) {
    if (typeof value === 'function') {
      fns.push(value);
      return placeholder;
    }
    return value;
  }, 2);
  //@ts-ignore
  json = json.replace(new RegExp('"' + placeholder + '"', 'g'), function (_) {
    return fns.shift();
  });
  return 'export default ' + json + '';
};
/**
 *
 * @param componentAssets 服务端拿到的资产: Components
 * @param data 图数据
 * @returns
 */
var getMockServiceConfig = function getMockServiceConfig(componentAssets) {
  return Object.keys(componentAssets).map(function (key) {
    var component = componentAssets[key];
    if (!component) {
      return [];
    }
    var mockServices = component.mockServices;
    if (mockServices) {
      var sers = mockServices();
      return sers.map(function (c) {
        return {
          id: c.id,
          mode: 'MOCK',
          name: c.name || c.id,
          content: stringify(c.service)
        };
      });
    }
    return [];
  }).reduce(function (acc, curr) {
    return [].concat(_toConsumableArray(acc), _toConsumableArray(curr));
  }, []).filter(function (c) {
    return c;
  });
};
export default getMockServiceConfig;