function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getKeysByData } from './utils';
/**
 *
 * @param assets 服务端拿到的资产：Elements
 * @param data 图数据
 * @returns
 */
var getElementsByAssets = function getElementsByAssets(assets, data) {
  var nodeElements = {};
  var edgeElements = {};
  Object.keys(assets).forEach(function (key) {
    var element = assets[key];
    //@ts-ignore
    var info = element.info,
      registerMeta = element.registerMeta,
      registerShape = element.registerShape,
      registerTransform = element.registerTransform,
      defaultProps = element.defaultProps;
    var id = info.id,
      name = info.name,
      category = info.category,
      type = info.type;
    var keys = getKeysByData(data, category);
    var configObj = registerMeta({
      data: data,
      keys: keys
    });
    /** 默认的配置值 */
    // const defaultProps = extractDefault({ config: configObj, value: {} });
    var item = {
      id: id,
      props: defaultProps,
      name: name,
      info: info,
      // meta: { configObj },
      meta: configObj
      // registerShape,
      // registerTransform,
    };
    if (category === 'node' || type === 'NODE') {
      nodeElements = Object.assign(Object.assign({}, nodeElements), _defineProperty({}, id, item));
    }
    if (category === 'edge' || type === 'EDGE') {
      edgeElements = Object.assign(Object.assign({}, edgeElements), _defineProperty({}, id, item));
    }
  });
  return {
    nodes: nodeElements,
    edges: edgeElements
  };
};
export default getElementsByAssets;