import registerMeta from './registerMeta';
import $i18n from '../../i18n';
import Component from './Component';
/**   index.md 中解析得到默认值，也可用户手动修改 */
var info = {
  id: 'MiniMap',
  name: $i18n.get({
    id: 'basic.components.MiniMap.SmallMap',
    dm: 'MiniMap'
  }),
  desc: $i18n.get({
    id: 'basic.components.MiniMap.WhenEnabledInformationCanBe',
    dm: 'When enabled, information can be displayed on the mini map.'
  }),
  icon: 'icon-minimap',
  cover: 'http://xxxx.jpg',
  category: 'system-interaction',
  type: 'AUTO',
  docs: 'https://www.yuque.com/antv/gi/wzzgehef2k3nfm9c'
};
export default {
  info: info,
  component: Component,
  registerMeta: registerMeta
};