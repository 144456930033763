function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import dayjs from 'dayjs';
import { maxBy, minBy } from 'lodash-es';
export var formatXAxis = function formatXAxis(data, format) {
  if (dayjs(data, format).isValid()) {
    return dayjs(data).format(format);
  }
  return dayjs(data).format(format);
};
export var getFormatData = function getFormatData(data, field, format) {
  var newData = data.filter(function (item) {
    return !!item[field];
  }).map(function (item) {
    return Object.assign(Object.assign({}, item), _defineProperty({}, field, formatXAxis(item[field], format)));
  }).sort(function (a, b) {
    return new Date(a[field]).getTime() - new Date(b[field]).getTime();
  });
  return newData;
};
export var getInitTimeRange = function getInitTimeRange(times) {
  var dataIndex = Math.ceil(times.length * 0.2);
  var timeRange = [times[0], times[dataIndex]];
  return timeRange;
};
export var getTimeInterval = function getTimeInterval(times) {
  var max = maxBy(times, function (item) {
    return new Date(item).getTime();
  });
  var min = minBy(times, function (item) {
    return new Date(item).getTime();
  });
  return [min, max];
};
export var getTimeFormat = function getTimeFormat(timeGranularity) {
  switch (timeGranularity) {
    case 'year':
      return 'YYYY';
    case 'quarter':
    case 'month':
      return 'YYYY-MM';
    case 'week':
    case 'day':
      return 'YYYY-MM-DD';
    case 'hour':
      return 'YYYY-MM-DD HH';
    case 'minute':
      return 'YYYY-MM-DD HH:mm';
    case 'second':
    default:
      return 'YYYY-MM-DD HH:mm:ss';
  }
};