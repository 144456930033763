function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
var SPLITTER = '|PGSPLIPTER|';
/**
 * 将图数据转换为属性图，一个属性值为一个节点，边将其连接到对应的 id 属性点上
 * 并统计每个属性值在该属性下的概率，即 （属性 A 值为 '1' 出现次数 / 属性 A 的所有值出现总次数）
 * @param graphData
 * @returns
 */
export var graphData2PropertyGraph = function graphData2PropertyGraph(graphData, schemaData) {
  var _a, _b;
  var typeKeyMap = {
    node: (_a = schemaData.nodes[0]) === null || _a === void 0 ? void 0 : _a.nodeTypeKeyFromProperties,
    edge: (_b = schemaData.edges[0]) === null || _b === void 0 ? void 0 : _b.edgeTypeKeyFromProperties
  };
  var schemaTypePropertiesMap = {
    node: {},
    edge: {}
  };
  schemaData.nodes.forEach(function (node) {
    var nodeType = node.nodeType,
      properties = node.properties;
    schemaTypePropertiesMap.node[nodeType] = properties;
  });
  schemaData.edges.forEach(function (edge) {
    var edgeType = edge.edgeType,
      properties = edge.properties;
    schemaTypePropertiesMap.edge[edgeType] = properties;
  });
  var splitData = function splitData(itemType, data) {
    if (itemType === 'edge') {
      var _id = data.id,
        source = data.source,
        target = data.target,
        _others = __rest(data, ["id", "source", "target"]);
      return {
        id: _id,
        others: _others
      };
    }
    var id = data.id,
      others = __rest(data, ["id"]);
    return {
      id: id,
      others: others
    };
  };
  var propertyLinks = [];
  var propertyValueMap = {};
  ['node', 'edge'].forEach(function (itemType) {
    var items = graphData["".concat(itemType, "s")];
    var dataTypeKey = typeKeyMap[itemType];
    items.forEach(function (item) {
      var data = item.data;
      if (!data) return;
      var _splitData = splitData(itemType, data),
        id = _splitData.id,
        others = _splitData.others;
      var dataType = data[dataTypeKey];
      var schemaProperties = schemaTypePropertiesMap[itemType][dataType];
      var idKey = "".concat(itemType).concat(SPLITTER, "id").concat(SPLITTER).concat(id);
      propertyValueMap[idKey] = {
        name: 'id',
        value: id,
        count: 1
      };
      Object.keys(others).forEach(function (pname) {
        if (schemaProperties && !schemaProperties.hasOwnProperty(pname)) return;
        var pvalue = others[pname];
        var key = "".concat(itemType).concat(SPLITTER).concat(pname).concat(SPLITTER).concat(pvalue);
        if (propertyValueMap.hasOwnProperty(key)) {
          propertyValueMap[key].count++;
        } else {
          propertyValueMap[key] = {
            name: pname,
            value: pvalue,
            count: 1
          };
        }
        propertyLinks.push({
          source: idKey,
          target: key
        });
      });
    });
  });
  // 计算每一属性值 count 数的个数
  var propertyCountCount = {};
  Object.keys(propertyValueMap).forEach(function (propertyValueKey) {
    var _propertyValueKey$spl = propertyValueKey.split(SPLITTER),
      _propertyValueKey$spl2 = _slicedToArray(_propertyValueKey$spl, 2),
      itemType = _propertyValueKey$spl2[0],
      propertyName = _propertyValueKey$spl2[1];
    var count = propertyValueMap[propertyValueKey].count;
    var propertyKey = "".concat(itemType).concat(SPLITTER).concat(propertyName);
    propertyCountCount[propertyKey] = propertyCountCount[propertyKey] || {
      total: 0,
      numberOfCount: 0,
      valueRatios: [],
      valueRankMap: {}
    };
    propertyCountCount[propertyKey][count] = propertyCountCount[propertyKey][count] || 0;
    propertyCountCount[propertyKey][count]++;
    propertyCountCount[propertyKey].numberOfCount++;
    propertyCountCount[propertyKey].total += count;
  });
  var properties = Object.keys(propertyValueMap).map(function (propertyValueKey) {
    var _propertyValueKey$spl3 = propertyValueKey.split(SPLITTER),
      _propertyValueKey$spl4 = _slicedToArray(_propertyValueKey$spl3, 3),
      itemType = _propertyValueKey$spl4[0],
      propertyName = _propertyValueKey$spl4[1],
      propertyValue = _propertyValueKey$spl4[2];
    var propertyKey = "".concat(itemType).concat(SPLITTER).concat(propertyName);
    var _propertyCountCount$p = propertyCountCount[propertyKey],
      numberOfCount = _propertyCountCount$p.numberOfCount,
      total = _propertyCountCount$p.total;
    var count = propertyValueMap[propertyValueKey].count;
    // 若这个属性值的出现「次数」非常多，大于该属性所有值出现「次数」的 90%，则认为这个值是一平凡的属性值，其余的为异常值
    var isOrdinary = propertyCountCount[propertyKey][count] > numberOfCount * 0.5;
    // 若存在平凡的属性值，这个属性的重要性则参考异常值而非信息量
    propertyCountCount[propertyKey].hasOrdinary = propertyCountCount[propertyKey].hasOrdinary || isOrdinary;
    var ratio = count / total;
    propertyCountCount[propertyKey].valueRatios.push({
      id: propertyValueKey,
      ratio: ratio
    });
    return {
      id: propertyValueKey,
      propertyName: propertyName,
      propertyValue: propertyValue,
      ratio: ratio,
      isOrdinary: isOrdinary
    };
  });
  Object.values(propertyCountCount).forEach(function (item) {
    var hasOrdinary = item.hasOrdinary,
      total = item.total,
      valueRankMap = item.valueRankMap;
    if (hasOrdinary) {
      // 存在平凡的值，则需要参考平均数
      item.ave = 1 / total;
    } else {
      // 否则参考信息量，将信息量排序，ratio 越小越重要
      item.valueRatios.sort(function (a, b) {
        return a.ratio - b.ratio;
      });
      item.valueRatios.forEach(function (valueRatio, i) {
        var id = valueRatio.id;
        valueRankMap[id] = i;
      });
    }
  });
  properties.forEach(function (property) {
    var id = property.id,
      ratio = property.ratio;
    var _id$split = id.split(SPLITTER),
      _id$split2 = _slicedToArray(_id$split, 2),
      itemType = _id$split2[0],
      propertyName = _id$split2[1];
    var propertyKey = "".concat(itemType).concat(SPLITTER).concat(propertyName);
    var _propertyCountCount$p2 = propertyCountCount[propertyKey],
      hasOrdinary = _propertyCountCount$p2.hasOrdinary,
      ave = _propertyCountCount$p2.ave,
      valueRankMap = _propertyCountCount$p2.valueRankMap;
    property.hasOrdinary = hasOrdinary;
    if (hasOrdinary) {
      if (ratio > ave && propertyName !== 'id') property.isOutlier = true;
    } else {
      property.rank = valueRankMap[id];
    }
  });
  return {
    nodes: properties,
    edges: propertyLinks
  };
};
/**
 * 计算一个图节点/边所有属性的重要性，并排序，重要的在前面
 * @param propertyGraphData 属性图数据
 * @param itemType 图节点/边
 * @param itemId 图节点/边的 id
 * @returns
 */
export var getNodePropertyImportance = function getNodePropertyImportance(propertyGraphData, itemType, itemId) {
  var findTop = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 5;
  var findOutlier = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  var _a, _b;
  if (!propertyGraphData) return [];
  var propertyNodeMap = {};
  (_a = propertyGraphData.nodes) === null || _a === void 0 ? void 0 : _a.forEach(function (pnode) {
    return propertyNodeMap[pnode.id] = pnode;
  });
  var idPropertyNode = propertyNodeMap["".concat(itemType).concat(SPLITTER, "id").concat(SPLITTER).concat(itemId)];
  var propertyNodes = [];
  if (idPropertyNode) {
    var getProperty = function getProperty(propertyNode) {
      var propertyName = propertyNode.propertyName,
        ratio = propertyNode.ratio,
        rank = propertyNode.rank,
        hasOrdinary = propertyNode.hasOrdinary,
        isOutlier = propertyNode.isOutlier;
      if (!hasOrdinary && rank < findTop) {
        propertyNodes.push({
          propertyName: propertyName,
          ratio: ratio,
          rank: rank
        });
      } else if (findOutlier && isOutlier) {
        propertyNodes.push({
          propertyName: propertyName,
          ratio: ratio,
          rank: rank,
          isOutlier: true
        });
      }
    };
    (_b = propertyGraphData.edges) === null || _b === void 0 ? void 0 : _b.forEach(function (edge) {
      var source = edge.source,
        target = edge.target;
      if (source === idPropertyNode.id) {
        getProperty(propertyNodeMap[target]);
      } else if (target === idPropertyNode.id) {
        getProperty(propertyNodeMap[source]);
      }
    });
  }
  return propertyNodes;
};
/**
 * 计算每一种属性的熵，并按照从大到小排序
 * @param propertyGraphData 属性图数据
 * @param itemType 图元素类型，节点或边
 * @returns
 */
export var getPropertyRanks = function getPropertyRanks(propertyGraphData, itemType) {
  if (!propertyGraphData) return [];
  var properyEntropyMap = {};
  propertyGraphData.nodes.forEach(function (propertyValueNode) {
    var id = propertyValueNode.id,
      ratio = propertyValueNode.ratio;
    var _id$split3 = id.split(SPLITTER),
      _id$split4 = _slicedToArray(_id$split3, 2),
      iType = _id$split4[0],
      propertyName = _id$split4[1];
    if (propertyName === 'id' || itemType && iType !== itemType) return [];
    var propertyKey = "".concat(iType).concat(SPLITTER).concat(propertyName);
    properyEntropyMap[propertyKey] = properyEntropyMap[propertyKey] || 0;
    properyEntropyMap[propertyKey] += ratio * Math.log2(ratio);
  });
  return Object.keys(properyEntropyMap).map(function (propertyKey) {
    return {
      propertyName: propertyKey.split(SPLITTER)[1],
      entropy: properyEntropyMap[propertyKey]
    };
  }).sort(function (a, b) {
    return b.entropy - b.entropy;
  }); // 按照从大到小排序，熵越大的越重要
};
/**
 * 筛选出计算一个属性的所有属性值的排序，若一个属性值是 outlier，则 rank 为 0，即最重要
 * @param propertyGraphData 属性图数据
 * @param itemType 图元素类型，节点或边
 * @param propertyName 属性名称
 * @returns
 */
export var getPropertyValueRanks = function getPropertyValueRanks(propertyGraphData, itemType, propertyName) {
  if (!propertyGraphData) return [];
  var valueRanks = [];
  propertyGraphData.nodes.forEach(function (propertyValueNode) {
    var id = propertyValueNode.id,
      rank = propertyValueNode.rank,
      isOutlier = propertyValueNode.isOutlier;
    var _id$split5 = id.split(SPLITTER),
      _id$split6 = _slicedToArray(_id$split5, 3),
      iType = _id$split6[0],
      pName = _id$split6[1],
      pValue = _id$split6[2];
    if (propertyName === 'id' || iType !== itemType || pName !== propertyName) return [];
    valueRanks.push({
      propertyValue: pValue,
      rank: isOutlier ? 0 : rank,
      isOutlier: isOutlier
    });
  });
  return valueRanks;
};