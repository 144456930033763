function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _toArray(r) { return _arrayWithHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { uniqueElementsBy } from './common';
import { filterByRules } from './filterByRules';
import processEdges from './processEdges';
/**
 *
 * @param elementType 元素类型：node or edge
 * @param data 数据
 * @param config GISDK配置
 * @param ElementAssets 元素资产
 * @param reset 是否重置transform
 * @returns nodes or edges
 */
export var transDataByConfig = function transDataByConfig(elementType, data, config, ElementAssets, reset) {
  console.time("".concat(elementType.toUpperCase(), "_TRANS_COST"));
  var elementConfig = config[elementType];
  if (!elementConfig) {
    return {};
  }
  var elementData = data[elementType];
  if (elementType === 'edges') {
    // 先整体做个多边处理
    elementData = processEdges(elementData, {
      poly: 40,
      loop: 10
    });
  }
  var _elementConfig = _toArray(elementConfig),
    basicConfig = _elementConfig[0],
    otherConfigs = _elementConfig.slice(1);
  var filterElements = otherConfigs.map(function (item) {
    //@ts-ignore
    var id = item.id,
      expressions = item.expressions,
      logic = item.logic;
    if (!ElementAssets) {
      return [];
    }
    var Element = ElementAssets[id];
    var filterData = filterByRules(elementData, {
      logic: logic,
      expressions: expressions
    });
    return Element.registerTransform(filterData, item, reset);
  }).reduce(function (acc, curr) {
    return [].concat(_toConsumableArray(curr), _toConsumableArray(acc));
  }, []);
  var uniqueElements = uniqueElementsBy(filterElements, function (a, b) {
    return a.id === b.id;
  });
  var uniqueIds = uniqueElements.map(function (n) {
    return n.id;
  });
  //@ts-ignore
  var restElements = elementData.filter(function (n) {
    return uniqueIds.indexOf(n.id) === -1;
  });
  //@ts-ignore
  var restData = ElementAssets[basicConfig.id].registerTransform(restElements, basicConfig, reset);
  var nodes = [].concat(_toConsumableArray(uniqueElements), _toConsumableArray(restData));
  console.timeEnd("".concat(elementType.toUpperCase(), "_TRANS_COST"));
  return nodes;
};