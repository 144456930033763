function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var DEFAULT_CONNECT_INFO = {
  username: '',
  password: '',
  serverUrl: ''
};
export var getConnectInfo = function getConnectInfo() {
  try {
    var TUGRAPH_CONNECT_INFO_STRING = localStorage.getItem('TUGRAPH_CONNECT_INFO') || '{}';
    var _JSON$parse = JSON.parse(TUGRAPH_CONNECT_INFO_STRING),
      _JSON$parse$username = _JSON$parse.username,
      username = _JSON$parse$username === void 0 ? DEFAULT_CONNECT_INFO.username : _JSON$parse$username,
      _JSON$parse$password = _JSON$parse.password,
      password = _JSON$parse$password === void 0 ? DEFAULT_CONNECT_INFO.password : _JSON$parse$password,
      _JSON$parse$serverUrl = _JSON$parse.serverUrl,
      serverUrl = _JSON$parse$serverUrl === void 0 ? DEFAULT_CONNECT_INFO.serverUrl : _JSON$parse$serverUrl;
    return {
      username: username,
      password: password,
      serverUrl: serverUrl
    };
  } catch (error) {
    return DEFAULT_CONNECT_INFO;
  }
};
export var setConnectInfo = function setConnectInfo(params) {
  localStorage.setItem('TUGRAPH_CONNECT_INFO', JSON.stringify(params));
};
/**
 * 获取服务引擎的上下文
 * @returns
 */
export var getEngineForm = function getEngineForm(engineId) {
  var defaltContext = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  try {
    var context = JSON.parse(localStorage.getItem('ENGINE_FORM') || '{}');
    return Object.assign(Object.assign({}, defaltContext), context[engineId] || {});
  } catch (error) {
    console.error(error);
    return {};
  }
};
/**
 * 设置服务引擎的上下文
 * @returns
 */
export var setEngineForm = function setEngineForm(engineId, context) {
  try {
    var preContext = JSON.parse(localStorage.getItem('ENGINE_FORM') || '{}');
    localStorage.setItem('ENGINE_FORM', JSON.stringify(Object.assign(Object.assign({}, preContext), _defineProperty({}, engineId, Object.assign(Object.assign({}, preContext[engineId]), context)))));
  } catch (error) {
    console.error(error);
  }
};