import Graphin, { useBehaviorHook } from '@antv/graphin';
import ActivateRelationsBehavior from './activate-relations-v2';
var defaultConfig = {
  /**
   * @description YesNo禁用该功能
   * @default false
   */
  disabled: false,
  /**
   * @description 可以Yes mousenter，表示Move the mouse in时触发；也可以Yes click，Mouse Click时触发
   * @default mouseenter
   */
  trigger: 'mouseenter',
  /**
   * @description 活跃NodeStatus。当行For被触发，需要被突出显示的Node和!!!NO TRANSLATE!!!都会附带此Status，默认值For  active；可以与 graph 实例的  nodeStyle  和  edgeStyle  结合实现丰富的视觉效果。
   * @default active
   */
  activeState: 'active',
  /**
   * @description 非活跃NodeStatus。不需要被突出显示的Node和!!!NO TRANSLATE!!!都会附带此Status。默认值For  inactive。可以与 graph 实例的  nodeStyle  和  edgeStyle  结合实现丰富的视觉效果；
   * @default inactive
   */
  inactiveState: 'inactive',
  /**
   * @description Highlight相连Node时YesNoReset已经选中的Node，默认For false，即选中的NodeStatus不会被 activate-relations 覆盖；
   * @default false
   */
  resetSelected: false,
  // 上游扩展的Degree
  upstreamDegree: 1,
  // 下游扩展的Degree
  downstreamDegree: 1,
  // 多选组合键
  modifierKey: 'alt',
  // YesNo支持多选
  multiSelectEnabled: false
};
Graphin.registerBehavior('activate-relations-v2', ActivateRelationsBehavior);
var ActivateRelations = function ActivateRelations(props) {
  useBehaviorHook({
    type: 'activate-relations-v2',
    userProps: props,
    defaultConfig: defaultConfig
  });
  return null;
};
export default ActivateRelations;