import $i18n from '../../i18n';
var ASSET_ID = 'PropertiesPanel';
var info = {
  id: ASSET_ID,
  name: $i18n.get({
    id: 'basic.components.PropertiesPanel.info.PropertiesPanel',
    dm: 'Properties panel'
  }),
  desc: $i18n.get({
    id: 'basic.components.PropertiesPanel.info.ClickANodeOrEdge',
    dm: 'Click a node or edge to display its details.'
  }),
  icon: 'icon-reconciliation',
  category: 'elements-interaction',
  cover: 'http://xxxx.jpg',
  type: 'AUTO',
  services: [ASSET_ID],
  docs: 'https://www.yuque.com/antv/gi/iv9m1b0zq76fa1sg'
};
export default info;