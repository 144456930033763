import { clone, difference, isArray } from '@antv/util';
import { Button, Checkbox, Col, Row, Select, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import $i18n from '../../../../i18n';
import { AssetInfo } from '../../typing';
import AssetsCenter from './AssetsCenter';
import RenderForm from './RenderForm';
import './index.less';

let refComponentKeys: string[] = [];

const getOriginContainerId = (prefixedId, pageLayout) => prefixedId.replace(`_${pageLayout.id}-`, '');
const getPrefixedContainerId = (id, pageLayout) => `_${pageLayout.id}-${id}`;

/** Container configuration面板 */
const ContainerPanel = props => {
  const {
    config,
    updateContext,
    context,
    setPanelHeight,
    pageLayout,
    candidateContainers,
    updatePageLayout,
    layoutComponents,
    handlePageLayoutChange,
    componentsMap,
    handleComplete,
    defaultExpandId,
    updateContainerSubAssets,
    collapse,
  } = props;
  const [state, setState] = useImmer<{
    selectedContainers: any[];
    focusingContainer: any;
    containerAssetsMap: {
      [containerId: string]: AssetInfo[];
    };
    focusingContainerAsset: AssetInfo[];
  }>({
    selectedContainers: [],
    focusingContainer: undefined,
    containerAssetsMap: {},
    focusingContainerAsset: [],
  });
  const { activeAssetsKeys, activeAssets } = context;
  const { selectedContainers, focusingContainer, containerAssetsMap, focusingContainerAsset } = state;

  React.useEffect(() => {
    if (collapse) handleFocusAssetsSelector();
  }, [collapse]);

  /**
   * 当活跃的资产变更时，缓存到 ref
   */
  React.useEffect(() => {
    (async () => {
      refComponentKeys = [...(activeAssetsKeys.components || [])];
    })();
  }, [activeAssetsKeys]);

  /**
   * 选中的Page layout（唯One）变更时，For已选定的Container（包括Page layout必选的子Container），集成当前Configuration中的子资产，加入活跃资产
   */
  useEffect(() => {
    if (pageLayout) {
      // 该Page layout必选的子Container id 列表
      const requiredIds: string[] = candidateContainers.filter(con => con.required || con.display).map(con => con.id);
      // 当前活跃的非Page layout类子资产 id 列表
      const currentActiveAssetKeys: string[] = refComponentKeys.filter(key => {
        // 排除掉目前活跃资产中的Page layout（即上One次的Page layout）
        const component = config.components.find(com => com.type === 'GICC_LAYOUT' && com.id === key);
        if (component || !componentsMap[key]) return false;
        return true;
      });
      // 当前Page layout正在使用的自带Container
      const pageLayoutContainerIds =
        pageLayout.props?.containers
          .filter(container => container.display)
          .map(container => getPrefixedContainerId(container.id, pageLayout)) || [];

      // 选中：必选的子Container + 当前活跃的所有Container + Page layout
      handleContainerChange([...requiredIds, ...currentActiveAssetKeys, ...pageLayoutContainerIds, pageLayout.id]);
    }
  }, [pageLayout?.id]);

  useEffect(() => {
    if (defaultExpandId) {
      let id = defaultExpandId;
      const focusContainer = candidateContainers.find(container => {
        if (container.id === defaultExpandId || container.id === `_${pageLayout?.id}-${defaultExpandId}`) {
          id = container.id;
          return true;
        }
      });
      if (focusContainer) {
        setState(draft => {
          draft.focusingContainer = focusContainer;
          draft.focusingContainerAsset = draft.containerAssetsMap[id];
        });
        setTimeout(() => {
          setPanelHeight('calc(50vh - 64px)');
        }, 16);
      }
    }
  }, [defaultExpandId]);

  /**
   * 选择Container时的响应函数，选中指定的Container
   * @param selectedList 需要选中的所有Container id 列表
   * @canditates 候选的Container，可传入以防止调用该函数时 state 中的候选Container更新尚未生效
   */
  const handleContainerChange = (selectedList: string[]) => {
    const containers = candidateContainers.filter(container => selectedList.includes(container.id));

    // GetContainer（Configuration在Template中的）默认资产
    const containerSubAssetsMap = { ...containerAssetsMap };
    containers.forEach(container => {
      const { id } = container;
      if (!containerAssetsMap[id]) {
        const defaultAssets = container.props.GI_CONTAINER;
        if (defaultAssets) {
          containerSubAssetsMap[id] = defaultAssets;
        }
      }
      updateContainerAssets(id, containerSubAssetsMap[id], 'add', containers);
    });

    // 比较出被Cancel勾选的Container
    const lastSelectedList = selectedContainers.map(container => container.id);
    const removedContainerIds = difference(lastSelectedList, selectedList);

    // 若被Cancel则清空缓存的子资产
    removedContainerIds.forEach(id => {
      if (containerSubAssetsMap[id]) {
        containerSubAssetsMap[id] = [];
      }
    });

    setState(draft => {
      draft.selectedContainers = containers;
      draft.containerAssetsMap = containerSubAssetsMap;
    });

    // 计算当前所有需要激活的资产
    let activeAssetsIds = selectedList;
    Object.keys(containerSubAssetsMap).forEach(contaienrId => {
      const assetInfos = containerSubAssetsMap[contaienrId];
      activeAssetsIds = activeAssetsIds.concat(assetInfos?.map(info => info.value));
    });
    // None需激活的资产 = 全量Asset intro - 需要激活的资产
    const inactivAssetsIds = [];
    Object.values(componentsMap).forEach(con => {
      if (!activeAssetsIds.includes(con.id)) inactivAssetsIds.push(con.id);
    });
    const activeComponentKeys = new Set<string>();
    activeAssetsIds.forEach(assetId => {
      if (assetId && componentsMap[assetId]) activeComponentKeys.add(assetId);
    });
    const initializerIds = Object.values(activeAssets.components)
      .filter((com: any) => com.info?.type === 'AUTO' || com.info?.type === 'INITIALIZER')
      .map((com: any) => com.info.id);
    initializerIds.forEach(initializerId => activeComponentKeys.add(initializerId));
    if (pageLayout) activeComponentKeys.add(pageLayout.id);
    refComponentKeys = Array.from(activeComponentKeys);
    if (JSON.stringify(refComponentKeys.sort()) !== JSON.stringify(clone(context.activeAssetsKeys.components).sort())) {
      // Settings激活Asset intro
      updateContext(draft => {
        draft.activeAssetsKeys.components = refComponentKeys;
      });
    }

    // Page layout的子Container不在Asset intro中，因此生效逻辑不通。若被选中，则Settings其 display For true
    updatePageLayout(propsPageLayout => {
      propsPageLayout.props.containers.forEach(container => {
        container.display = selectedList.map(id => getOriginContainerId(id, propsPageLayout)).includes(container.id);
        const containerAssets = containerSubAssetsMap[getPrefixedContainerId(container.id, propsPageLayout)];
        if (containerAssets) {
          container.GI_CONTAINER = containerAssets;
        }
      });
    });

    // CloseAsset Center
    handleFocusAssetsSelector();
  };

  /**
   * 单个Container中，除了资产集成输入框以外的，OtherParameter表单变更的响应函数，将更新 context 中对应的Parameter，以使Canvas联动变更
   * @param containerId Container id
   * @param values 表单值
   */
  const handleFormChange = (containerId: string, values) => {
    updatePageLayout(draft => {
      const originId = getOriginContainerId(containerId, draft);
      const container = draft.props.containers.find(con => con.id === originId);
      if (container) {
        Object.keys(values).forEach(key => {
          container[key] = values[key];
        });
      }
    });
    updateContext(draft => {
      draft.config.components.forEach(item => {
        if (item.id === containerId) {
          item.props = {
            ...item.props,
            ...values,
          };
        }
      });
    });
  };

  /**
   * 点击One个Container的资产集成输入框时的响应函数，将Settings当前正在聚焦的Container，并Zoom outContainerSettings区域Height以ForAsset Center腾出空间
   * @param id 被点击（即聚焦）的Container id。若For不指定，则代表失焦，Asset Center将Close，ContainerSettings区域Height恢复
   * @returns
   */
  const handleFocusAssetsSelector = (id?: string) => {
    if (!id) {
      setState(draft => {
        draft.focusingContainer = undefined;
      });
      setPanelHeight('100%');
      return;
    }
    const focusContainer = selectedContainers.find(container => container.id === id);
    setState(draft => {
      draft.focusingContainer = focusContainer;
      draft.focusingContainerAsset = draft.containerAssetsMap[id];
    });
    setPanelHeight('calc(50vh - 64px)');
  };

  /**
   * 更新One个Container集成的资产，可能Yes增加多个资产，Or删去多个资产
   * @param containerId Container id
   * @param propsAsset 被Operation的资产信息列表
   * @param action add 表示加入，remove 表示删去
   */
  const updateContainerAssets = (
    containerId: string,
    propsAsset: AssetInfo[] | AssetInfo,
    action: 'add' | 'remove' = 'add',
    containers = undefined,
  ) => {
    const container = (containers || selectedContainers).find(container => container.id === containerId);
    if (container) {
      const assetList = isArray(propsAsset) ? propsAsset : [propsAsset];
      // Get缓存的Container子Asset intro
      let containerAssets = [...(containerAssetsMap[containerId] || [])];
      if (assetList.length) {
        if (action === 'add') {
          const { mode } = container.meta?.GI_CONTAINER?.['x-component-props'] || {};
          if (mode === 'multiple') {
            // 若当前Container子资产支持多选，则加入Container的子Asset intro
            assetList.forEach(asset => containerAssets.push(asset));
          } else {
            // Container子资产单选
            containerAssets = [assetList[0]];
          }
        } else {
          // 移除
          assetList.forEach(asset => {
            const item = containerAssets.find(assetItem => assetItem.value === asset.value);
            if (item) containerAssets.splice(containerAssets.indexOf(item), 1);
          });
        }
      }

      // 新的子资产 id 列表 (activeAssetsKeys)
      const containerAssetsIdsSet = new Set(containerAssets.map(casset => casset.value));
      const containerAssetsIds = Array.from(containerAssetsIdsSet);

      // 更新全局活跃Asset intro
      updateContext(draft => {
        draft.config.components.forEach(item => {
          if (item.id === containerId) {
            // 找到普通的Container资产，更新其子Asset intro
            item.props.GI_CONTAINER = containerAssetsIds;
          }
        });

        const activeComponentKeys = new Set<string>(refComponentKeys);
        if (action === 'add') {
          assetList.forEach(asset => {
            if (componentsMap[asset.value]) activeComponentKeys.add(asset.value);
          });
        } else {
          assetList.forEach(asset => activeComponentKeys.delete(asset.value));
        }
        refComponentKeys = Array.from(activeComponentKeys);

        draft.activeAssetsKeys.components = refComponentKeys;
      });

      // Page layout的子Container不在Asset intro中，因此生效逻辑不通。若被选中，则Settings其 display For true
      updatePageLayout(propsPageLayout => {
        const originContainerId = getOriginContainerId(containerId, propsPageLayout);
        const propContainer = propsPageLayout.props?.containers.find(con => con.id === originContainerId);
        if (propContainer) {
          propContainer.display = true;
          propContainer.GI_CONTAINER = containerAssetsIds;
        } else if (!componentsMap[containerId]) {
          propsPageLayout.props = propsPageLayout.props || {
            containers: [],
          };
          // 该子Container不存在Page layout的子Container列表中，加入
          propsPageLayout.props.containers.push({
            ...container,
            GI_CONTAINER: containerAssetsIds,
            display: true,
          });
        }
      });

      // 更新 { [Container]: 子资产[] } 缓存(containerAssetsMap)，并记录当前正在Operation的Container (focusingContainerAsset)
      setState(draft => {
        draft.containerAssetsMap[containerId] = containerAssets;
        draft.focusingContainerAsset = containerAssets;
      });
      // 更新Container的子资产Configuration，方便下次进入时读取
      updateContainerSubAssets(containerId, containerAssets);
      return containerAssets;
    }
    return undefined;
  };

  return (
    <>
      {/* Container configuration */}
      <div>
        <Row justify="space-between" align="middle" className="gi-container-config-header">
          <Col className="gi-container-config-title">
            {$i18n.get({ id: 'gi-site.MetaPanel.ContainerPanel.ContainerConfiguration', dm: 'Container configuration' })}
          </Col>
          <Col>
            <Tooltip
              title={$i18n.get({
                id: 'gi-site.MetaPanel.ContainerPanel.ReturnToAssetConfiguration',
                dm: 'Back to asset configuration',
              })}
            >
              <Button onClick={handleComplete} type="primary" size="small">
                {$i18n.get({ id: 'gi-site.MetaPanel.ContainerPanel.Complete', dm: 'Complete' })}
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <div className="gi-container-config-wrapper">
          <div className="gi-container-config-item">
            <p className="gi-container-config-label">
              {$i18n.get({ id: 'gi-site.MetaPanel.ContainerPanel.PageLayout', dm: 'Page layout' })}
            </p>
            <div className="gi-container-config-input">
              <Select className="gi-container-config-seletor" onSelect={handlePageLayoutChange} value={pageLayout?.id}>
                {layoutComponents.map(component => (
                  <Select.Option value={component.id} key={component.id}>
                    {component.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

          {/* 相关Container components的 checkbox */}
          {pageLayout ? (
            <div className="gi-container-config-item">
              <p className="gi-container-config-label">
                {pageLayout?.name}
                {$i18n.get({ id: 'gi-site.MetaPanel.ContainerPanel.Container', dm: 'Container' })}
              </p>
              <div className="gi-container-config-input">
                <Checkbox.Group
                  value={selectedContainers.map(con => con.id)}
                  options={candidateContainers?.map(container => ({
                    label: container.name,
                    value: String(container.id),
                    disabled: container.required,
                  }))}
                  onChange={handleContainerChange}
                />
              </div>
            </div>
          ) : (
            ''
          )}

          {/* panel: Container configuration + Integrated Components输入框 */}
          {selectedContainers?.length ? (
            <div className="gi-container-collapse">
              {selectedContainers.map(item => (
                <RenderForm
                  key={item.id}
                  selecting={item.id === focusingContainer?.id}
                  {...item}
                  onChange={handleFormChange}
                  config={config}
                  pageLayoutId={pageLayout?.id}
                  handleFocusAssetsSelector={handleFocusAssetsSelector}
                  assets={containerAssetsMap[item.id]}
                  handleRemoveContainerAsset={(id, asset) => updateContainerAssets(id, asset, 'remove')}
                  defaultActiveContainerId={defaultExpandId}
                  handleCollapseChange={value => {
                    if (!value?.length && item.id === focusingContainer?.id) handleFocusAssetsSelector();
                  }}
                />
              ))}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      {/* 资产选择器 */}
      <AssetsCenter
        containerComponent={focusingContainer}
        value={focusingContainerAsset}
        componentsMap={componentsMap}
        handleUpdate={updateContainerAssets}
        handleClose={handleFocusAssetsSelector}
      />
    </>
  );
};

export default ContainerPanel;
