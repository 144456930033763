function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { icons } from '@antv/gi-sdk';
import { Utils } from '@antv/graphin';
import merge from 'deepmerge';
var defaultNodeTheme = {
  primaryColor: '#FF6A00',
  nodeSize: 26,
  mode: 'light'
};
var getLabel = function getLabel(data, LABEL_KEYS) {
  return LABEL_KEYS.map(function (d) {
    /**
     * 兼容性处理：原先的label 逻辑是 ${type}.${properpertiesKey}
     * 现在改为 ${type}^^${properpertiesKey}
     */
    var _d$split = d.split('^^'),
      _d$split2 = _slicedToArray(_d$split, 2),
      newNodeType = _d$split2[0],
      newLabelKey = _d$split2[1];
    var _d$split3 = d.split('.'),
      _d$split4 = _slicedToArray(_d$split3, 2),
      oldNodeType = _d$split4[0],
      oldLabelKey = _d$split4[1];
    var key = newLabelKey || oldLabelKey || 'id';
    return data[key];
  }).filter(function (d) {
    return d;
  }).join('\n');
};
var getIconStyleByConfig = function getIconStyleByConfig(style, data) {
  var keyshape = style.keyshape;
  if (!style.icon || !keyshape) {
    return {};
  }
  var icon = Object.assign({}, style.icon);
  var value = icon.value;
  if (icon.visible) {
    if (icon.type === 'image') {
      return {
        fill: 'transparent',
        size: [keyshape.size, keyshape.size],
        type: 'image',
        clip: {
          r: keyshape.size / 2
        },
        value: value
      };
    }
    if (icon.type === 'font') {
      return Object.assign(Object.assign({}, icon), {
        size: keyshape.size / 2,
        type: 'font',
        fontFamily: 'iconfont',
        value: icons[value] || '',
        fill: icon.fill || keyshape.fill
      });
    }
    if (icon.type === 'text') {
      return Object.assign(Object.assign({}, icon), {
        fontSize: keyshape.size / 4,
        fill: '#fff',
        value: value
      });
    }
    return Object.assign({}, icon);
  }
  return Object.assign(Object.assign({}, icon), {
    visible: false,
    value: ''
  });
};
var getBadgesStyleByConfig = function getBadgesStyleByConfig(style, data) {
  var badge = style.badge,
    keyshape = style.keyshape;
  if (!badge || !keyshape) {
    return [];
  }
  var visible = badge.visible,
    value = badge.value,
    color = badge.color;
  if (visible) {
    var size = Math.round(keyshape.size / 3);
    var fontSize = size / 2;
    badge.size = size;
    badge.stroke = color || keyshape.stroke;
    if (badge.type === 'mapping') {
      var b = {
        type: 'text',
        size: size,
        stroke: keyshape.stroke,
        fill: '#fff',
        color: keyshape.fill,
        visible: Boolean(data[value]),
        value: data[value],
        fontSize: fontSize
      };
      return [b];
    }
    if (badge.type === 'font') {
      badge.type = 'font';
      badge.fontFamily = 'iconfont';
      badge.value = icons[value] || '';
    }
    if (badge.type === 'text') {
      badge.fill = '#fff';
      badge.color = color || keyshape.fill;
      badge.value = value;
      badge.fontSize = fontSize;
    }
    return [badge];
  }
  return [];
};
var defaultNodeStyles = Utils.getNodeStyleByTheme(defaultNodeTheme);
var style = defaultNodeStyles.style,
  status = defaultNodeStyles.status;
var keyshape = style.keyshape,
  halo = style.halo,
  label = style.label,
  icon = style.icon;
export var defaultConfig = {
  size: defaultNodeTheme.nodeSize,
  color: defaultNodeTheme.primaryColor,
  label: [],
  advanced: {
    keyshape: Object.assign(Object.assign({}, keyshape), {
      fillOpacity: 0.8
    }),
    label: Object.assign(Object.assign({}, label), {
      opacity: 1,
      visible: true
    }),
    icon: Object.assign(Object.assign({}, icon), {
      fill: '#fff',
      type: 'font',
      value: '',
      opacity: 1,
      visible: false
    }),
    badge: {
      visible: false,
      position: 'RT',
      type: 'text',
      value: '',
      size: Math.round(keyshape.size / 3),
      fill: '#fff',
      color: '#fff',
      stroke: keyshape.stroke,
      isMapping: false
    },
    halo: Object.assign(Object.assign({}, halo), {
      visible: false,
      lineWidth: 0
    })
  },
  status: {
    minZoom: {
      label: {
        opacity: 0
      },
      icon: {
        opacity: 0
      },
      badges: {
        opacity: 0
      }
    }
  }
};
/** 数据映射函数  需要根据配置自动生成*/
var transform = function transform(nodes, nodeConfig, reset) {
  try {
    /** 解构配置项 */
    var _merge = merge(defaultConfig, nodeConfig.props || {}),
      color = _merge.color,
      size = _merge.size,
      LABEL_KEYS = _merge.label,
      advanced = _merge.advanced,
      userStatus = _merge.status;
    var isBug = false;
    //@ts-ignore
    if (!Object.is(advanced)) {
      isBug = true;
    }
    var _ref = isBug ? defaultConfig.advanced : advanced,
      _halo = _ref.halo;
    var transNodes = nodes.map(function (node) {
      // properties
      var data = node.data || node.properties || node;
      var keyshape = Object.assign(Object.assign({}, advanced.keyshape), {
        fill: color,
        stroke: color,
        size: size
      });
      advanced.keyshape = keyshape;
      var LABEL_VALUE = getLabel(data, LABEL_KEYS);
      var icon = getIconStyleByConfig(advanced, data);
      var badges = getBadgesStyleByConfig(advanced, data);
      var label = Object.assign(Object.assign({}, advanced.label), {
        value: advanced.label.visible ? LABEL_VALUE : ''
      });
      var preStyle = node && node.style || {};
      if (reset) {
        preStyle = {};
      }
      var styleByConfig = {
        keyshape: keyshape,
        label: label,
        icon: icon,
        halo: _halo,
        badges: badges,
        status: Object.assign(Object.assign(Object.assign({}, status), userStatus), {
          highlight: {
            keyshape: {
              lineWidth: 4,
              fillOpacity: 0.6
            }
          },
          active: {
            halo: {
              visible: true
            },
            keyshape: {
              lineWidth: 5
            }
          },
          /** 扩散的状态 */
          query_start: {
            halo: {
              visible: true,
              stroke: color,
              lineWidth: 4,
              lineDash: [8, 8]
            }
          },
          query_normal: {
            halo: {
              visible: true,
              stroke: color,
              lineWidth: 1,
              lineDash: [8, 8]
            }
          }
        })
      };
      return Object.assign(Object.assign({}, node), {
        id: node.id,
        data: data,
        nodeType: node.nodeType || 'UNKNOW',
        type: 'graphin-circle',
        // 数据中的style还是优先级最高的
        style: merge(styleByConfig, preStyle)
      });
    });
    return transNodes;
  } catch (error) {
    console.error('parse transform error:', error);
    return nodes;
  }
};
export default transform;