import { Utils } from '@antv/graphin';
// Pattern matchingResult根据条件进行Filter
export var filterByPatternRules = function filterByPatternRules(oriGraphData, pattern, matches, directed) {
  var _a, _b, _c, _d;
  var graphNodeTypeMap = {};
  var nodeDataMap = {};
  oriGraphData.nodes.forEach(function (node) {
    graphNodeTypeMap[node.id] = node.label;
    nodeDataMap[node.id] = node;
  });
  var patternEdgesLength = pattern.edges.length;
  var patternNodesLength = pattern.nodes.length;
  var matchesLength = matches.length;
  for (var i = matchesLength - 1; i >= 0; i -= 1) {
    var match = matches[i];
    // 在 match 找每One个 pattern 点的Match。只要有One个 pattern Node没有找到Match，则该 match 不合格
    var matchNodesLength = match.nodes.length;
    var allPatternNodeMatched = true;
    for (var pn = 0; pn < patternNodesLength; pn += 1) {
      var pNode = pattern.nodes[pn];
      var oneNodeMatched = false;
      // 若该 pattern 点没有规则，则不需要寻找。继续Match下One个 pattern 点
      if (!((_a = pNode.rules) === null || _a === void 0 ? void 0 : _a.length)) continue;
      for (var mn = 0; mn < matchNodesLength; mn += 1) {
        var mNode = match.nodes[mn];
        // Type的Match
        if (pNode.label !== mNode.label) continue; // 不Match，继续下One个 mNode
        // 规则的Match
        var mProperties = ((_b = nodeDataMap[mNode.id]) === null || _b === void 0 ? void 0 : _b.data) || mNode.data;
        mNode.data = mProperties;
        if (!mProperties && ((_c = pNode.rules) === null || _c === void 0 ? void 0 : _c.length)) continue; // 不Match，继续下One个 mNode
        var allRuleMatched = true;
        for (var r = 0; r < pNode.rules.length; r += 1) {
          var rule = pNode.rules[r];
          var mPropertyValue = mProperties[rule.name];
          switch (rule.name) {
            case 'id':
              mPropertyValue = mNode.id;
              break;
            case 'label':
              mPropertyValue = mNode.label;
              break;
            default:
              break;
          }
          switch (rule.rule) {
            case '>':
              if (mPropertyValue <= rule.value) allRuleMatched = false;
              break;
            case '<':
              if (mPropertyValue >= rule.value) allRuleMatched = false;
              break;
            case '=':
              if (mPropertyValue !== rule.value) allRuleMatched = false;
              break;
            case '!=':
              if (mPropertyValue === rule.value) allRuleMatched = false;
              break;
            case 'like':
              if (!"".concat(mPropertyValue).includes(rule.value)) allRuleMatched = false;
              break;
            case 'unlike':
              if ("".concat(mPropertyValue).includes(rule.value)) allRuleMatched = false;
              break;
            default:
              break;
          }
          if (!allRuleMatched) break;
        }
        // 该!!!NO TRANSLATE!!!符合 pNode，即有One个点符合了 pNode，继续找下One条 pNode 的Match
        if (allRuleMatched) {
          oneNodeMatched = true;
          break;
        }
      }
      // 该 pEdge 没有找到Match，该 match 不合格
      if (!oneNodeMatched) {
        allPatternNodeMatched = false;
        break;
      }
    }
    // 有One条 pattern 中的Node没有在该 match 中找到Match，该 match 不合格，Delete该 match
    if (!allPatternNodeMatched) {
      matches.splice(i, 1);
      continue;
    }
    // 在 match 找每One个 pattern !!!NO TRANSLATE!!!的Match。只要有One个 pattern !!!NO TRANSLATE!!!没有找到Match，则该 match 不合格
    var allPatternEdgeMatched = true;
    for (var pe = 0; pe < patternEdgesLength; pe += 1) {
      var pEdge = pattern.edges[pe];
      var oneEdgeMatched = false;
      // 若该 pattern !!!NO TRANSLATE!!!没有规则，则不需要寻找
      if (!pEdge.rules || !pEdge.rules.length) continue;
      for (var me = 0; me < match.edges.length; me += 1) {
        var mEdge = match.edges[me];
        // Type和端点的Match
        if (pEdge.label !== mEdge.label) continue;
        var directedMatched = pEdge.sourceNodeType === graphNodeTypeMap[mEdge.source] && pEdge.targetNodeType === graphNodeTypeMap[mEdge.target];
        var undirectedMatched = directedMatched || pEdge.sourceNodeType === graphNodeTypeMap[mEdge.target] && pEdge.targetNodeType === graphNodeTypeMap[mEdge.source];
        if (directed && !directedMatched) continue;
        if (!directed && !undirectedMatched) continue;
        // 规则的Match
        var _mProperties = mEdge === null || mEdge === void 0 ? void 0 : mEdge.data;
        if (!_mProperties && ((_d = pEdge.rules) === null || _d === void 0 ? void 0 : _d.length)) continue;
        var _allRuleMatched = true;
        for (var _r = 0; _r < pEdge.rules.length; _r += 1) {
          var _rule = pEdge.rules[_r];
          var _mPropertyValue = _mProperties[_rule.name];
          switch (_rule.rule) {
            case '>':
              if (_mPropertyValue <= _rule.value) _allRuleMatched = false;
              break;
            case '<':
              if (_mPropertyValue >= _rule.value) _allRuleMatched = false;
              break;
            case '=':
              if (_mPropertyValue !== _rule.value) _allRuleMatched = false;
              break;
            case '!=':
              if (_mPropertyValue === _rule.value) _allRuleMatched = false;
              break;
            case 'like':
              if (!"".concat(_mPropertyValue).includes(_rule.value)) _allRuleMatched = false;
              break;
            case 'unlike':
              if ("".concat(_mPropertyValue).includes(_rule.value)) _allRuleMatched = false;
              break;
            default:
              break;
          }
          if (!_allRuleMatched) break;
        }
        // 该!!!NO TRANSLATE!!!符合 pEdge，即有One条!!!NO TRANSLATE!!!符合了 pEdge，继续找下One条 pEdge 的Match
        if (_allRuleMatched) {
          oneEdgeMatched = true;
          break;
        }
      }
      // 该 pEdge 没有找到Match，该 match 不合格
      if (!oneEdgeMatched) {
        allPatternEdgeMatched = false;
        break;
      }
    }
    // 有One条 pattern 中的!!!NO TRANSLATE!!!没有在该 match 中找到Match，该 match 不合格，Delete该 match
    if (!allPatternEdgeMatched) matches.splice(i, 1);
  }
};
export var formatDataModels = function formatDataModels(dataType, models, config) {
  var schemaEdgeMap = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  return (models === null || models === void 0 ? void 0 : models.map(function (model) {
    var _a;
    var type = model["".concat(dataType, "Type")];
    var modelConfig = {};
    if (type) {
      modelConfig = config["".concat(dataType, "s")].find(function (config) {
        return config.groupName === "".concat(type.toUpperCase(), " TYPE");
      }) || modelConfig;
    }
    var _ref = modelConfig.props || {},
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? '#ccc' : _ref$color,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? dataType === 'node' ? 30 : 1 : _ref$size;
    var res = Object.assign(Object.assign({}, model), {
      style: Object.assign(Object.assign({}, model.style), {
        keyshape: {
          size: size,
          fill: dataType === 'node' ? color : undefined,
          stroke: color
        },
        label: typeof model.label === 'string' ? {
          value: model.label
        } : ((_a = model.style) === null || _a === void 0 ? void 0 : _a.label) || model.label
      })
    });
    if (dataType === 'edge') {
      var edgeSchema = schemaEdgeMap[model.edgeType];
      if (edgeSchema) {
        res.sourceNodeType = edgeSchema.sourceNodeType;
        res.targeteEdgeType = edgeSchema.targetNodeType;
      }
    }
    return res;
  })) || [];
};
export var formatData = function formatData(graphData, config, schemaEdgeMap) {
  return {
    nodes: formatDataModels('node', graphData.nodes, config),
    edges: Utils.processEdges(formatDataModels('edge', graphData.edges, config, schemaEdgeMap))
  };
};
export default {
  filterByPatternRules: filterByPatternRules,
  formatDataModels: formatDataModels,
  formatData: formatData
};