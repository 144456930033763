import { Card, Col, Row, Tag, Typography } from 'antd';
import * as React from 'react';
import $i18n from '../../i18n';

const { Meta } = Card;
const { Paragraph } = Typography;

interface CardsProps {
  data: any;
}

// 已经在官方注册过的资产上架
const CARD_MAP = {
  GI_ASSETS_AKG: {
    cover: 'https://gw.alipayobjects.com/mdn/rms_0d75e8/afts/img/A*jFagT7R_T0UAAAAAAAAAAAAAARQnAQ',
    title: $i18n.get({ id: 'gi-site.pages.Assets.Cards.AntKnowledgeGraph', dm: 'Ant Knowledge Graph' }),
    desc: $i18n.get({
      id: 'gi-site.pages.Assets.Cards.ExcellentAssetsInKnowledgeReasoning',
      dm: 'Excellent assets in knowledge reasoning scenarios such as algorithm analysis, DSL language query, and fusion analysis',
    }),
    group: $i18n.get({ id: 'gi-site.pages.Assets.Cards.KnowSpiderFrontEndTeam', dm: 'Know Spider front-end team' }),
    color: '#2f54eb',
  },
  GI_ASSETS_YUQUE: {
    cover: 'https://gw.alipayobjects.com/mdn/rms_0d75e8/afts/img/A*qP9PRLYCpwUAAAAAAAAAAAAAARQnAQ',
    title: $i18n.get({ id: 'gi-site.pages.Assets.Cards.YuqueKnowledgeNetwork', dm: 'Yuque Knowledge Network' }),
    desc: $i18n.get({
      id: 'gi-site.pages.Assets.Cards.ContainsAssetsSuchAsNodes',
      dm: 'Contains assets such as nodes, edges, and interactions under the yuque Knowledge Network.',
    }),
    group: $i18n.get({ id: 'gi-site.pages.Assets.Cards.YuqueFrontEndTeam', dm: 'Yuque front-end team' }),
    color: '#87d068',
  },
  GI_ASSETS_SECURITY: {
    cover: 'https://gw.alipayobjects.com/mdn/rms_0d75e8/afts/img/A*NWl9TYq8tQkAAAAAAAAAAAAAARQnAQ',
    title: $i18n.get({ id: 'gi-site.pages.Assets.Cards.AntSecurity', dm: 'Ant security' }),
    desc: $i18n.get({
      id: 'gi-site.pages.Assets.Cards.IncludingCaseAnalysisMoneyLaundering',
      dm: 'Including case analysis, money laundering analysis, and other quality analysis assets in the field of security risk control',
    }),
    group: $i18n.get({ id: 'gi-site.pages.Assets.Cards.LargeSecurityFrontEndTeam', dm: 'Large security front-end team' }),
    color: '#8171ff',
  },
  GI_ASSETS_GRAPHSCOPE: {
    cover: 'https://gw.alipayobjects.com/mdn/rms_0d75e8/afts/img/A*SRjBRZji8RsAAAAAAAAAAAAAARQnAQ',
    title: $i18n.get({
      id: 'gi-site.pages.Assets.Cards.GraphComputingEngineStandaloneVersion',
      dm: 'Graph Computing Engine (standalone version)',
    }),
    desc: $i18n.get({
      id: 'gi-site.pages.Assets.Cards.IncludesAssetModulesSuchAs',
      dm: 'Includes asset modules such as O & M Management, cluster management, data management, and deployment overview.',
    }),
    group: $i18n.get({ id: 'gi-site.pages.Assets.Cards.GraphscopeOfficial', dm: 'GraphScope official' }),
    color: '#2281f2',
  },
  GI_ASSETS_TUGRAPH: {
    cover: 'https://gw.alipayobjects.com/mdn/rms_3ff49c/afts/img/A*xqsZTKLVHPsAAAAAAAAAAAAAARQnAQ',
    title: $i18n.get({ id: 'gi-site.pages.Assets.Cards.GraphDatabaseStandalone', dm: 'Graph Database (standalone)' }),
    desc: $i18n.get({
      id: 'gi-site.pages.Assets.Cards.SupportsTbLevelStandAlone',
      dm: 'Supports TB-level stand-alone storage, high-performance graph computing, and Cypher statement query.',
    }),
    group: $i18n.get({ id: 'gi-site.pages.Assets.Cards.TugraphOfficial', dm: 'TuGraph official' }),
    color: '#2281f2',
  },
  GeaMakerGraphStudio: {
    cover: 'https://gw.alipayobjects.com/mdn/rms_0d75e8/afts/img/A*yDeARar2jLIAAAAAAAAAAAAAARQnAQ',
    title: $i18n.get({ id: 'gi-site.pages.Assets.Cards.AntGraphDatabase', dm: 'Ant graph database' }),
    desc: $i18n.get({
      id: 'gi-site.pages.Assets.Cards.IncludesAssetModulesSuchAs.3',
      dm: 'Includes asset modules such as Gremlin query, advanced query, and visual modeling.',
    }),
    group: $i18n.get({ id: 'gi-site.pages.Assets.Cards.GeamakerFrontEndTeam', dm: 'GeaMaker front-end team' }),
    color: '#091e35',
  },
  GI_ASSETS_GEAMAKER: {
    cover: 'https://gw.alipayobjects.com/mdn/rms_0d75e8/afts/img/A*yDeARar2jLIAAAAAAAAAAAAAARQnAQ',
    title: $i18n.get({ id: 'gi-site.pages.Assets.Cards.AntGraphDatabase', dm: 'Ant graph database' }),
    desc: $i18n.get({
      id: 'gi-site.pages.Assets.Cards.IncludesAssetModulesSuchAs.3',
      dm: 'Includes asset modules such as Gremlin query, advanced query, and visual modeling.',
    }),
    group: $i18n.get({ id: 'gi-site.pages.Assets.Cards.GeamakerFrontEndTeam', dm: 'GeaMaker front-end team' }),
    color: '#091e35',
  },
  GI_ASSETS_NEO4J: {
    cover: 'https://mdn.alipayobjects.com/huamei_qa8qxu/afts/img/A*6NRERpsvzMcAAAAAAAAAAAAADmJ7AQ/original',
    title: $i18n.get({ id: 'gi-site.pages.Assets.Cards.NeoJGraphDatabase', dm: 'Neo4j graph database' }),
    desc: $i18n.get({
      id: 'gi-site.pages.Assets.Cards.IncludesAssetModulesSuchAs.3',
      dm: 'Includes asset modules such as Gremlin query, advanced query, and visual modeling.',
    }),
    group: $i18n.get({ id: 'gi-site.pages.Assets.Cards.GVpFrontEndTeam', dm: 'G6VP front-end team' }),
    color: '#091e35',
  },
  GI_ASSETS_BASIC: {
    cover: 'https://gw.alipayobjects.com/mdn/rms_0d75e8/afts/img/A*wnCMQpZDfAQAAAAAAAAAAAAAARQnAQ',
    title: $i18n.get({ id: 'gi-site.pages.Assets.Cards.BasicAnalysisAssets', dm: 'Basic Analysis assets' }),
    desc: $i18n.get({
      id: 'gi-site.pages.Assets.Cards.BuiltInBasicLayoutBasic',
      dm: 'Built-in 20 + basic layout, basic interaction, element style, system interaction, basic container and other assets',
    }),
    group: $i18n.get({ id: 'gi-site.pages.Assets.Cards.GVpOfficial', dm: 'G6VP official' }),
    color: 'green',
  },
  GI_ASSETS_ADVANCE: {
    cover: 'https://gw.alipayobjects.com/mdn/rms_0d75e8/afts/img/A*wnCMQpZDfAQAAAAAAAAAAAAAARQnAQ',
    title: $i18n.get({ id: 'gi-site.pages.Assets.Cards.AdvancedAnalysisAssets', dm: 'Advanced analysis assets' }),
    desc: $i18n.get({
      id: 'gi-site.pages.Assets.Cards.BuiltInGraphAnalysisAdvanced',
      dm: 'Built-in 10 + graph analysis advanced assets: filtering, neighbor query, path analysis, multi-canvas analysis, etc.',
    }),
    group: $i18n.get({ id: 'gi-site.pages.Assets.Cards.GVpOfficial', dm: 'G6VP official' }),
    color: 'blue',
  },
  GI_ASSETS_SCENE: {
    cover: 'https://gw.alipayobjects.com/mdn/rms_0d75e8/afts/img/A*wnCMQpZDfAQAAAAAAAAAAAAAARQnAQ',
    title: $i18n.get({ id: 'gi-site.pages.Assets.Cards.ScenarioAnalysisAssets', dm: 'Scenario Analysis assets' }),
    desc: $i18n.get({
      id: 'gi-site.pages.Assets.Cards.BuiltInDBigPicture',
      dm: 'Built-in 3D large graph analysis, geographic interaction analysis, time series analysis and other scene analysis assets',
    }),
    group: $i18n.get({ id: 'gi-site.pages.Assets.Cards.GVpOfficial', dm: 'G6VP official' }),
    color: 'red',
  },
  GI_ASSETS_ALGORITHM: {
    cover: 'https://gw.alipayobjects.com/mdn/rms_0d75e8/afts/img/A*wnCMQpZDfAQAAAAAAAAAAAAAARQnAQ',
    title: $i18n.get({ id: 'gi-site.pages.Assets.Cards.AlgorithmAnalysisAssets', dm: 'Algorithm Analysis assets' }),
    desc: $i18n.get({
      id: 'gi-site.pages.Assets.Cards.BuiltInNodeClusteringNode',
      dm: 'Built-in node clustering, node similarity analysis, community discovery, pattern matching and other 6 + algorithm analysis assets',
    }),
    group: $i18n.get({ id: 'gi-site.pages.Assets.Cards.GVpOfficial', dm: 'G6VP official' }),
    color: 'purple',
  },
};

const Cards: React.FunctionComponent<CardsProps> = props => {
  const { data } = props;

  return (
    <div>
      <Row gutter={[16, 16]}>
        {data.map(item => {
          const { global, name, url, version } = item;
          console.log('item', item);
          const extraInfo = CARD_MAP[global] || {
            title: name,
            desc: $i18n.get(
              {
                id: 'gi-site.pages.Assets.Cards.TheGlobalVariableIsGlobal',
                dm: 'The global variable is: {global}',
              },
              { global: global },
            ),
            group: 'unkown',
            cover: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
            color: '#000',
          };
          const { title, desc, group, cover, color } = extraInfo;

          return (
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6} key={global}>
              <Card
                hoverable
                cover={<img alt="example" src={cover} style={{ objectFit: 'cover', aspectRatio: '16/9' }} />}
              >
                <div style={{ position: 'relative' }}>
                  <Meta
                    title={title}
                    description={
                      <Paragraph
                        style={{ height: 45, margin: 0 }}
                        ellipsis={{ rows: 2, expandable: false, tooltip: desc }}
                      >
                        {desc}
                      </Paragraph>
                    }
                  ></Meta>
                  <Tag color={color} style={{ position: 'absolute', right: '0px', top: '2px' }}>
                    {`${group}@${version}`}
                  </Tag>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Cards;
