import registerLayout from './registerLayout';
import registerMeta from './registerMeta';
/**   index.md 中解析得到默认值，也可用户手动修改 */
import $i18n from '../../i18n';
var info = {
  id: 'Concentric',
  options: {
    type: 'concentric'
  },
  name: $i18n.get({
    id: 'basic.layouts.Concentric.ConcentricCircleLayout',
    dm: 'Concentric circle layout'
  }),
  category: 'basic',
  type: 'LAYOUT',
  desc: $i18n.get({
    id: 'basic.layouts.Concentric.AccordingToTheNodeDegree',
    dm: 'According to the node degree, it can be arranged in concentric circles.'
  }),
  icon: 'icon-layout-concentric',
  cover: 'http://xxxx.jpg',
  docs: 'https://www.yuque.com/antv/gi/gpr0ca0pa26img2x'
};
export default {
  info: info,
  registerLayout: registerLayout,
  registerMeta: registerMeta
};