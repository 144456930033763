import { useContext } from '@antv/gi-sdk';
import React from 'react';
/**
 *
 * @returns 点击Canvas的交互逻辑
 */
var CanvasClick = function CanvasClick() {
  var _useContext = useContext(),
    graph = _useContext.graph;
  React.useEffect(function () {
    var handleCenter = function handleCenter() {
      graph.fitCenter(true); // graph.fitCenter(true); Animation有bug
    };
    graph.on('canvas:dblclick', handleCenter);
    return function () {
      graph.off('canvas:dblclick', handleCenter);
    };
  }, [graph]);
  return null;
};
export default CanvasClick;