import $i18n from '../../i18n';
import { defaultConfig } from './registerTransform';
var advanced = defaultConfig.advanced,
  color = defaultConfig.color,
  size = defaultConfig.size;
var keyshape = advanced.keyshape,
  label = advanced.label,
  animate = advanced.animate;
var registerMeta = function registerMeta(context) {
  var keys = context.keys,
    schemaData = context.schemaData;
  var schema = {
    type: 'object',
    properties: {
      color: {
        title: $i18n.get({
          id: 'basic.elements.SimpleEdge.registerMeta.Color',
          dm: 'Color'
        }),
        type: 'string',
        'x-decorator': 'FormItem',
        'x-component': 'ColorInput',
        default: color
      },
      size: {
        title: $i18n.get({
          id: 'basic.elements.SimpleEdge.registerMeta.Size',
          dm: 'Size'
        }),
        type: 'number',
        'x-decorator': 'FormItem',
        'x-component': 'NumberPicker',
        default: size
      },
      label: {
        title: $i18n.get({
          id: 'basic.elements.SimpleEdge.registerMeta.Text',
          dm: 'Text'
        }),
        type: 'string',
        // enum: keys.map(c => {
        //   return {
        //     label: `${c.id} (${c.type})`,
        //     value: c.id,
        //   };
        // }),
        'x-decorator': 'FormItem',
        'x-component': 'GroupSelect',
        'x-component-props': {
          mode: 'multiple',
          schemaData: schemaData.edges
        }
      },
      advancedPanel: {
        type: 'void',
        'x-decorator': 'FormItem',
        'x-component': 'FormCollapse',
        'x-component-props': {
          className: 'gi-assets-elements-advance-panel',
          ghost: true
        },
        properties: {
          advanced: {
            type: 'object',
            'x-component': 'FormCollapse.CollapsePanel',
            'x-component-props': {
              header: $i18n.get({
                id: 'basic.elements.SimpleEdge.registerMeta.AdvancedConfiguration',
                dm: 'Advanced Configuration'
              }),
              key: 'advanced-panel'
            },
            properties: {
              panel: {
                type: 'void',
                'x-decorator': 'FormItem',
                'x-component': 'FormCollapse',
                'x-component-props': {
                  className: 'gi-assets-elements-panel',
                  style: {},
                  ghost: true
                },
                properties: {
                  keyshape: {
                    type: 'object',
                    'x-decorator': 'FormItem',
                    'x-component': 'FormCollapse.CollapsePanel',
                    'x-component-props': {
                      header: $i18n.get({
                        id: 'basic.elements.SimpleEdge.registerMeta.Shape',
                        dm: 'Shape'
                      }),
                      key: 'icon-panel'
                    },
                    properties: {
                      hasArrow: {
                        type: 'boolean',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.Arrow',
                          dm: 'Arrow'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'Switch',
                        default: true
                      },
                      customPoly: {
                        type: 'boolean',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.DefineRadians',
                          dm: 'Define radians'
                        }),
                        default: keyshape.customPoly,
                        'x-decorator': 'FormItem',
                        'x-component': 'Switch',
                        'x-reactions': [{
                          target: 'advanced.keyshape.poly',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }, {
                          target: 'advanced.icon.fill',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }, {
                          target: 'advanced.icon.size',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }]
                      },
                      poly: {
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.Radian',
                          dm: 'Radian'
                        }),
                        type: 'number',
                        'x-decorator': 'FormItem',
                        'x-component': 'NumberPicker',
                        default: keyshape.poly
                      },
                      lineDash: {
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.DottedLine',
                          dm: 'Dotted line'
                        }),
                        type: 'array',
                        'x-decorator': 'FormItem',
                        'x-component': 'Offset',
                        'x-component-props': {
                          min: -100,
                          max: 100
                        },
                        default: keyshape.lineDash
                      },
                      opacity: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.Transparency',
                          dm: 'Transparency'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'NumberPicker',
                        default: keyshape.opacity
                      }
                    }
                  },
                  label: {
                    type: 'object',
                    'x-decorator': 'FormItem',
                    'x-component': 'FormCollapse.CollapsePanel',
                    'x-component-props': {
                      header: $i18n.get({
                        id: 'basic.elements.SimpleEdge.registerMeta.Label',
                        dm: 'Label'
                      }),
                      key: 'keyshape-panel'
                    },
                    properties: {
                      visible: {
                        type: 'boolean',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.Visible',
                          dm: 'Visible'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'Switch',
                        default: label.visible
                      },
                      fontSize: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.Size',
                          dm: 'Size'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'NumberPicker',
                        default: label.fontSize
                      },
                      offset: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.Offset',
                          dm: 'Offset'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'Offset',
                        'x-component-props': {
                          min: -100,
                          max: 100
                        },
                        default: label.offset
                      },
                      fill: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.Color',
                          dm: 'Color'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'ColorInput',
                        default: label.fill
                      },
                      backgroundEnable: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.Background',
                          dm: 'Background'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'Switch',
                        default: label.backgroundEnable
                      },
                      backgroundFill: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.BackgroundColor',
                          dm: 'Background color'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'ColorInput',
                        default: label.backgroundFill
                      },
                      backgroundStroke: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.BackgroundStroke',
                          dm: 'Background stroke'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'ColorInput',
                        default: label.backgroundStroke
                      }
                    }
                  },
                  animate: {
                    type: 'object',
                    'x-component': 'FormCollapse.CollapsePanel',
                    'x-component-props': {
                      header: $i18n.get({
                        id: 'basic.elements.SimpleEdge.registerMeta.Animation',
                        dm: 'Animation'
                      }),
                      key: 'aniamte-panel'
                    },
                    properties: {
                      visible: {
                        type: 'boolean',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.Switch',
                          dm: 'Switch'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'Switch',
                        default: animate.visible,
                        'x-reactions': [{
                          target: 'advanced.animate.type',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }, {
                          target: 'advanced.animate.dotColor',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }, {
                          target: 'advanced.animate.repeat',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }, {
                          target: 'advanced.animate.duration',
                          fulfill: {
                            state: {
                              visible: '{{$self.value}}'
                            }
                          }
                        }]
                      },
                      type: {
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.Type',
                          dm: 'Type'
                        }),
                        type: 'string',
                        'x-decorator': 'FormItem',
                        'x-component': 'Select',
                        enum: [{
                          label: $i18n.get({
                            id: 'basic.elements.SimpleEdge.registerMeta.Ball',
                            dm: 'Ball'
                          }),
                          value: 'circle-running'
                        }, {
                          label: $i18n.get({
                            id: 'basic.elements.SimpleEdge.registerMeta.DottedLine',
                            dm: 'Dotted line'
                          }),
                          value: 'line-dash'
                        }, {
                          label: $i18n.get({
                            id: 'basic.elements.SimpleEdge.registerMeta.GradualLength',
                            dm: 'Gradual length'
                          }),
                          value: 'line-growth'
                        }],
                        default: animate.type
                      },
                      dotColor: {
                        type: 'string',
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.BallColor',
                          dm: 'Ball color'
                        }),
                        'x-decorator': 'FormItem',
                        'x-component': 'ColorInput',
                        default: animate.dotColor
                      },
                      repeat: {
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.Repeat',
                          dm: 'Repeat'
                        }),
                        type: 'string',
                        'x-decorator': 'FormItem',
                        'x-component': 'Switch',
                        default: animate.repeat
                      },
                      duration: {
                        title: $i18n.get({
                          id: 'basic.elements.SimpleEdge.registerMeta.Duration',
                          dm: 'Duration'
                        }),
                        type: 'string',
                        'x-decorator': 'FormItem',
                        'x-component': 'NumberPicker',
                        default: animate.duration
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };
  return schema;
};
export default registerMeta;