function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { uniqueElementsBy } from './common';
/**
 *
 * @param assets 服务端拿到的资产：Elements
 * @param data 图数据
 * @returns
 */
var getElementsByAssets = function getElementsByAssets(assets, data, schemaData) {
  var nodeElements = {};
  var edgeElements = {};
  Object.keys(assets).forEach(function (key) {
    var element = assets[key];
    //@ts-ignore
    var info = element.info,
      registerMeta = element.registerMeta,
      registerShape = element.registerShape,
      registerTransform = element.registerTransform,
      defaultProps = element.defaultProps;
    var id = info.id,
      name = info.name,
      category = info.category,
      type = info.type;
    var elementType = type === 'NODE' ? 'nodes' : 'edges';
    var propertiesKey = schemaData[elementType].reduce(function (acc, curr) {
      var properties = curr.properties;
      var item = Object.keys(properties).map(function (c) {
        return {
          id: c,
          type: properties[c]
        };
      });
      return [].concat(_toConsumableArray(acc), _toConsumableArray(item));
    }, []);
    var keys = uniqueElementsBy(propertiesKey, function (a, b) {
      return a.id === b.id;
    });
    var configObj = registerMeta({
      data: data,
      keys: keys,
      schemaData: schemaData
    });
    /** 默认的配置值 */
    // const defaultProps = extractDefault({ config: configObj, value: {} });
    var item = Object.assign(Object.assign({}, element), {
      id: id,
      props: defaultProps,
      name: name,
      info: info,
      meta: configObj
    });
    if (elementType === 'nodes') {
      nodeElements = Object.assign(Object.assign({}, nodeElements), _defineProperty({}, id, item));
    }
    if (elementType === 'edges') {
      edgeElements = Object.assign(Object.assign({}, edgeElements), _defineProperty({}, id, item));
    }
  });
  return {
    nodes: nodeElements,
    edges: edgeElements
  };
};
export default getElementsByAssets;