import { GIConfig, GraphSchemaData, utils } from '@antv/gi-sdk';
import Graphin, { GraphinData } from '@antv/graphin';
import { Alert, Button, Card, Col, Row, notification } from 'antd';
import * as React from 'react';
import MonacoEditor from 'react-monaco-editor';
import $i18n from '../../../../i18n';
interface SchemaEditorProps {
  schemaGraph: GraphinData;
  schemaData: GraphSchemaData;
  onSave: (params: any) => void;
  config: GIConfig;
}

let monacoRef;
const SchemaEditor: React.FunctionComponent<SchemaEditorProps> = props => {
  const { schemaData, onSave, config } = props;
  const editorDidMount = editor => {
    editor.focus();
  };
  const [state, setState] = React.useState({
    content: JSON.stringify(schemaData, null, 2),
    schemaGraph: props.schemaGraph,
  });

  const { content, schemaGraph } = state;
  const handleSave = () => {
    const monacoModel = monacoRef.editor.getModel();
    const content = monacoModel.getValue();

    let isError = false;
    let newSchemaData = { ...schemaData };
    try {
      newSchemaData = JSON.parse(content);
    } catch (error) {
      console.log('error', error);
      isError = true;
      notification.error({
        message: $i18n.get({
          id: 'gi-site.MetaPanel.DataPanel.SchemaEditor.ModelFormatParsingFailed',
          dm: 'Model format parsing failed',
        }),
        description: error.message,
      });
    }
    if (isError) {
      return;
    }
    //对接服务
    onSave &&
      onSave({
        schemaData: newSchemaData,
      });
  };
  const handlePreview = () => {
    const monacoModel = monacoRef.editor.getModel();
    const content = monacoModel.getValue();

    let newSchemaGraph = schemaGraph;
    try {
      newSchemaGraph = utils.getSchemaGraph(JSON.parse(content), config);
    } catch (error) {
      console.log('error', error);
      notification.error({
        message: $i18n.get({ id: 'gi-site.MetaPanel.DataPanel.SchemaEditor.ModelPreviewFailed', dm: 'Model preview failed' }),
        description: error.message,
      });
    }

    setState(preState => {
      return {
        ...preState,
        schemaGraph: newSchemaGraph,
      };
    });
  };

  return (
    <div>
      <Alert
        message={$i18n.get({
          id: 'gi-site.MetaPanel.DataPanel.SchemaEditor.TheGraphModelGraphSchema',
          dm: 'The Graph model (Graph Schema) is automatically generated by the G6VP platform based on the specified node type (NodeType) and edge type (EdgeType) when you upload data. You can edit it again, currently, only the code editing function and visual editing function are available. It is still under development...',
        })}
      />
      <Row gutter={8} style={{ marginTop: '12px' }}>
        <Col span={12}>
          <Card
            title={$i18n.get({ id: 'gi-site.MetaPanel.DataPanel.SchemaEditor.SchemaEditing', dm: 'Schema editing' })}
            extra={
              <Button type="primary" onClick={handlePreview}>
                {$i18n.get({ id: 'gi-site.MetaPanel.DataPanel.SchemaEditor.VisualPreview', dm: 'Visual preview' })}
              </Button>
            }
          >
            <MonacoEditor
              ref={node => {
                monacoRef = node;
              }}
              width="calc(100% + 24px)"
              height="calc(100vh - 350px)"
              language="json"
              theme="vs-light"
              defaultValue={content}
              options={{}}
              editorDidMount={editorDidMount}
            />
          </Card>
        </Col>
        <Col span={12}>
          <div style={{ height: 'calc(100vh - 380px)' }}>
            <Alert
              message={$i18n.get({
                id: 'gi-site.MetaPanel.DataPanel.SchemaEditor.NodetypeNodeType',
                dm: 'nodeType: Node type',
              })}
              type="success"
              style={{ marginBottom: '8px' }}
            />
            <Alert
              message={$i18n.get({
                id: 'gi-site.MetaPanel.DataPanel.SchemaEditor.PropertiesOtherAttributeFieldsOf',
                dm: 'properties: Other attribute fields of the node',
              })}
              type="success"
              style={{ marginBottom: '8px' }}
            />
            <Alert
              message={$i18n.get({
                id: 'gi-site.MetaPanel.DataPanel.SchemaEditor.NodetypekeyfrompropertiesAttributeFieldsOfThe',
                dm: 'nodeTypeKeyFromProperties: attribute fields of the mapping source of nodeType',
              })}
              type="success"
              style={{ marginBottom: '8px' }}
            />

            <Card
              title={$i18n.get({ id: 'gi-site.MetaPanel.DataPanel.SchemaEditor.PreviewModel', dm: 'Preview model' })}
              style={{ height: '100%' }}
              bodyStyle={{ height: '100%' }}
            >
              <Graphin
                style={{ minHeight: '300px', background: 'var(--background-color)' }}
                data={schemaGraph}
                fitView
                layout={{ type: 'graphin-force', animation: false }}
              ></Graphin>
            </Card>
          </div>
        </Col>
      </Row>
      <Button
        type="primary"
        onClick={handleSave}
        style={{
          position: 'absolute',
          top: '10px',
          right: '80px',
        }}
      >
        {$i18n.get({ id: 'gi-site.MetaPanel.DataPanel.SchemaEditor.SaveModel', dm: 'Save model' })}
      </Button>
    </div>
  );
};

export default SchemaEditor;
