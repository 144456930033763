function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { useContext } from '@antv/gi-sdk';
import React, { useEffect } from 'react';
var useContextMenu = function useContextMenu(props) {
  var _props$bindTypes = props.bindTypes,
    bindTypes = _props$bindTypes === void 0 ? ['node'] : _props$bindTypes,
    container = props.container;
  var _useContext = useContext(),
    graph = _useContext.graph;
  var _React$useState = React.useState({
      visible: false,
      x: 0,
      y: 0,
      item: null,
      selectedItems: []
    }),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    state = _React$useState2[0],
    setState = _React$useState2[1];
  var handleShow = function handleShow(e) {
    var _a, _b;
    e.preventDefault();
    e.stopPropagation();
    var width = graph.get('width');
    var height = graph.get('height');
    if (!container.current) {
      return;
    }
    var bbox = container.current.getBoundingClientRect();
    var offsetX = graph.get('offsetX') || 0;
    var offsetY = graph.get('offsetY') || 0;
    var graphTop = graph.getContainer().offsetTop;
    var graphLeft = graph.getContainer().offsetLeft;
    var x = e.canvasX + graphLeft + offsetX;
    var y = e.canvasY + graphTop + offsetY;
    // when the menu is (part of) out of the canvas
    if (x + bbox.width > width) {
      x = e.canvasX - bbox.width - offsetX + graphLeft;
    }
    if (y + bbox.height > height) {
      y = e.canvasY - bbox.height - offsetY + graphTop;
    }
    if (((_b = (_a = e.item) === null || _a === void 0 ? void 0 : _a.getType) === null || _b === void 0 ? void 0 : _b.call(_a)) === 'node') {
      // 如果YesNode，则x，y指定到Node的中心点
      // eslint-disable-next-line no-underscore-dangle
      var _ref = e.item && e.item.getModel(),
        PointX = _ref.x,
        PointY = _ref.y;
      var CenterCanvas = graph.getCanvasByPoint(PointX, PointY);
      var daltX = e.canvasX - CenterCanvas.x;
      var daltY = e.canvasY - CenterCanvas.y;
      x = x - daltX;
      y = y - daltY;
    }
    /** Settings变量 */
    setState(function (preState) {
      return Object.assign(Object.assign({}, preState), {
        visible: true,
        x: x,
        y: y,
        item: e.item
      });
    });
  };
  var handleClose = function handleClose() {
    setState(function (preState) {
      if (preState.visible) {
        return Object.assign(Object.assign({}, preState), {
          visible: false,
          x: 0,
          y: 0
        });
      }
      return preState;
    });
  };
  useEffect(function () {
    var handleSaveAllItem = function handleSaveAllItem(e) {
      setState(function (preState) {
        return Object.assign(Object.assign({}, preState), {
          selectedItems: e.selectedItems
        });
      });
    };
    // @ts-ignore
    bindTypes.forEach(function (bindType) {
      graph.on("".concat(bindType, ":contextmenu"), handleShow);
    });
    graph.on('canvas:click', handleClose);
    graph.on('canvas:drag', handleClose);
    graph.on('wheelzoom', handleClose);
    if (bindTypes.includes('canvas')) {
      //@ts-ignore
      graph.on('nodeselectchange', handleSaveAllItem);
    }
    return function () {
      bindTypes.forEach(function (bindType) {
        graph.off("".concat(bindType, ":contextmenu"), handleShow);
      });
      graph.off('canvas:click', handleClose);
      graph.off('canvas:drag', handleClose);
      graph.off('wheelzoom', handleClose);
      graph.off('nodeselectchange', handleSaveAllItem);
    };
  }, [graph, bindTypes]);
  var x = state.x,
    y = state.y,
    visible = state.visible,
    item = state.item,
    selectedItems = state.selectedItems;
  return {
    oneShow: handleShow,
    onClose: handleClose,
    item: item,
    selectedItems: selectedItems,
    visible: visible,
    x: x,
    y: y
  };
};
export default useContextMenu;