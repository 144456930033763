function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import $i18n from '../../i18n';
var modes = ['TableMode', 'MapMode'];
var registerMeta = function registerMeta(context) {
  var _context$GIAC_CONTENT = context.GIAC_CONTENT_ITEMS,
    GIAC_CONTENT_ITEMS = _context$GIAC_CONTENT === void 0 ? [] : _context$GIAC_CONTENT,
    GIAC_ITEMS = context.GIAC_ITEMS;
  var modeItems = _toConsumableArray(GIAC_CONTENT_ITEMS).filter(function (item) {
    return modes.includes(item.value);
  });
  var schema = {
    GI_CONTAINER: {
      title: $i18n.get({
        id: 'advance.components.ModeSwitch.registerMeta.IntegratedComponents',
        dm: '集成组件'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        mode: 'multiple'
      },
      enum: modeItems,
      default: []
    },
    // ...metas,
    placement: {
      title: $i18n.get({
        id: 'advance.components.ModeSwitch.registerMeta.PlacementOrientation',
        dm: '放置方位'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        options: [{
          value: 'LT',
          label: $i18n.get({
            id: 'advance.components.ModeSwitch.registerMeta.TopLeftTop',
            dm: '左上 / top'
          })
        }, {
          value: 'RT',
          label: $i18n.get({
            id: 'advance.components.ModeSwitch.registerMeta.TopRightRight',
            dm: '右上 / right'
          })
        }, {
          value: 'LB',
          label: $i18n.get({
            id: 'advance.components.ModeSwitch.registerMeta.LowerLeftLeft',
            dm: '左下 / left'
          })
        }, {
          value: 'RB',
          label: $i18n.get({
            id: 'advance.components.ModeSwitch.registerMeta.BottomRightBottom',
            dm: '右下 / bottom'
          })
        }]
      },
      default: 'LT'
    },
    offset: {
      title: $i18n.get({
        id: 'advance.components.ModeSwitch.registerMeta.OffsetDistance',
        dm: '偏移距离'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Offset',
      default: [16, 8]
    }
  };
  return schema;
};
export default registerMeta;