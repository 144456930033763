function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
/**
 *
 * @param source 筛选前的画布数据
 * @param filterCriteria 筛选标准
 * @param graph G6 画布实例
 * @returns
 */
export var filterGraphData = function filterGraphData(source, filterCriteria, isFilterIsolatedNodes) {
  var analyzerType = filterCriteria.analyzerType,
    isFilterReady = filterCriteria.isFilterReady,
    elementType = filterCriteria.elementType,
    prop = filterCriteria.prop,
    selectValue = filterCriteria.selectValue,
    range = filterCriteria.range;
  if (!isFilterReady || analyzerType === 'NONE') {
    return source;
  }
  var newData = {
    nodes: [],
    edges: []
  };
  if (elementType === 'node') {
    var inValidNodes = new Set();
    newData.nodes = source.nodes.filter(function (node) {
      if (analyzerType === 'SELECT' || analyzerType === 'PIE' || analyzerType === 'WORDCLOUD' || analyzerType === 'COLUMN') {
        if (node.data && node.data[prop] != undefined && (selectValue === null || selectValue === void 0 ? void 0 : selectValue.indexOf(node.data[prop])) !== -1) {
          return true;
        }
        inValidNodes.add(node.id);
        return false;
      } else if (analyzerType === 'HISTOGRAM') {
        // const min = range![0];
        // const max = range![1];
        // if (node.data && node.data[prop!] && min <= node.data[prop!] && node.data[prop!] <= max) {
        //   return true;
        // }
        // inValidNodes.add(node.id);
        // return false;
        var _iterator = _createForOfIteratorHelper(range),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var arr = _step.value;
            var min = arr[0];
            var max = arr[1];
            if (node.data && node.data[prop] && min <= node.data[prop] && node.data[prop] <= max) {
              return true;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        inValidNodes.add(node.id);
        return false;
      }
    });
    newData.edges = source.edges.filter(function (edge) {
      return !inValidNodes.has(edge.source) && !inValidNodes.has(edge.target);
    });
  } else if (elementType === 'edge') {
    var validNodes = new Set();
    newData.edges = source.edges.filter(function (edge) {
      if (analyzerType === 'SELECT' || analyzerType === 'PIE' || analyzerType === 'WORDCLOUD' || analyzerType === 'COLUMN') {
        if (edge.data && edge.data[prop] != undefined && (selectValue === null || selectValue === void 0 ? void 0 : selectValue.indexOf(edge.data[prop])) !== -1) {
          validNodes.add(edge.source);
          validNodes.add(edge.target);
          return true;
        }
        return false;
      } else if (analyzerType === 'HISTOGRAM') {
        // const min = range![0];
        // const max = range![1];
        // if (
        //   edge.data &&
        //   edge.data[prop!] &&
        //   min <= edge.data[prop!] &&
        //   edge.data[prop!] <= max
        // ) {
        //   validNodes.add(edge.source);
        //   validNodes.add(edge.target);
        //   return true;
        // }
        // return false;
        var _iterator2 = _createForOfIteratorHelper(range),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var arr = _step2.value;
            var min = arr[0];
            var max = arr[1];
            if (edge.data && edge.data[prop] && min <= edge.data[prop] && edge.data[prop] <= max) {
              validNodes.add(edge.source);
              validNodes.add(edge.target);
              return true;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
        return false;
      }
    });
    newData.nodes = isFilterIsolatedNodes ? source.nodes.filter(function (node) {
      return validNodes.has(node.id);
    }) : source.nodes;
  }
  return newData;
};
/**
 *
 * @param graphData 画布数据
 * @param prop 节点/边属性
 * @param elementType 元素类型
 * @returns 图表数据
 */
export var getChartData = function getChartData(graphData, prop, elementType) {
  var elements = elementType === 'node' ? graphData.nodes : graphData.edges;
  var chartData = new Map();
  elements === null || elements === void 0 ? void 0 : elements.forEach(function (e) {
    e.data && e.data[prop] != undefined && chartData.set(e.data[prop], chartData.has(e.data[prop]) ? chartData.get(e.data[prop]) + 1 : 1);
  });
  return chartData;
};
/**
 *
 * @param graphData 画布数据
 * @param prop 节点/边属性
 * @param elementType 元素类型
 * @returns 直方图图表数据
 */
export var getHistogramData = function getHistogramData(graphData, prop, elementType) {
  var elements = elementType === 'node' ? graphData.nodes : graphData.edges;
  var data = elements.filter(function (e) {
    return e.data && e.data[prop] && typeof e.data[prop] === 'number';
  }).map(function (e) {
    return {
      value: e.data[prop]
    };
  });
  data.sort(function (a, b) {
    return a.value - b.value;
  });
  return data;
};
//@todo 感觉需要算法优化下，后面再做吧
/**
 *高亮选中的节点和边
 * @param graph G6 graph 实例
 * @param data 子图数据
 * @returns
 */
export var highlightSubGraph = function highlightSubGraph(graph, data) {
  if (!graph || graph.destroyed) return {};
  var source = graph.save();
  var nodeIds = data.nodes.map(function (node) {
    return node.id;
  });
  var edgeIds = [];
  /** 需要考虑聚合边的情况，需要构造全量的边 */
  data.edges.forEach(function (edge) {
    var aggregate = edge.aggregate;
    if (aggregate) {
      aggregate.forEach(function (item) {
        edgeIds.push(item.id);
      });
    } else {
      edgeIds.push(edge.id);
    }
  });
  var sourceNodesCount = source.nodes.length;
  var sourceEdgesCount = edgeIds.length; //考虑聚合边
  var nodesCount = data.nodes.length;
  var edgesCount = data.edges.length;
  var isEmpty = nodesCount === 0 && edgesCount === 0;
  var isFull = nodesCount === sourceNodesCount && edgesCount === sourceEdgesCount;
  // 如果是空或者全部图数据，则恢复到画布原始状态，取消高亮
  if (isEmpty || isFull) {
    source.nodes.forEach(function (node) {
      graph.clearItemStates(node.id);
    });
    source.edges.forEach(function (edge) {
      graph.clearItemStates(edge.id);
    });
    return {
      isEmpty: isEmpty,
      isFull: isFull
    };
  }
  source.nodes.forEach(function (node) {
    var hasMatch = nodeIds.includes(node.id);
    if (hasMatch) {
      graph.setItemState(node.id, 'disabled', false);
      graph.setItemState(node.id, 'selected', true);
    } else {
      graph.setItemState(node.id, 'selected', false);
      graph.setItemState(node.id, 'disabled', true);
    }
  });
  source.edges.forEach(function (edge) {
    var aggregate = edge.aggregate,
      id = edge.id;
    var hasMatch = false;
    /** 考虑聚合边的情况，aggregate 数据中的 edgeId 匹配上一个就可以高亮整个聚合边 */
    if (aggregate) {
      hasMatch = aggregate.map(function (e) {
        return e.id;
      }).some(function (itemId) {
        return edgeIds.includes(itemId);
      });
    } else {
      hasMatch = edgeIds.includes(id);
    }
    if (hasMatch) {
      graph.setItemState(edge.id, 'disabled', false);
      graph.setItemState(edge.id, 'selected', true);
    } else {
      graph.setItemState(edge.id, 'selected', false);
      graph.setItemState(edge.id, 'disabled', true);
    }
  });
  return {
    isEmpty: isEmpty,
    isFull: isFull
  };
};