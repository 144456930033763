// import ServerComponent from '../components';
import * as ElementProperties from './ElementProperties';
import Engine from './Engine';
import * as GremlinQuery from './GremlinQuery';
import * as HugeGraphService from './HugeGraphService';
import * as Initializer from './Initializer';
import * as NeighborsQuery from './NeighborsQuery';
import $i18n from '../i18n';
export default {
  id: 'HugeGraph',
  name: 'HugeGraph',
  title: $i18n.get({
    id: 'hugegraph.src.services.HugegraphEngineService',
    dm: 'HugeGraph engine service'
  }),
  desc: $i18n.get({
    id: 'hugegraph.src.services.HugegraphComputingEngineAssetPackage',
    dm: 'HugeGraph computing engine asset package provided by GI team by default'
  }),
  type: 'database',
  cover: 'https://mdn.alipayobjects.com/huamei_qa8qxu/afts/img/A*6NRERpsvzMcAAAAAAAAAAAAADmJ7AQ/original',
  group: $i18n.get({
    id: 'hugegraph.src.services.GVpTeam',
    dm: 'G6VP team'
  }),
  component: Engine,
  services: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, Initializer), GremlinQuery), NeighborsQuery), HugeGraphService), ElementProperties)
};