function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import dayjs from 'dayjs';
import { getTimeFormat } from './panel/helper';
export function timeParser(time, timeGranularity) {
  if (typeof time === 'number') {
    if (time.toString().length === 10) return time * 1000;
    return time;
  }
  return dayjs(time, getTimeFormat(timeGranularity)).valueOf(); //new Date(time).getTime();
}
/**
 * 根据时间范围筛选图数据
 * 从 context graph 中读取图数据，然后根据时间粒度进行筛选
 * 由于无法识别 graphData 是播放过程更新的还是外部更新的
 * 因此对每个播放的节点/边都加上 __GI_PLAYING__ 标记，用于区分
 * @param data 图数据
 * @param range 时间范围
 * @param timeField 时间字段(边)
 * @param timeFieldNode 时间字段(节点，默认与边相同)
 * @returns
 */
export function dataFilter(data, range, timeGranularity, timeField, type) {
  var parser = function parser(time) {
    return timeParser(time, timeGranularity);
  };
  var baseFiltered = data[type].filter(function (item) {
    if (!item.data[timeField]) return true;
    var time = parser(item.data[timeField]);
    return time >= parser(range[0]) && time <= parser(range[1]);
  });
  var another = data[type === 'nodes' ? 'edges' : 'nodes'];
  var addPlayingTag = function addPlayingTag(data) {
    var now = new Date().getTime();
    return data.map(function (datum) {
      return Object.assign(Object.assign({}, datum), {
        __GI_PLAYING__: now
      });
    });
  };
  return _defineProperty(_defineProperty({}, type, addPlayingTag(baseFiltered)), type === 'nodes' ? 'edges' : 'nodes', addPlayingTag(another));
}
/**
 * 获取图数据中的时间范围
 */
export function getTimeRange(data, timeGranularity, timeField) {
  var parser = function parser(t) {
    return timeParser(t, timeGranularity);
  };
  var times = data.map(function (datum) {
    return datum[timeField];
  }).filter(Boolean).map(parser);
  var timeRange = [Math.min.apply(Math, _toConsumableArray(times)), Math.max.apply(Math, _toConsumableArray(times))];
  return timeRange;
}
/**
 * 转换图数据为 G2 渲染数据
 * @param data 图数据
 */
export function dataTransform(data, type) {
  return data[type].map(function (node) {
    return node.data;
  });
}
/**
 * 判断图数据中是否有正在播放的节点/边
 * @param data 图数据
 * @returns 是否有正在播放的节点/边
 */
export function isPlayingData(data) {
  return data.nodes.some(function (node) {
    return node.__GI_PLAYING__;
  }) || data.edges.some(function (edge) {
    return edge.__GI_PLAYING__;
  });
}