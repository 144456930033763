import { S2Event } from '@antv/s2';
import React from 'react';
import { useContext } from '@antv/gi-sdk';
var useListenEdgeSelect = function useListenEdgeSelect(isSelectedActive, s2Instance, isFullScreen) {
  var _useContext = useContext(),
    graphData = _useContext.data,
    graph = _useContext.graph,
    largeGraphData = _useContext.largeGraphData,
    updateContext = _useContext.updateContext;
  React.useEffect(function () {
    s2Instance === null || s2Instance === void 0 ? void 0 : s2Instance.on(S2Event.GLOBAL_SELECTED, function () {
      // isSelectedActiv For false Or全屏时，不Highlight选中Element
      if (!isSelectedActive || isFullScreen) {
        return;
      }
      var cells = s2Instance.interaction.getCells();
      console.log('cells:', cells);
      // if (cells.length === 0) {
      //   graphData.nodes.forEach(node => {
      //     graph.clearItemStates(node.id);
      //   });
      //   graphData.edges.forEach(edge => {
      //     graph.clearItemStates(edge.id);
      //   });
      //   return;
      // }
      var selectedEdges = new Set();
      var relatedNodes = new Set();
      cells.forEach(function (cell) {
        var _a;
        var rowIndex = cell.rowIndex;
        // @ts-ignore
        var rowData = s2Instance.dataSet.getMultiData();
        if (!rowData) return;
        var nodeID = (_a = rowData[rowIndex]) === null || _a === void 0 ? void 0 : _a.id;
        //@ts-ignore
        selectedEdges.add(nodeID);
      });
      if (largeGraphData) {
        var edges = largeGraphData.edges.filter(function (e) {
          if (selectedEdges.has(e.id)) {
            relatedNodes.add(e.target);
            relatedNodes.add(e.source);
            return true;
          }
          return false;
        });
        var nodes = largeGraphData.nodes.filter(function (n) {
          return relatedNodes.has(n.id);
        });
        var newData = {
          nodes: nodes,
          edges: edges
        };
        //console.log("newData:", newData)
        updateContext(function (draft) {
          draft.source = newData;
          draft.data = newData;
        });
      } else {
        graphData.edges.forEach(function (edgeConfig) {
          var id = edgeConfig.id;
          var item = graph.findById(id);
          if (selectedEdges.has(id)) {
            item.hasState('disabled') && graph.setItemState(id, 'disabled', false);
            !item.hasState('selected') && graph.setItemState(id, 'selected', true);
            relatedNodes.add(edgeConfig.target);
            relatedNodes.add(edgeConfig.source);
          } else {
            !item.hasState('disabled') && graph.setItemState(id, 'disabled', true);
            item.hasState('selected') && graph.setItemState(id, 'selected', false);
          }
        });
        graphData.nodes.forEach(function (nodeConfig) {
          var id = nodeConfig.id;
          var item = graph.findById(id);
          //graph.setItemState(id, 'disabled', true);
          if (relatedNodes.has(id)) {
            item.hasState('disabled') && graph.setItemState(id, 'disabled', false);
            !item.hasState('selected') && graph.setItemState(id, 'selected', true);
          } else {
            !item.hasState('disabled') && graph.setItemState(id, 'disabled', true);
            item.hasState('selected') && graph.setItemState(id, 'selected', false);
          }
        });
      }
    });
    return function () {
      s2Instance === null || s2Instance === void 0 ? void 0 : s2Instance.off(S2Event.GLOBAL_SELECTED);
    };
  }, [isSelectedActive, largeGraphData, graphData, s2Instance, isFullScreen]);
};
export default useListenEdgeSelect;