function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { uniqueElementsBy } from './common';
export var getServiceOptions = function getServiceOptions(services, serviceId) {
  return services.filter(function (c) {
    return c.id.includes(serviceId);
  }).map(function (c) {
    return {
      value: c.id,
      label: c.id
    };
  });
};
export var getServiceOptionsByEngineId = function getServiceOptionsByEngineId(services, serviceId, engineId) {
  var options = services.filter(function (c) {
    return c.id.includes(serviceId);
  }).map(function (c) {
    return {
      value: c.id,
      label: c.id
    };
  });
  var defaultValue = options.find(function (c) {
    return c.value.startsWith(engineId);
  }) || options[0];
  return {
    options: options,
    defaultValue: (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.value) || ''
  };
};
export var getCombineServer = function getCombineServer(servers) {
  var serverMap = [];
  servers.forEach(function (server) {
    var id = server.id,
      services = server.services;
    var matchServer = serverMap[id];
    if (matchServer) {
      serverMap[id] = Object.assign(Object.assign(Object.assign({}, matchServer), server), {
        services: Object.assign(Object.assign({}, matchServer.services), services)
      });
    } else {
      serverMap[id] = server;
    }
  });
  return Object.values(serverMap);
};
/**
 *
 * @param servers 资产包中的引擎
 * @returns
 */
export var getCombineServices = function getCombineServices(servers) {
  if (!servers) {
    return [];
  }
  var sers = servers.reverse().reduce(function (acc, curr) {
    if (!curr.services) {
      return _toConsumableArray(acc);
    }
    var id = curr.id,
      services = curr.services;
    var sers = Object.keys(services).map(function (key) {
      var service = services[key];
      return Object.assign(Object.assign({}, service), {
        id: "".concat(id, "/").concat(key)
      });
    });
    return [].concat(_toConsumableArray(acc), _toConsumableArray(sers));
  }, []);
  return uniqueElementsBy(sers, function (a, b) {
    return a.id === b.id;
  });
};