function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
// import { notification } from 'antd';
import * as React from 'react';
import { useContext } from './context';
import { isPosition, isStyles } from './process';
export var defaultInitializerCfg = {
  id: 'Initializer',
  props: {
    GI_INITIALIZER: true,
    serviceId: 'GI_SERVICE_INTIAL_GRAPH',
    schemaServiceId: 'GI_SERVICE_SCHEMA'
  }
};
var Initializer = function Initializer(props) {
  var context = useContext();
  var serviceId = props.serviceId,
    schemaServiceId = props.schemaServiceId;
  var services = context.services,
    updateContext = context.updateContext,
    transform = context.transform,
    largeGraphLimit = context.largeGraphLimit;
  React.useEffect(function () {
    var _services$find = services.find(function (s) {
        return s.id === serviceId;
      }),
      initialService = _services$find.service;
    var _ref = services.find(function (s) {
        return s.id === schemaServiceId;
      }) || {
        service: function service() {
          return Promise.resolve(null);
        }
      },
      schemaService = _ref.service;
    Promise.all([schemaService(), initialService()]).then(function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
        schema = _ref3[0],
        _ref3$ = _ref3[1],
        data = _ref3$ === void 0 ? {
          nodes: [],
          edges: []
        } : _ref3$;
      updateContext(function (draft) {
        var nodes = data.nodes,
          edges = data.edges;
        if (schema) {
          // 更新schemaData
          draft.schemaData = schema;
        }
        var position = isPosition(nodes);
        var style = isStyles(nodes);
        if (position) {
          draft.layout.type = 'preset';
        }
        if (style) {
          draft.data = data;
          draft.source = data;
        } else {
          if (nodes.length > largeGraphLimit) {
            console.warn('The amount of data loaded is too large.，We recommend that you aggregate data and switch to grid layout by default. You can also load the large graph component in the asset center to enable 3D rendering.');
            var newData = transform(data, true);
            draft.largeGraphMode = true;
            draft.largeGraphData = newData;
            draft.source = newData;
            draft.data = {
              nodes: [],
              edges: []
            };
          } else {
            var _newData = transform(data, true);
            draft.rawData = Object.assign({}, data);
            draft.data = _newData;
            draft.source = _newData;
          }
        }
        draft.initialized = true;
        draft.layoutCache = false;
      });
    });
  }, [largeGraphLimit]);
  return null;
};
export default Initializer;