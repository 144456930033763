function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
// 返回轮廓配置
export var getHullOptions = function getHullOptions(option) {
  return {
    id: option.id || "".concat(Math.random().toString(36).substr(2)),
    type: option.type || 'bubble',
    members: option.members,
    padding: option.padding || 10,
    style: option.style
  };
};
// 创建轮廓
export var drawHulls = function drawHulls(hullOptions, hulls, graph) {
  if (!graph) {
    return;
  }
  hullOptions === null || hullOptions === void 0 ? void 0 : hullOptions.forEach(function (option) {
    // 过滤传入的members中在画布中实际不存在的nodeId、members中id不能相同
    option.members = _toConsumableArray(new Set(option.members)).filter(function (member) {
      return graph.findById(member);
    });
  });
  if (!hulls) {
    hulls = hullOptions === null || hullOptions === void 0 ? void 0 : hullOptions.map(function (option) {
      var members = option.members;
      // members长度为0的情况，调用hull.updateData(hull.members)会报错
      if ((members === null || members === void 0 ? void 0 : members.length) < 1) {
        return undefined;
      }
      return graph.createHull(Object.assign(Object.assign({}, option), {
        members: members
      }));
    }).filter(function (item) {
      return !!item;
    });
  } else {
    hulls.forEach(function (hull) {
      hull.updateData(hull.members);
    });
  }
  return hulls;
};
// 移除轮廓
export var removeHulls = function removeHulls(hulls, graph) {
  var hullMap = graph === null || graph === void 0 ? void 0 : graph.get('hullMap');
  if (hulls) {
    hulls.forEach(function (hull) {
      if (hull) {
        graph === null || graph === void 0 ? void 0 : graph.removeHull(hull);
        if (hullMap) {
          delete hullMap[hull.id];
        }
      }
    });
    hulls = null;
  }
};
export default {
  getHullOptions: getHullOptions,
  removeHulls: removeHulls,
  drawHulls: drawHulls
};