import { FileTextOutlined } from '@ant-design/icons';
import { IUserEdge, IUserNode } from '@antv/graphin';
import { Alert, Button, Row, Space, Upload, message, notification } from 'antd';
import React, { useState } from 'react';
import { Updater } from 'use-immer';
import xlsx2js from 'xlsx2js';
import { IInputData, IState } from './typing';
import { getOptions } from './utils';
import $i18n from '../../i18n';

interface IProps {
  state: IState;
  updateState: Updater<IState>;
  updateGISite: (params: any) => void;
}

const { Dragger } = Upload;

const UploadLocalFile: React.FC<IProps> = props => {
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const { state, updateState, updateGISite } = props;

  const draggerProps = {
    name: 'file',
    defaultFileList: state.inputData,
    onRemove: file => {
      const renderData = state.inputData.filter(d => d.uid !== file.uid);
      if (renderData.length === 0) {
        setButtonDisabled(true);
      }
      updateState(draft => {
        draft.inputData = renderData;
      });
      mergeData(renderData);
    },
    customRequest: async options => {
      const { file, onSuccess } = options;
      let fileData;

      setButtonDisabled(false);

      if (!file) {
        return false;
      } else if (/\.(xls|xlsx|csv)$/.test(file.name.toLowerCase())) {
        const data = await xlsx2js(file);
        const isNodeFile = (file.name as string).includes('nodes');
        const isEdgeFile = (file.name as string).includes('edges');
        if (!isNodeFile && !isEdgeFile) {
          notification.error({
            message: $i18n.get({
              id: 'gi-site.components.FileServerEngine.UploadLocalFile.TheFileNameIsNot',
              dm: 'The file name is not standard.',
            }),
            description: $i18n.get({
              id: 'gi-site.components.FileServerEngine.UploadLocalFile.AccordingToTheSpecificationsCsv',
              dm: '按照规范，CSV Or者 Excel 文件，文件名中需要Include nodes Or者 edges 以此区分YesPoint Table还YesEdge table。规范命名示例如下:\n             交易表.edges.csv 用户表.nodes.csv',
            }),

            duration: 0,
          });
          return;
        }

        fileData = isEdgeFile
          ? {
              nodes: [],
              edges: data,
              combos: [],
            }
          : {
              nodes: data,
              edges: [],
              combos: [],
            };

        const renderData: IInputData[] = [
          ...state.inputData,
          {
            uid: file.uid,
            name: file.name,
            data: fileData,
            transfunc: state.transfunc,
            enable: true,
          },
        ];
        updateState(draft => {
          draft.inputData = renderData;
        });
        onSuccess('Ok');
        mergeData(renderData);
      } else if (/\.(json)$/.test(file.name.toLowerCase())) {
        const reader = new FileReader();
        reader.readAsText(file, 'utf-8');
        reader.onload = fileReader => {
          fileData = JSON.parse(fileReader.target!.result as string);

          const renderData = [
            ...state.inputData,
            {
              uid: file.uid,
              name: file.name,
              data: fileData,
              transfunc: state.transfunc,
              enable: true,
            },
          ];
          updateState(draft => {
            draft.inputData = renderData;
          });
          onSuccess('Ok');
          mergeData(renderData);
        };
      } else {
        return false;
      }
    },
  };

  const downloadMockFiles = async () => {
    window.open('https://github.com/antvis/G6VP/blob/master/packages/gi-site/src/mock/excel');
  };

  const downloadJSON = async () => {
    window.open('https://github.com/antvis/G6VP/blob/master/packages/gi-site/src/mock/fund-force.json');
  };

  const mergeData = (renderData: IInputData[] = state.inputData) => {
    try {
      let nodes: IUserNode[] = [];
      let edges: IUserEdge[] = [];
      let combos: any[] = [];

      renderData.forEach(d => {
        nodes = [...nodes, ...d.data.nodes];
        edges = [...edges, ...d.data.edges];
        combos = [...combos, ...(d.data.combos ? d.data.combos : [])];
      });
      updateState(draft => {
        draft.data = { nodes, edges };
        draft.transData = eval(state.transfunc)({ nodes, edges });
      });
    } catch (e) {
      message.error(
        $i18n.get({
          id: 'gi-site.components.FileServerEngine.UploadLocalFile.PleaseUploadValidData',
          dm: 'Please upload valid data',
        }),
      );
    }
  };

  const checkData = () => {
    updateState(draft => {
      draft.activeKey++;
      draft.transColumns = getOptions(state.data);
      draft.tableData = state.transData?.nodes.map((d, i) => {
        return {
          ...d,
          key: i,
        };
      });
    });
  };

  return (
    <div className="upload-panel" style={{ margin: '10px 0px 0px 0px' }}>
      <div style={{ textAlign: 'left' }}>
        <Alert
          message={$i18n.get({
            id: 'gi-site.components.FileServerEngine.UploadLocalFile.JsonFileSpecificationPointAnd',
            dm: 'JSON file specification: Node and edge data must be uploaded in the same JSON file. nodes represent a collection of nodes and edges represent a collection of edges.',
          })}
          type="info"
          showIcon
          closable
          style={{ marginBottom: '12px' }}
          action={
            <Space direction="vertical">
              <Button size="small" type="primary" onClick={downloadJSON}>
                {$i18n.get({
                  id: 'gi-site.components.FileServerEngine.UploadLocalFile.DownloadSampleData',
                  dm: 'Download sample data',
                })}
              </Button>
            </Space>
          }
        />

        <Alert
          message={$i18n.get({
            id: 'gi-site.components.FileServerEngine.UploadLocalFile.CsvXlsXlsxFileSpecification',
            dm: 'CSV/XLS/XLSX file specification: upload the node table and edge table respectively. According to the convention, the node table file name must contain nodes, such as count.nodes.csv, and the Edge table file name must contain edges, such as transfer.edges.csv',
          })}
          type="info"
          showIcon
          closable
          style={{ marginBottom: '12px' }}
          action={
            <Space direction="vertical">
              <Button size="small" type="primary" onClick={downloadMockFiles}>
                {$i18n.get({
                  id: 'gi-site.components.FileServerEngine.UploadLocalFile.DownloadSampleData',
                  dm: 'Download sample data',
                })}
              </Button>
            </Space>
          }
        />
      </div>

      <h4 style={{ marginBottom: 0, marginTop: '10px' }}>
        {$i18n.get({ id: 'gi-site.components.FileServerEngine.UploadLocalFile.TransmittedData', dm: 'Transmitted data' })}
      </h4>

      <div className="upload-panel-section">
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <FileTextOutlined />
          </p>
          <p>
            {$i18n.get({
              id: 'gi-site.components.FileServerEngine.UploadLocalFile.ClickOrDragTheData',
              dm: 'Click or drag the data file here to upload it. JSON,CSV,XLS, and XLSX formats are supported.',
            })}
          </p>
          {/* <Divider /> */}
        </Dragger>
      </div>

      <Row style={{ padding: '30px 0px 10px 0px', justifyContent: 'center' }}>
        <Button type="primary" disabled={buttonDisabled} shape="round" onClick={checkData}>
          {$i18n.get({ id: 'gi-site.components.FileServerEngine.UploadLocalFile.GoToTheNextStep', dm: 'Go to the next step' })}
        </Button>
      </Row>
    </div>
  );
};

export default UploadLocalFile;
