import $i18n from '../../i18n';
var registerMeta = function registerMeta(context) {
  var presetOptions = [{
    label: $i18n.get({
      id: 'basic.layouts.GraphinForce.registerMeta.GridLayout',
      dm: 'Grid Layout'
    }),
    value: 'grid'
  }, {
    label: $i18n.get({
      id: 'basic.layouts.GraphinForce.registerMeta.CircularLayout',
      dm: 'Circular layout'
    }),
    value: 'circular'
  }, {
    label: $i18n.get({
      id: 'basic.layouts.GraphinForce.registerMeta.ConcentricCircleLayout',
      dm: 'Concentric circle layout'
    }),
    value: 'concentric'
  }, {
    label: $i18n.get({
      id: 'basic.layouts.GraphinForce.registerMeta.DirectedLayering',
      dm: 'Directed layering'
    }),
    value: 'dagre'
  }];
  return {
    stiffness: {
      type: 'number',
      title: $i18n.get({
        id: 'basic.layouts.GraphinForce.registerMeta.SpringStiffnessCoefficient',
        dm: 'Spring stiffness coefficient'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {
        step: 1
      },
      default: 200
    },
    repulsion: {
      title: $i18n.get({
        id: 'basic.layouts.GraphinForce.registerMeta.CullenConstantKeRepulsion',
        dm: 'Cullen constant Ke (repulsion)'
      }),
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {},
      default: 1000
    },
    damping: {
      title: $i18n.get({
        id: 'basic.layouts.GraphinForce.registerMeta.DampingCoefficient',
        dm: 'Damping coefficient'
      }),
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {
        step: 0.1,
        min: 0,
        max: 1
      },
      default: 0.9
    },
    animation: {
      title: $i18n.get({
        id: 'basic.layouts.GraphinForce.registerMeta.EnableAnimation',
        dm: 'Enable animation'
      }),
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: true
    },
    preset: {
      type: 'object',
      properties: {
        type: {
          type: 'string',
          title: $i18n.get({
            id: 'basic.layouts.GraphinForce.registerMeta.FrontLayout',
            dm: 'Front Layout'
          }),
          'x-component': 'Select',
          'x-decorator': 'FormItem',
          'x-component-props': {
            options: presetOptions
          },
          default: 'concentric'
        }
      }
    },
    defSpringLenCfg: {
      type: 'object',
      properties: {
        minLimitDegree: {
          title: $i18n.get({
            id: 'basic.layouts.GraphinForce.registerMeta.MinimumLimitDegree',
            dm: 'Minimum limit degree'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 5
        },
        maxLimitLength: {
          title: $i18n.get({
            id: 'basic.layouts.GraphinForce.registerMeta.MaximumLimitSideLength',
            dm: 'Maximum limit side length'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 500
        },
        defaultSpring: {
          title: $i18n.get({
            id: 'basic.layouts.GraphinForce.registerMeta.DefaultSideLength',
            dm: 'Default side length'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 100
        }
      }
    },
    centripetalOptions: {
      type: 'object',
      properties: {
        leaf: {
          title: $i18n.get({
            id: 'basic.layouts.GraphinForce.registerMeta.LeafNode',
            dm: 'Leaf node'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 2
        },
        single: {
          title: $i18n.get({
            id: 'basic.layouts.GraphinForce.registerMeta.IsolatedNode',
            dm: 'Isolated node'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 2
        },
        others: {
          title: $i18n.get({
            id: 'basic.layouts.GraphinForce.registerMeta.OtherNodes',
            dm: 'Other nodes'
          }),
          type: 'number',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: 1
        }
        // center: {
        //   title: 'Central Node',
        //   type: 'string',
        //   'x-decorator': 'FormItem',
        //   'x-component': 'Input.TextArea',
        //   default: `(_node) => {
        //     return {
        //       x: 400,
        //       y: 200,
        //     };
        //   }`,
        // },
      }
    }
  };
};
export default registerMeta;