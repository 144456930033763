import * as CypherQuery from './CypherQuery';
import Engine from './Engine';
import * as Initializer from './Initializer';
import * as NeighborsQuery from './NeighborsQuery';
import * as Neo4jService from './Neo4jService';
import $i18n from '../i18n';
export default {
  id: 'Neo4j',
  name: 'Neo4j',
  title: $i18n.get({
    id: 'neo4j.src.services.NeoJEngineService',
    dm: 'Neo4j engine service'
  }),
  desc: $i18n.get({
    id: 'neo4j.src.services.NeoJComputingEngineAsset',
    dm: 'Neo4j computing engine asset package provided by GI team by default'
  }),
  type: 'database',
  cover: 'https://mdn.alipayobjects.com/huamei_qa8qxu/afts/img/A*6NRERpsvzMcAAAAAAAAAAAAADmJ7AQ/original',
  group: $i18n.get({
    id: 'neo4j.src.services.GVpTeam',
    dm: 'G6VP team'
  }),
  component: Engine,
  services: Object.assign(Object.assign(Object.assign(Object.assign({}, Initializer), CypherQuery), NeighborsQuery), Neo4jService)
};