function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
var getScaleFunc = function getScaleFunc(scale) {
  var scaleFunc = function scaleFunc(val) {
    return val;
  };
  switch (scale) {
    case 'sqrt':
      scaleFunc = function scaleFunc(val) {
        return Math.sqrt(val);
      };
      break;
    case 'sqr':
      scaleFunc = function scaleFunc(val) {
        return val * val;
      };
      break;
    case 'log2':
      scaleFunc = function scaleFunc(val) {
        return Math.log2(val);
      };
      break;
    case 'log10':
      scaleFunc = function scaleFunc(val) {
        return Math.log(val);
      };
      break;
    case 'reciprocal':
      scaleFunc = function scaleFunc(val) {
        return 1 / (val || 1);
      };
      break;
  }
  return scaleFunc;
};
export var getEdgeWeightedStrength = function getEdgeWeightedStrength(options) {
  var _options$edgeStrength = options.edgeStrength,
    strength = _options$edgeStrength === void 0 ? 1 : _options$edgeStrength,
    _options$edgeWeightFi = options.edgeWeightFieldScale,
    scale = _options$edgeWeightFi === void 0 ? 1 : _options$edgeWeightFi,
    weightFeild = options.edgeWeightField;
  var typeField = 'edgeType';
  var fieldMap = {};
  weightFeild.forEach(function (weightField) {
    var _weightField$split = weightField.split('^^'),
      _weightField$split2 = _slicedToArray(_weightField$split, 2),
      edgeType = _weightField$split2[0],
      field = _weightField$split2[1];
    fieldMap[edgeType] = field;
  });
  var scaleFunc = getScaleFunc(scale);
  return function (model) {
    var fieldName = fieldMap[model[typeField]];
    if (fieldName) {
      var fieldVal = model.data[fieldName] || 1;
      var val = scaleFunc(Number(fieldVal * strength));
      return isNaN(val) || !val ? strength : val;
    }
    return strength;
  };
};
export var getNodeWeightedStrength = function getNodeWeightedStrength(options) {
  var _options$nodeStrength = options.nodeStrength,
    strength = _options$nodeStrength === void 0 ? 1 : _options$nodeStrength,
    _options$nodeWeightFi = options.nodeWeightFieldScale,
    scale = _options$nodeWeightFi === void 0 ? 1 : _options$nodeWeightFi,
    nodeWeightField = options.nodeWeightField,
    nodeWeightFieldFromEdge = options.nodeWeightFieldFromEdge,
    nodeWeightFromType = options.nodeWeightFromType;
  var weightFeild = nodeWeightFromType === 'node' ? nodeWeightField : nodeWeightFieldFromEdge;
  var fieldMap = {};
  weightFeild.forEach(function (weightField) {
    var _weightField$split3 = weightField.split('^^'),
      _weightField$split4 = _slicedToArray(_weightField$split3, 2),
      edgeType = _weightField$split4[0],
      field = _weightField$split4[1];
    fieldMap[edgeType] = field;
  });
  var scaleFunc = function scaleFunc(val) {
    return val;
  };
  switch (scale) {
    case 'sqrt':
      scaleFunc = function scaleFunc(val) {
        return Math.sqrt(val);
      };
      break;
    case 'sqr':
      scaleFunc = function scaleFunc(val) {
        return val * val;
      };
      break;
    case 'log2':
      scaleFunc = function scaleFunc(val) {
        return Math.log2(val);
      };
      break;
    case 'log10':
      scaleFunc = function scaleFunc(val) {
        return Math.log(val);
      };
      break;
    case 'reciprocal':
      scaleFunc = function scaleFunc(val) {
        return 1 / (val || 1);
      };
      break;
  }
  if (nodeWeightFromType === 'node') {
    return function (model) {
      var fieldName = fieldMap[model.nodeType];
      if (fieldName) {
        var fieldVal = model.data[fieldName] || 1;
        var val = scaleFunc(Number(fieldVal * strength));
        return isNaN(val) || !val ? strength : val;
      }
      return strength;
    };
  }
  return function (model) {
    var edges = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var total = 0;
    edges === null || edges === void 0 ? void 0 : edges.forEach(function (edge) {
      var source = edge.source,
        target = edge.target;
      if (source !== model.id && target !== model.id) return;
      var fieldName = fieldMap[edge.edgeType];
      var fieldVal = edge.data[fieldName] || 1;
      var val = Number(fieldVal);
      total += isNaN(val) || !val ? 0 : val;
    });
    if (total && !isNaN(total)) return scaleFunc(total * strength);
    return strength;
  };
};