import Component from './Component';
import registerMeta from './registerMeta';
/**   index.md 中解析得到默认值，也可用户手动修改 */
import $i18n from '../../i18n';
var info = {
  id: 'AddSheetbar',
  name: $i18n.get({
    id: 'advance.components.AddSheetbar.AddATab',
    dm: 'Add a tab'
  }),
  desc: $i18n.get({
    id: 'advance.components.AddSheetbar.AddATab',
    dm: 'Add a tab'
  }),
  cover: 'http://xxxx.jpg',
  category: 'system-interaction',
  icon: 'icon-plus',
  type: 'GIAC',
  docs: 'https://www.yuque.com/antv/gi/wwzhrmtem0m2g09p'
};
export default {
  info: info,
  component: Component,
  registerMeta: registerMeta
};