function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import $i18n from '../i18n';
var aggregate = function aggregate(data) {
  var nodes = data.nodes,
    edges = data.edges;
  var edgeMap = new Map();
  edges.forEach(function (edge, index) {
    var source = edge.source,
      target = edge.target,
      edgeType = edge.edgeType;
    /** 汇总边 KEY */
    var key = "".concat(source, "->").concat(edgeType, "->").concat(target);
    var values = edgeMap.get(key);
    if (values) {
      // 为了和transform的edgeId逻辑保持一致，后续统一处理
      edgeMap.set(key, [].concat(_toConsumableArray(values), [Object.assign(Object.assign({}, edge), {
        id: edge.id || "".concat(source, "-").concat(target, "-").concat(index)
      })]));
    } else {
      edgeMap.set(key, [Object.assign(Object.assign({}, edge), {
        id: edge.id || "".concat(source, "-").concat(target, "-").concat(index)
      })]);
    }
  });
  var aggregateEdges = _toConsumableArray(edgeMap.keys()).map(function (key) {
    var children = edgeMap.get(key);
    var firstEdge = children[0];
    var source = firstEdge.source,
      target = firstEdge.target,
      edgeType = firstEdge.edgeType,
      edgeTypeKeyFromProperties = firstEdge.edgeTypeKeyFromProperties;
    var aggregate = children.length > 1;
    if (aggregate) {
      return {
        source: source,
        target: target,
        edgeType: edgeType,
        edgeTypeKeyFromProperties: edgeTypeKeyFromProperties,
        aggregate: children.map(function (item) {
          return Object.assign(Object.assign({}, item), {
            data: Object.assign(Object.assign({}, item.data), {
              GI_AGGREGATE_ID: key
            })
          });
        }),
        GI_AGGREGATE_ID: key,
        data: _defineProperty(_defineProperty(_defineProperty({
          source: source,
          target: target
        }, edgeTypeKeyFromProperties, edgeType), "aggregate", children), "aggregateCount", $i18n.get({
          id: 'sdk.src.process.aggregateEdges.SummaryChildrenlength',
          dm: '汇总：{childrenLength} 条'
        }, {
          childrenLength: children.length
        }))
      };
    }
    return Object.assign({}, firstEdge);
  });
  return {
    nodes: nodes,
    edges: aggregateEdges
  };
};
export default aggregate;