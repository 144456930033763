import * as React from 'react';
import $i18n from '../../i18n';

interface LogoProps {
  handleClick: () => void;
  title?: string;
  style?: React.CSSProperties;
  // width: number;
  // height: string;
  size?: number;
}

const Logo: React.FunctionComponent<LogoProps> = props => {
  const { title = '', style, size = 33, handleClick } = props;
  const width = size;
  const ratio = 29 / 33;
  const height = Math.round(size * ratio);
  const viewBox = `0 0 ${width + 3} ${height + 3}`;
  const fontSize = `${Math.round(18 * ratio)}px`;

  return (
    <div onClick={handleClick} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: '10px', ...style }}>
      <svg
        viewBox="0 0 50 50"
        width="30px"
        height="30px"
      >
        <defs>
          <clipPath id="_clipPath_wkLPftco6dkRqy8mhwcnFIHWk6i7eUdT"><rect width="50" height="50" /></clipPath>
        </defs>
        <g clip-path="url(#_clipPath_wkLPftco6dkRqy8mhwcnFIHWk6i7eUdT)">
          <path
            d=" M 7.337 7.336 C 11.865 2.808 18.115 0 25 0 C 38.77 0 50 11.23 50 25 C 50 38.769 38.77 50 25 50 C 11.231 50 0 38.769 0 25 C 0 18.115 2.808 11.865 7.337 7.336 Z "
            fill-rule="evenodd"
            fill="#557daa"
          />
          <path
            d=" M 16.901 28.263 L 17.055 27.466 L 17.235 26.692 L 17.438 25.938 L 17.665 25.209 L 17.914 24.501 L 18.188 23.81 L 18.484 23.138 L 18.805 22.483 L 19.15 21.844 L 19.52 21.219 L 19.915 20.608 L 20.341 20.005 L 20.794 19.413 L 21.277 18.83 L 21.793 18.255 L 22.342 17.689 L 22.925 17.131 L 23.544 16.58 L 24.201 16.037 L 24.897 15.501 L 24.996 15.43 L 25.62 15.903 L 26.267 16.427 L 26.883 16.964 L 27.467 17.508 L 28.013 18.057 L 28.532 18.618 L 29.023 19.191 L 29.483 19.771 L 29.915 20.363 L 30.322 20.967 L 30.704 21.587 L 31.062 22.223 L 31.395 22.873 L 31.703 23.539 L 31.989 24.227 L 32.252 24.938 L 32.49 25.67 L 32.706 26.427 L 32.899 27.213 L 33.067 28.027 L 33.209 28.864 L 33.324 29.734 L 33.336 29.857 L 32.614 30.161 L 31.834 30.46 L 31.063 30.725 L 30.305 30.957 L 29.552 31.157 L 28.805 31.325 L 28.066 31.464 L 27.335 31.572 L 26.605 31.651 L 25.873 31.701 L 25.149 31.722 L 24.424 31.714 L 23.692 31.677 L 22.958 31.61 L 22.221 31.514 L 21.476 31.387 L 20.718 31.227 L 19.953 31.035 L 19.179 30.811 L 18.396 30.55 L 17.598 30.253 L 16.782 29.916 L 16.672 29.866 L 16.769 29.089 L 16.901 28.263 Z  M 15.88 11.884 C 18.332 9.366 21.362 7.152 25 5.257 C 28.085 6.871 30.779 8.735 33.062 10.859 C 39.419 16.777 42.587 24.722 42.143 34.95 C 31.013 42.009 19.592 42.422 7.857 34.95 C 7.471 25.663 10.018 17.905 15.88 11.884 Z "
            fill-rule="evenodd"
            fill="rgb(254,254,254)"
          />
        </g>
      </svg>
      {/* <div style={{ color: `var(--text-color)`, fontSize: fontSize, fontWeight: '500', padding: '0px 4px' }}>
        {title}
      </div> */}
    </div>
  );
};

export default Logo;
