import registerLayout from './registerLayout';
import registerMeta from './registerMeta';
/**   index.md 中解析得到默认值，也可用户手动修改 */
import $i18n from '../../i18n';
var info = {
  id: 'ClusteringDagre',
  options: {
    type: 'clusteringDagre'
  },
  name: $i18n.get({
    id: 'basic.layouts.ClusteringDagre.ClusteringHierarchicalLayout',
    dm: 'Clustering hierarchical layout'
  }),
  category: 'basic',
  type: 'LAYOUT',
  desc: $i18n.get({
    id: 'basic.layouts.ClusteringDagre.ClusteringDirectedHierarchicalLayout',
    dm: 'Clustering directed hierarchical layout'
  }),
  icon: 'icon-layout-tree',
  cover: 'http://xxxx.jpg',
  docs: 'https://www.yuque.com/antv/gi/bpvfi1rtglkhz4wc'
};
export default {
  info: info,
  registerLayout: registerLayout,
  registerMeta: registerMeta
};