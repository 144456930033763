import $i18n from '../i18n';
var info = {
  id: 'NodesSimilarity',
  name: $i18n.get({
    id: 'gi-assets-algorithm.src.NodesSimilarity.info.NodeSimilarity',
    dm: 'Node similarity'
  }),
  category: 'algorithm-analysis',
  type: 'GIAC_CONTENT',
  desc: $i18n.get({
    id: 'gi-assets-algorithm.src.NodesSimilarity.info.CosineSimilarityBetweenNodesAnd',
    dm: 'Cosine similarity between nodes and selected seed nodes'
  }),
  cover: 'http://xxxx.jpg',
  icon: 'icon-one-degree',
  docs: 'https://www.yuque.com/antv/gi/kayv86k5gu6dmi7p'
};
export default info;