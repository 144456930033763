function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
export var formatProperties = function formatProperties(node) {
  var _a;
  if (node.data && ((_a = Object.keys(node.data)) === null || _a === void 0 ? void 0 : _a.length)) {
    // 如果有 data 属性，则取 data 属性和 nodeType
    var nodeType = node.nodeType,
      edgeType = node.edgeType;
    return nodeType ? Object.assign(Object.assign({}, node.data), {
      nodeType: nodeType
    }) : Object.assign(Object.assign({}, node.data), {
      edgeType: edgeType
    });
  }
  //@ts-ignore
  return node || {};
};
// lite version of lodash.get
function get(obj, path) {
  var pathArray = path.split('.');
  var result = obj;
  var _iterator = _createForOfIteratorHelper(pathArray),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var key = _step.value;
      if (result && key in result) {
        result = result[key];
      } else {
        return undefined;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return result;
}
var filterByExpression = function filterByExpression(data, expression) {
  if (!expression) return false;
  var _expression$name = expression.name,
    name = _expression$name === void 0 ? '' : _expression$name,
    operator = expression.operator,
    value = expression.value;
  var formatted = String(value);
  var propertyValue = String(get(data, name));
  switch (operator) {
    case 'eql':
      return propertyValue === formatted;
    case 'not-eql':
      return propertyValue !== formatted;
    case 'contain':
      return propertyValue.indexOf("".concat(formatted)) > -1;
    case 'not-contain':
      return propertyValue.indexOf("".concat(formatted)) === -1;
    case 'gt':
      return Number(propertyValue) > Number(value);
    case 'gte':
      return Number(propertyValue) >= Number(value);
    case 'lt':
      return Number(propertyValue) < Number(value);
    case 'lte':
      return Number(propertyValue) <= Number(value);
    default:
      return false;
  }
};
export var filterByTopRule = function filterByTopRule(item, rule) {
  var logic = rule.logic,
    expressions = rule.expressions;
  // 未配置规则一律通过
  if (!expressions || expressions.length === 0) {
    return true;
  }
  return logic === true ? expressions.every(function (exp) {
    return filterByExpression(formatProperties(item), exp);
  }) : expressions.some(function (exp) {
    return filterByExpression(formatProperties(item), exp);
  });
};
export var filterByRules = function filterByRules(items, rule) {
  return items.filter(function (item) {
    return filterByTopRule(item, rule);
  });
};