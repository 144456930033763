function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
// 转换 单个点边
export var transNodeOrEdgeByFieldMapping = function transNodeOrEdgeByFieldMapping(data) {
  var fieldMapping = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var transformedData = {};
  for (var key in data) {
    var mapping = fieldMapping[key];
    if (mapping) {
      transformedData[mapping.name] = data[key];
    } else {
      transformedData[key] = data[key];
    }
  }
  return transformedData;
};
// 转换 edges or nodes
export var transNodesOrEdgesByFieldMapping = function transNodesOrEdgesByFieldMapping(data) {
  var fieldMapping = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (Object.keys(fieldMapping).length === 0) return data;
  return data.map(function (datum) {
    var data = datum.data,
      others = __rest(datum, ["data"]);
    return Object.assign({
      data: transNodeOrEdgeByFieldMapping(data, fieldMapping)
    }, others);
  });
};
// 转换 schema
export var transSchemaByMeta = function transSchemaByMeta(schemaData) {
  var _schemaData$edges = schemaData.edges,
    edges = _schemaData$edges === void 0 ? [] : _schemaData$edges,
    _schemaData$nodes = schemaData.nodes,
    nodes = _schemaData$nodes === void 0 ? [] : _schemaData$nodes;
  var _ref = schemaData.meta || {},
    nodeFieldMapping = _ref.nodeFieldMapping,
    edgeFieldMapping = _ref.edgeFieldMapping;
  var transform = function transform(item) {
    var mapping = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var _item$properties = item.properties,
      properties = _item$properties === void 0 ? {} : _item$properties,
      rest = __rest(item, ["properties"]);
    return Object.assign(Object.assign({}, rest), {
      properties: Object.entries(mapping).reduce(function (result, _ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
          key = _ref3[0],
          value = _ref3[1];
        if (properties.hasOwnProperty(key)) {
          result[value.name] = properties[key];
          delete result[key];
        }
        return result;
      }, Object.assign({}, properties))
    });
  };
  var edgesSchema = edges.map(function (edge) {
    return transform(edge, edgeFieldMapping);
  });
  var nodesSchema = nodes.map(function (node) {
    return transform(node, nodeFieldMapping);
  });
  return {
    edges: edgesSchema,
    nodes: nodesSchema
  };
};
// 转换 graphData & schemaData
export var transDataBySchemaMeta = function transDataBySchemaMeta(data, schemaData) {
  var _ref4 = schemaData.meta || {},
    _ref4$nodeFieldMappin = _ref4.nodeFieldMapping,
    nodeFieldMapping = _ref4$nodeFieldMappin === void 0 ? {} : _ref4$nodeFieldMappin,
    _ref4$edgeFieldMappin = _ref4.edgeFieldMapping,
    edgeFieldMapping = _ref4$edgeFieldMappin === void 0 ? {} : _ref4$edgeFieldMappin;
  var nodes = data.nodes,
    edges = data.edges,
    rest = __rest(data, ["nodes", "edges"]);
  return Object.assign({
    data: {
      nodes: transNodesOrEdgesByFieldMapping(data.nodes, nodeFieldMapping),
      edges: transNodesOrEdgesByFieldMapping(data.edges, edgeFieldMapping)
    },
    schemaData: transSchemaByMeta(schemaData)
  }, rest);
};