function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var process = function process(item) {};
var getData = function getData(type, _ref) {
  var largeGraphData = _ref.largeGraphData,
    selectItems = _ref.selectItems,
    graphData = _ref.graphData;
  // 计算数据
  var items = graphData[type];
  if (selectItems[type].length !== 0) {
    items = selectItems[type];
  }
  if (largeGraphData) {
    items = largeGraphData[type];
  }
  // 节点和边的数据处理过程不太一样，边要考虑聚合边
  if (type === 'nodes') {
    return items.map(function (item) {
      return Object.assign(Object.assign({}, item.data), {
        id: item.id
      });
    });
  }
  if (type === 'edges') {
    //@ts-ignore
    return items.reduce(function (acc, curr) {
      var aggregate = curr.aggregate;
      // 表格模式下，需要将聚合边拆开展示
      if (aggregate && aggregate.length > 0) {
        var aggs = aggregate.map(function (c) {
          return Object.assign(Object.assign({}, c.data), {
            id: c.id
          });
        });
        return [].concat(_toConsumableArray(acc), _toConsumableArray(aggs));
      }
      return [].concat(_toConsumableArray(acc), [Object.assign(Object.assign({}, curr.data), {
        id: curr.id
      })]);
    }, []);
  }
};
export default getData;