"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uniqueElementsBy = exports.time = exports.mergeObjectByRule = exports.isStyles = exports.isPosition = exports.handleExpand = exports.handleCollaspe = exports.getService = exports.getProjectContext = exports.getPositionStyles = exports.getKeysByData = exports.getDefaultValues = exports.debounce = exports.createUuid = void 0;
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
var isPosition = exports.isPosition = function isPosition(nodes) {
  //若收到一个空数组，Array.prototype.every() 方法在一切情况下都会返回 true
  if (nodes.length === 0) {
    return false;
  }
  return nodes.every(function (node) {
    return !window.isNaN(node.x) && !window.isNaN(node.y);
  });
};
var isStyles = exports.isStyles = function isStyles(nodes) {
  if (nodes.length === 0) {
    return false;
  }
  return nodes.every(function (node) {
    return node.style;
  });
};
var createUuid = exports.createUuid = function createUuid() {
  return 'xxxxxxxxxxxx'.replace(/[x]/g, function (c) {
    // eslint-disable-next-line
    var r = Math.random() * 16 | 0;
    // eslint-disable-next-line
    var v = c == 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
};
var debounce = exports.debounce = function debounce(fn) {
  var ms = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var timeoutId;
  return function () {
    var _this = this;
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    clearTimeout(timeoutId);
    //@ts-ignores
    timeoutId = setTimeout(function () {
      return fn.apply(_this, args);
    }, ms);
  };
};
var getPositionStyles = exports.getPositionStyles = function getPositionStyles() {
  var placement = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'LT';
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [24, 64];
  var styles = {
    position: 'absolute',
    top: 'unset',
    left: 'unset',
    right: 'unset',
    bottom: 'unset'
  };
  var _offset = _slicedToArray(offset, 2),
    offsetX = _offset[0],
    offsetY = _offset[1];
  if (placement === 'RT') {
    styles.right = "".concat(offsetX, "px");
    styles.top = "".concat(offsetY, "px");
  }
  if (placement === 'LT') {
    styles.left = "".concat(offsetX, "px");
    styles.top = "".concat(offsetY, "px");
  }
  if (placement === 'LB') {
    styles.left = "".concat(offsetX, "px");
    styles.bottom = "".concat(offsetY, "px");
  }
  if (placement === 'RB') {
    styles.right = "".concat(offsetX, "px");
    styles.bottom = "".concat(offsetY, "px");
  }
  return styles;
};
/** 数据去重 */
var uniqueElementsBy = exports.uniqueElementsBy = function uniqueElementsBy(arr, fn) {
  return arr.reduce(function (acc, v) {
    if (!acc.some(function (x) {
      return fn(v, x);
    })) acc.push(v);
    return acc;
  }, []);
};
/**
 *
 * 时间戳转时间
 *
 */
var time = exports.time = function time(_time) {
  if (!_time) {
    return 'Invalid Date';
  }
  var date = new Date(new Date(_time).valueOf() + 8 * 3600 * 1000);
  return date.toJSON().substr(0, 16).replace('T', ' ').replace(/-/g, '.');
};
/**
 *
 * @param data 画布中的图数据
 * @param responseData 扩展出来的图数据
 * @returns
 */
var handleExpand = exports.handleExpand = function handleExpand(data, responseData) {
  var _ref = responseData || {},
    _ref$nodes = _ref.nodes,
    nodes = _ref$nodes === void 0 ? [] : _ref$nodes,
    _ref$edges = _ref.edges,
    edges = _ref$edges === void 0 ? [] : _ref$edges;
  return {
    nodes: uniqueElementsBy([].concat(_toConsumableArray(data.nodes), _toConsumableArray(nodes)), function (a, b) {
      return a.id === b.id;
    }),
    edges: uniqueElementsBy([].concat(_toConsumableArray(data.edges), _toConsumableArray(edges)), function (a, b) {
      if (a.id && b.id) {
        return a.id === b.id;
      }
      return a.source === b.source && a.target === b.target;
    })
  };
};
/**
 *
 * @param data 画布中的图数据
 * @param responseData 需要收起的图数据
 * @returns
 */
var handleCollaspe = exports.handleCollaspe = function handleCollaspe(data, responseData) {
  var nodeIds = responseData.nodes.map(function (c) {
    return c.id;
  });
  var edgeIds = responseData.edges.map(function (c) {
    return "".concat(c.source, "-").concat(c.target);
  });
  var nodes = data.nodes.filter(function (c) {
    return nodeIds.indexOf(c.id) === -1;
  });
  var edges = data.edges.filter(function (c) {
    var id = "".concat(c.source, "-").concat(c.target);
    return edgeIds.indexOf(id) === -1;
  });
  return {
    nodes: nodes,
    edges: edges
  };
};
/**
 *
 * @param services 从上下文得到的 services
 * @param serviceId 组件绑定的 serviceId
 * @returns
 */
var getService = exports.getService = function getService(services, serviceId) {
  if (!serviceId) {
    console.warn('not found serviceId', serviceId);
    return null;
  }
  var _ref2 = services.find(function (s) {
      return s.id === serviceId;
    }) || {},
    service = _ref2.service;
  if (!service) {
    console.warn('Component need a service', serviceId);
    return null;
  }
  return service;
};
/**
 *
 * @param source 原数据 格式 { type:"object",properties:{}}
 * @returns
 */
var getDefaultValues = exports.getDefaultValues = function getDefaultValues(s, componentType) {
  var ROOT = 'props';
  var result = {};
  var walk = function walk(schema, obj, k) {
    try {
      if (componentType === 'GICC_LAYOUT' && k === 'containers') {
        obj[k] = [];
        schema.forEach(function (container, i) {
          obj[k].push({
            id: container.id,
            name: container.name,
            required: container.required
          });
          Object.keys(container).forEach(function (key) {
            if (key === 'name' || key === 'id' || key === 'required') return;
            walk(container[key], obj[k][i], key);
          });
        });
        return;
      }
      var type = schema.type,
        properties = schema.properties;
      if (type === 'void') {
        Object.keys(properties).forEach(function (key) {
          walk(properties[key], obj, key);
        });
        return;
      }
      if (type === 'object') {
        obj[k] = {};
        var val = obj[k];
        Object.keys(properties).forEach(function (key) {
          walk(properties[key], val, key);
        });
        return;
      }
      obj[k] = schema.default;
    } catch (error) {
      console.log('error', schema, obj, k);
      return;
    }
  };
  walk(s, result, ROOT);
  return result[ROOT];
};
var walkProperties = function walkProperties(data) {
  var result = [];
  var walk = function walk(obj) {
    Object.keys(obj).forEach(function (key) {
      var value = obj[key];
      var isObject = Object.prototype.toString.call(value) === '[object Object]';
      if (isObject) {
        walk(value);
      } else {
        result.push(key);
      }
    });
  };
  walk(data);
  return _toConsumableArray(new Set(result));
};
var getKeysByData = exports.getKeysByData = function getKeysByData(data, category) {
  try {
    if (category === 'node') {
      var node = data.nodes[0] || {};
      var result = walkProperties(node);
      return result;
    }
    if (category === 'edge') {
      var edge = data.edges[0] || {};
      var _result = walkProperties(edge);
      return _result;
    }
  } catch (error) {}
  return [];
};
/**
 * 合并对象根据自定义规则
 * @param condition 判断条件
 * @returns
 * @example

const a = { name: 'USA', serviceId: 'AKG/USA'};
const b = {  name: 'China', serviceId: 'GI/China'};

const res = mergeObjectByRule((acc, curr) =>   curr.includes('AKG'),a,b);
// res:{ name:"China",serviceId: 'AKG/USA' }

 */
var mergeObjectByRule = exports.mergeObjectByRule = function mergeObjectByRule(condition) {
  for (var _len2 = arguments.length, objArray = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    objArray[_key2 - 1] = arguments[_key2];
  }
  // 为了默认合并对象的习惯，后者覆盖前者，所以需要将数组revert
  var objects = [].concat(objArray).reverse();
  return objects.reduce(function (acc, obj, index) {
    Object.keys(obj).forEach(function (k) {
      if (!acc.hasOwnProperty(k)) {
        //key值不存在的条件，合并进去
        acc[k] = obj[k];
      } else if (condition(acc[k], obj[k])) {
        //只有符合自定义判断条件才合并
        acc[k] = obj[k];
      }
    });
    return acc;
  }, {});
};
var getProjectContext = exports.getProjectContext = function getProjectContext() {
  var ContextString = localStorage.getItem('SERVER_ENGINE_CONTEXT') || '{}';
  var context = JSON.parse(ContextString);
  return context;
};