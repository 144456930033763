import { useShortcuts } from '@antv/gi-sdk';
import { Button, notification } from 'antd';
import * as React from 'react';

import { ShareAltOutlined } from '@ant-design/icons';
import $i18n from '../../i18n';
import getExportContext from './getExportContext';
interface ShareProjectProps {
  context: any;
}

const ShareProject: React.FunctionComponent<ShareProjectProps> = props => {
  const { context } = props;

  const handleClick = async () => {
    // const workbook = await WorkbookServices.getById(context.id);
    // if (!workbook) {
    //   return;
    // }
    // const dataset = await DatasetServices.queryDatasetInfo(workbook.datasetId);
    // const params = {
    //   dataset,
    //   workbook,
    //   GI_ASSETS_PACKAGES: JSON.parse(localStorage.getItem('GI_ASSETS_PACKAGES') || '{}'),
    // };

    const params = getExportContext(context);
    const { workbook } = params;
    const elementA = document.createElement('a');
    elementA.download = workbook.name as string;
    elementA.style.display = 'none';
    try {
      const blob = new Blob([JSON.stringify(params, null, 2)]);
      elementA.href = URL.createObjectURL(blob);
      document.body.appendChild(elementA);
      elementA.click();
      document.body.removeChild(elementA);
      notification.success({
        message: $i18n.get({
          id: 'gi-site.components.Navbar.Share.TheBackupProjectIsSuccessful',
          dm: 'The backup project is successful!',
        }),
        description: $i18n.get({
          id: 'gi-site.components.Navbar.Share.TheCurrentEnvironmentIsLocal',
          dm: 'The current environment is "local storage", and all operation data is in your browser s local IndexedDB, so you can download project files for sharing, then restore the file in the create canvas/restore Workbook',
        }),
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: $i18n.get({ id: 'gi-site.components.Navbar.Share.FailedToBackUpThe', dm: 'Failed to back up the project!' }),
        description: $i18n.get(
          {
            id: 'gi-site.components.Navbar.Share.FailureReasonError',
            dm: 'Failure Reason:{error}',
          },
          { error: error },
        ),
      });
    }
  };

  useShortcuts(['ctrl+shift+s', 'command+shift+s'], () => {
    handleClick();
  });

  return (
    <div>
      <Button size="small" onClick={handleClick} icon={<ShareAltOutlined />} type="text">
        {$i18n.get({ id: 'gi-site.components.Navbar.Share.Backup', dm: 'Backup' })}
      </Button>
    </div>
  );
};

export default ShareProject;
