import { useContext } from '@antv/gi-sdk';
import * as React from 'react';
var ActiveEdge = function ActiveEdge(props) {
  var _useContext = useContext(),
    graph = _useContext.graph;
  React.useEffect(function () {
    var handleClick = function handleClick(e) {
      var item = e.item;
      // 先清空所有点!!!NO TRANSLATE!!!Status，只给当前选中!!!NO TRANSLATE!!!及关联点Settings对应Status
      graph.getNodes().forEach(function (node) {
        graph.clearItemStates(node);
      });
      graph.getEdges().forEach(function (edge) {
        graph.clearItemStates(edge);
        var sourceNode = edge.get('sourceNode');
        var targetNode = edge.get('targetNode');
        if (edge.getID() === item.getID()) {
          graph.setItemState(edge, 'selected', true);
          graph.setItemState(sourceNode, 'active', true);
          graph.setItemState(targetNode, 'active', true);
        }
      });
    };
    graph.on('edge:click', handleClick);
    return function () {
      graph.off('edge:click', handleClick);
    };
  }, [graph]);
  return null;
};
export default ActiveEdge;