function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { extra } from '@antv/gi-sdk';
import { TIME_GRANULARITY_LIST } from './constant';
import { playbackSpeedList } from './control/animation/constants';
import info from './info';
import $i18n from '../i18n';
var deepClone = extra.deepClone,
  GIAC_CONTENT_METAS = extra.GIAC_CONTENT_METAS;
var metas = deepClone(GIAC_CONTENT_METAS);
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.title.default = info.name;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.icon.default = info.icon;
var registerMeta = function registerMeta(_ref) {
  var schemaData = _ref.schemaData;
  var getProperties = function getProperties(type, dataType) {
    return Object.entries(schemaData[type].reduce(function (acc, cur) {
      return Object.assign(Object.assign({}, acc), cur.properties);
    }, {})).filter(function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
        v = _ref3[1];
      return dataType ? [dataType].includes(v) : true;
    }).map(function (_ref4) {
      var _ref5 = _slicedToArray(_ref4, 1),
        k = _ref5[0];
      return {
        label: k,
        value: "".concat(type, ":").concat(k)
      };
    });
  };
  var schema = Object.assign({
    timeField: {
      title: $i18n.get({
        id: 'scene.src.Timebar.registerMeta.TimeField',
        dm: '时间字段'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: [{
        label: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.Node',
          dm: '节点'
        }),
        options: getProperties('nodes')
      }, {
        label: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.Edge',
          dm: '边'
        }),
        options: getProperties('edges')
      }]
    },
    yField: {
      title: $i18n.get({
        id: 'scene.src.Timebar.registerMeta.IndicatorField',
        dm: '指标字段'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: [{
        label: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.Node',
          dm: '节点'
        }),
        options: getProperties('nodes')
      }, {
        label: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.Edge',
          dm: '边'
        }),
        options: getProperties('edges')
      }],
      'x-decorator-props': {
        tooltip: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.OnlyAggregateCountsAreSupported',
          dm: '非数值类型仅支持聚合计数\n确保和时间字段同属于节点或边'
        })
      }
    },
    aggregation: {
      title: $i18n.get({
        id: 'scene.src.Timebar.registerMeta.Aggregation',
        dm: '聚合'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: [{
        label: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.Count',
          dm: '计数'
        }),
        value: 'count'
      }, {
        label: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.Maximum',
          dm: '最大值'
        }),
        value: 'max'
      }, {
        label: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.Mean',
          dm: '均值'
        }),
        value: 'mean'
      }, {
        label: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.MinimumValue',
          dm: '最小值'
        }),
        value: 'min'
      }, {
        label: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.Median',
          dm: '中位数'
        }),
        value: 'median'
      }, {
        label: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.Summation',
          dm: '求和'
        }),
        value: 'sum'
      }],
      default: 'count'
    },
    // datepicker 有问题
    // timeRange: {
    //   title: '时间范围',
    //   type: 'string',
    //   'x-decorator': 'FormItem',
    //   'x-component': 'DatePicker.RangePicker',
    //   'x-component-props': {
    //     showTime: true,
    //   },
    // },
    defaultTimeLength: {
      title: $i18n.get({
        id: 'scene.src.Timebar.registerMeta.DefaultTimeLength',
        dm: '默认时间长度'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        options: [{
          value: 'all',
          label: $i18n.get({
            id: 'scene.src.Timebar.registerMeta.All',
            dm: '全部'
          })
        }, {
          value: 'month',
          label: $i18n.get({
            id: 'scene.src.Timebar.registerMeta.OneMonth',
            dm: '一个月'
          })
        }, {
          value: 'year',
          label: $i18n.get({
            id: 'scene.src.Timebar.registerMeta.OneYear',
            dm: '一年'
          })
        }]
      },
      default: 'all'
    },
    timeGranularity: {
      title: $i18n.get({
        id: 'scene.src.Timebar.registerMeta.TimeGranularity',
        dm: '时间粒度'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: TIME_GRANULARITY_LIST,
      'x-linkages': [{
        type: 'value:schema',
        target: 'timeRange',
        condition: "{{ $self === 'year' }}",
        schema: {
          'x-component-props': {
            picker: 'year'
          }
        }
      }, {
        type: 'value:schema',
        target: 'timeRange',
        condition: "{{ $self === 'quarter' }}",
        schema: {
          'x-component-props': {
            picker: 'quarter'
          }
        }
      }, {
        type: 'value:schema',
        target: 'timeRange',
        condition: "{{ $self === 'month' }}",
        schema: {
          'x-component-props': {
            picker: 'month'
          }
        }
      }, {
        type: 'value:schema',
        target: 'timeRange',
        condition: "{{ $self === 'week' }}",
        schema: {
          'x-component-props': {
            picker: 'week'
          }
        }
      }],
      default: 'day'
    },
    speed: {
      title: $i18n.get({
        id: 'scene.src.Timebar.registerMeta.PlaybackSpeed',
        dm: '播放速度'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: playbackSpeedList,
      default: 1
    },
    playMode: {
      title: $i18n.get({
        id: 'scene.src.Timebar.registerMeta.PlaybackMode',
        dm: '播放模式'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: [{
        label: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.Filtering',
          dm: '过滤'
        }),
        value: 'filter'
      }, {
        label: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.Highlight',
          dm: '高亮'
        }),
        value: 'highlight'
      }, {
        label: $i18n.get({
          id: 'scene.src.Timebar.registerMeta.ShowHide',
          dm: '显示/隐藏'
        }),
        value: 'show-hide'
      }],
      default: 'filter'
    }
  }, metas);
  return schema;
};
export default registerMeta;