import $i18n from '../../i18n';
var registerMeta = function registerMeta(context) {
  return {
    unitRadius: {
      type: 'number',
      title: $i18n.get({
        id: 'basic.layouts.Radial.registerMeta.LevelDistance',
        dm: 'Level Distance'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {
        step: 1
      },
      default: 200
    },
    linkDistance: {
      type: 'number',
      title: $i18n.get({
        id: 'basic.layouts.Radial.registerMeta.SideLength',
        dm: 'Side length'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      default: 200
    },
    nodeSize: {
      type: 'number',
      title: $i18n.get({
        id: 'basic.layouts.Radial.registerMeta.NodeSize',
        dm: 'Node size'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      default: 60
    },
    focusNode: {
      type: 'string',
      title: $i18n.get({
        id: 'basic.layouts.Radial.registerMeta.CentralNode',
        dm: 'Central Node'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'Select'
    },
    nodeSpacing: {
      type: 'number',
      title: $i18n.get({
        id: 'basic.layouts.Radial.registerMeta.NodeSpacing',
        dm: 'Node spacing'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {},
      default: 30
    },
    preventOverlap: {
      type: 'boolean',
      title: $i18n.get({
        id: 'basic.layouts.Radial.registerMeta.PreventOverlap',
        dm: 'Prevent overlap'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: true
    },
    strictRadial: {
      type: 'boolean',
      title: $i18n.get({
        id: 'basic.layouts.Radial.registerMeta.StrictRadiation',
        dm: 'Strict radiation'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: true
    }
  };
};
export default registerMeta;