import ServerComponent from './ServerComponent';
import $i18n from '../../i18n';

export default {
  id: 'GI',
  type: 'file',
  name: 'GraphJSON',
  desc: $i18n.get({
    id: 'gi-site.components.FileServerEngine.TheLocalFileImportService',
    dm: 'The local file import service provided by G6VP can store data in browser IndexDB or cloud servers depending on the deployment environment.',
  }),
  component: ServerComponent,
  cover: 'https://gw.alipayobjects.com/mdn/rms_0d75e8/afts/img/A*3YEZS6qSRgAAAAAAAAAAAAAAARQnAQ',
};
