import { PLACEMENT_OPTIONS } from '../const';
import $i18n from '../../i18n';
var registerMeta = function registerMeta(context) {
  var _a;
  var data = context.data,
    schemaData = context.schemaData;
  var keys = ['id'];
  try {
    keys = Object.keys(((_a = data.nodes[0]) === null || _a === void 0 ? void 0 : _a.data) || {});
  } catch (error) {
    console.warn(error);
  }
  var options = keys.map(function (c) {
    return {
      value: c,
      label: c
    };
  });
  var schema = {
    sortKey: {
      title: $i18n.get({
        id: 'basic.components.NodeLegend.registerMeta.MappingField',
        dm: 'Mapping field'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      default: 'type',
      enum: options
    },
    textColor: {
      title: $i18n.get({
        id: 'basic.components.NodeLegend.registerMeta.FontColor',
        dm: 'Font color'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'ColorInput',
      default: '#ddd'
    },
    placement: {
      title: $i18n.get({
        id: 'basic.components.NodeLegend.registerMeta.ComponentLocation',
        dm: 'Component location'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: PLACEMENT_OPTIONS,
      default: 'LB'
    },
    offset: {
      title: $i18n.get({
        id: 'basic.components.NodeLegend.registerMeta.Offset',
        dm: 'Offset'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Offset',
      'x-component-props': {
        min: 0,
        max: 400
      },
      default: [100, 20]
    }
  };
  return schema;
};
export default registerMeta;