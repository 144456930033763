import registerLayout from './registerLayout';
import registerMeta from './registerMeta';
/**   index.md 中解析得到默认值，也可用户手动修改 */
import $i18n from '../../i18n';
var info = {
  id: 'Force2',
  options: {
    type: 'force2',
    animate: false,
    preset: {
      type: 'concentric',
      width: 800,
      height: 800,
      minNodeSpacing: 10,
      nodeSize: 10
    },
    clusterNodeStrength: 35,
    minMovement: 10,
    damping: 0.8,
    maxSpeed: 1000,
    distanceThresholdMode: 'max'
  },
  name: $i18n.get({
    id: 'basic.layouts.Force2.FastForceGuide',
    dm: 'Fast Force'
  }),
  category: 'basic',
  type: 'LAYOUT',
  desc: $i18n.get({
    id: 'basic.layouts.Force2.ProgressiveForceDirectedLayoutFor',
    dm: 'Progressive Force-directed layout for dynamic layout'
  }),
  icon: 'icon-layout-force',
  cover: 'http://xxxx.jpg',
  docs: 'https://www.yuque.com/antv/gi/rloo8d2l7qbsuyg8'
};
export default {
  info: info,
  registerLayout: registerLayout,
  registerMeta: registerMeta
};