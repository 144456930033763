import Component from './Component';
import registerMeta from './registerMeta';
/**   index.md 中解析得到默认值，也可用户手动修改 */
import $i18n from '../../i18n';
var info = {
  id: 'CanvasSetting',
  name: $i18n.get({
    id: 'basic.components.CanvasSetting.CanvasSettings',
    dm: 'Canvas settings'
  }),
  desc: $i18n.get({
    id: 'basic.components.CanvasSetting.SetTheBackgroundOfThe',
    dm: 'Set the background of the canvas, zoom, move, and other interactions'
  }),
  icon: 'icon-canvas-setting',
  cover: 'http://xxxx.jpg',
  category: 'canvas-interaction',
  type: 'AUTO',
  docs: 'https://www.yuque.com/antv/gi/aa34nmszaivikxi1',
  features: [$i18n.get({
    id: 'basic.components.CanvasSetting.SelectCanvasNodeOrEdge',
    dm: 'Select Canvas node or edge'
  }), $i18n.get({
    id: 'basic.components.CanvasSetting.SupportsAutomaticAdaptiveCenteringBy',
    dm: 'Supports automatic adaptive centering by double-clicking the canvas.'
  }), $i18n.get({
    id: 'basic.components.CanvasSetting.YouCanConfigureTheBackground',
    dm: 'You can configure the background color of the canvas.'
  }), $i18n.get({
    id: 'basic.components.CanvasSetting.SupportsScalingAndTranslationInteraction',
    dm: 'Supports scaling and translation interaction of the canvas.'
  })]
};
export default {
  info: info,
  component: Component,
  registerMeta: registerMeta
};