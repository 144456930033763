import $i18n from '../../i18n';
var registerMeta = function registerMeta(context) {
  try {
    var keys = context.keys;
    var numberKeys = keys.filter(function (key) {
      return key.type === 'number';
    }).map(function (c) {
      return c.id;
    });
    var numberOptions = numberKeys.map(function (c) {
      return {
        label: c,
        value: c
      };
    });
    return {
      type: 'object',
      properties: {
        donut: {
          title: $i18n.get({
            id: 'basic.elements.DonutNode.registerMeta.RingDisplay',
            dm: 'Ring display'
          }),
          type: 'array',
          //todo: 显示TextProperties根据 data 生成
          enum: numberOptions,
          default: [],
          'x-decorator': 'FormItem',
          'x-component': 'Select',
          'x-component-props': {
            mode: 'multiple'
          }
        },
        donutColors: {
          title: $i18n.get({
            id: 'basic.elements.DonutNode.registerMeta.RingColor',
            dm: 'Ring color'
          }),
          type: 'string',
          'x-decorator': 'FormItem',
          'x-component': 'Input',
          default: ''
        },
        size: {
          title: $i18n.get({
            id: 'basic.elements.DonutNode.registerMeta.RingSizeSmall',
            dm: 'Ring size small'
          }),
          type: 'string',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: undefined
        },
        label: {
          title: $i18n.get({
            id: 'basic.elements.DonutNode.registerMeta.Text',
            dm: 'Text'
          }),
          type: 'array',
          enum: keys.map(function (c) {
            return {
              label: "".concat(c.id, " (").concat(c.type, ")"),
              value: c.id
            };
          }),
          default: [],
          'x-decorator': 'FormItem',
          'x-component': 'Select',
          'x-component-props': {
            mode: 'multiple'
          }
        }
      }
    };
  } catch (error) {
    return {};
  }
};
export default registerMeta;