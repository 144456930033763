// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/app/node_modules/.pnpm/@umijs+runtime@3.5.41_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "exact": true,
    "path": "/",
    "redirect": "/home"
  },
  {
    "exact": true,
    "path": "/workspace/:projectId",
    "component": require('/app/packages/gi-site/src/pages/Analysis').default
  },
  {
    "exact": true,
    "path": "/workspacepreview/:projectId",
    "component": require('/app/packages/gi-site/src/pages/AnalysisPreview').default
  },
  {
    "exact": true,
    "path": "/share/:shareId",
    "component": require('/app/packages/gi-site/src/pages/Share').default
  },
  {
    "exact": true,
    "path": "/tabs/:type",
    "component": require('/app/packages/gi-site/src/pages/Tab').default
  },
  {
    "exact": true,
    "path": "/app/:id",
    "component": require('/app/packages/gi-site/src/pages/Applaction').default
  },
  {
    "exact": true,
    "path": "/preview/:id",
    "component": require('/app/packages/gi-site/src/pages/Preview').default
  },
  {
    "path": "/",
    "component": require('@/layouts/index').default,
    "routes": [
      {
        "exact": true,
        "path": "/workspace",
        "component": require('/app/packages/gi-site/src/pages/Workspace').default
      },
      {
        "exact": true,
        "path": "/services",
        "component": require('/app/packages/gi-site/src/pages/ServerCenter').default
      },
      {
        "exact": true,
        "path": "/home",
        "component": require('/app/packages/gi-site/src/pages/Home').default
      },
      {
        "path": "/dataset",
        "component": require('@/layouts/SideNav').default,
        "routes": [
          {
            "exact": true,
            "path": "/dataset/list",
            "component": require('/app/packages/gi-site/src/pages/Dataset/List').default
          },
          {
            "exact": true,
            "path": "/dataset/list/:id",
            "component": require('/app/packages/gi-site/src/pages/Dataset/Detail').default
          },
          {
            "exact": true,
            "path": "/dataset/create",
            "component": require('/app/packages/gi-site/src/pages/Dataset/Create').default
          },
          {
            "exact": true,
            "path": "/dataset/case",
            "component": require('/app/packages/gi-site/src/pages/Dataset/Case').default
          },
          {
            "exact": true,
            "path": "/dataset/SYSTEM_DIRECT_CONNECT",
            "component": require('/app/packages/gi-site/src/pages/Dataset/SystemDirectConnect').default
          },
          {
            "exact": true,
            "path": "/dataset/delete",
            "component": require('/app/packages/gi-site/src/pages/Dataset/Delete').default
          }
        ]
      },
      {
        "path": "/workbook",
        "component": require('@/layouts/SideNav').default,
        "routes": [
          {
            "exact": true,
            "path": "/workbook/project",
            "component": require('/app/packages/gi-site/src/pages/Workspace/Projects').default
          },
          {
            "exact": true,
            "path": "/workbook/create",
            "component": require('/app/packages/gi-site/src/pages/Workbook/Create').default
          },
          {
            "exact": true,
            "path": "/workbook/template",
            "component": require('/app/packages/gi-site/src/pages/Template/index').default
          },
          {
            "exact": true,
            "path": "/workbook/template/:id",
            "component": require('/app/packages/gi-site/src/pages/Template/Detail').default
          },
          {
            "exact": true,
            "path": "/workbook/report",
            "component": require('/app/packages/gi-site/src/pages/Share').default
          }
        ]
      },
      {
        "path": "/open",
        "component": require('@/layouts/SideNav').default,
        "routes": [
          {
            "exact": true,
            "path": "/open/solution",
            "component": require('/app/packages/gi-site/src/pages/Solution').default
          },
          {
            "exact": true,
            "path": "/open/assets-manage",
            "component": require('/app/packages/gi-site/src/pages/Assets').default
          },
          {
            "exact": true,
            "path": "/open/assets-list",
            "component": require('/app/packages/gi-site/src/pages/AssetsList').default
          },
          {
            "exact": true,
            "path": "/open/engines",
            "component": require('/app/packages/gi-site/src/pages/ServerCenter').default
          },
          {
            "exact": true,
            "path": "/open/g6v5demo",
            "component": require('/app/packages/gi-site/src/pages/G6V5Demo').default
          },
          {
            "exact": true,
            "path": "/open/user",
            "component": require('/app/packages/gi-site/src/pages/Share').default
          }
        ]
      },
      {
        "component": require('/app/packages/gi-site/src/pages/404').default,
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
