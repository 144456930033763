import { S2Event } from '@antv/s2';
import React from 'react';
import { useContext } from '@antv/gi-sdk';
var useListenNodeSelect = function useListenNodeSelect(isSelectedActive, s2Instance, isFullScreen) {
  var _useContext = useContext(),
    graphData = _useContext.data,
    graph = _useContext.graph,
    largeGraphData = _useContext.largeGraphData,
    updateContext = _useContext.updateContext;
  React.useEffect(function () {
    s2Instance === null || s2Instance === void 0 ? void 0 : s2Instance.on(S2Event.GLOBAL_SELECTED, function () {
      // isSelectedActiv For false Or全屏时，不Highlight选中Element
      if (!isSelectedActive || isFullScreen) {
        return;
      }
      var cells = s2Instance.interaction.getCells();
      // if (cells.length === 0) {
      //   graphData.nodes.forEach(node => {
      //     graph.clearItemStates(node.id);
      //   });
      //   graphData.edges.forEach(edge => {
      //     graph.clearItemStates(edge.id);
      //   });
      //   return;
      // }
      var selectedNodes = new Set();
      cells.forEach(function (cell) {
        var _a;
        var rowIndex = cell.rowIndex;
        // @ts-ignore
        var rowData = s2Instance.dataSet.getMultiData();
        if (!rowData) return;
        var nodeID = (_a = rowData[rowIndex]) === null || _a === void 0 ? void 0 : _a.id;
        // @ts-ignore
        selectedNodes.add(nodeID);
      });
      if (largeGraphData) {
        var nodes = largeGraphData.nodes.filter(function (n) {
          return selectedNodes.has(n.id);
        });
        var edges = largeGraphData.edges.filter(function (e) {
          return selectedNodes.has(e.target) && selectedNodes.has(e.source);
        });
        var newData = {
          nodes: nodes,
          edges: edges
        };
        updateContext(function (draft) {
          draft.data = newData;
          draft.source = newData;
        });
      } else {
        graphData.nodes.forEach(function (nodeConfig) {
          var id = nodeConfig.id;
          var item = graph.findById(id);
          if (selectedNodes.has(id)) {
            item.hasState('disabled') && graph.setItemState(id, 'disabled', false);
            !item.hasState('selected') && graph.setItemState(id, 'selected', true);
          } else {
            !item.hasState('disabled') && graph.setItemState(id, 'disabled', true);
            item.hasState('selected') && graph.setItemState(id, 'selected', false);
          }
        });
        graphData.edges.forEach(function (edgeConfig) {
          var id = edgeConfig.id,
            source = edgeConfig.source,
            target = edgeConfig.target;
          graph.setItemState(id, 'disabled', true);
          var item = graph.findById(id);
          if (selectedNodes.has(target) && selectedNodes.has(source)) {
            !item.hasState('selected') && graph.setItemState(id, 'selected', true);
            item.hasState('disabled') && graph.setItemState(id, 'disabled', false);
          } else {
            !item.hasState('disabled') && graph.setItemState(id, 'disabled', true);
            item.hasState('selected') && graph.setItemState(id, 'selected', false);
          }
        });
      }
    });
    return function () {
      s2Instance === null || s2Instance === void 0 ? void 0 : s2Instance.off(S2Event.GLOBAL_SELECTED);
    };
  }, [isSelectedActive, largeGraphData, graphData, s2Instance, isFullScreen]);
};
export default useListenNodeSelect;