function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { template } from '@antv/gi-sdk';
import $i18n from '../i18n';
var pageLayout = {
  id: 'SegmentedLayout',
  name: $i18n.get({
    id: 'basic.src.templates.Simple.SegmentLayout',
    dm: 'Segment layout'
  }),
  props: {
    containers: [{
      id: 'GI_CONTAINER_SIDE',
      name: $i18n.get({
        id: 'basic.src.templates.Simple.SideContainer',
        dm: 'Side Container'
      }),
      required: true,
      GI_CONTAINER: ['FilterPanel'],
      display: true
    }]
  }
};
var components = [].concat(_toConsumableArray(template.components), [pageLayout]);
export var config = {
  nodes: template.nodes,
  edges: template.edges,
  layout: template.layout,
  components: components,
  pageLayout: pageLayout
};
export var activeAssetsKeys = {
  elements: [].concat(_toConsumableArray(config.nodes.map(function (n) {
    return n.id;
  })), _toConsumableArray(config.edges.map(function (e) {
    return e.id;
  }))),
  components: _toConsumableArray(components.map(function (c) {
    return c.id;
  })),
  layouts: ['Force2', 'Concentric', 'Dagre', 'FundForce', 'GraphinForce']
};
export default Object.assign({
  name: $i18n.get({
    id: 'basic.src.templates.Simple.MinimalistTemplate',
    dm: 'Minimalist template'
  }),
  id: 'TP_SIMPLE',
  desc: $i18n.get({
    id: 'basic.src.templates.Simple.ThisTemplateIsAnOfficial',
    dm: 'This template is an official minimalist template that contains {activeAssetsKeysComponentsLength} analysis assets and provides common interactive analysis, filter views, and other functions. On the page layout, canvas Display space is large, providing immersive analysis experience'
  }, {
    activeAssetsKeysComponentsLength: activeAssetsKeys.components.length
  }),
  image: "/image/tp_simple.png",
  activeAssetsKeys: activeAssetsKeys
}, config);