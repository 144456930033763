import { useContext } from '@antv/gi-sdk';
import React from 'react';
// 生成Point Table的Table Mode
var useNodeDataCfg = function useNodeDataCfg() {
  var _useContext = useContext(),
    schemaData = _useContext.schemaData,
    graphData = _useContext.source,
    largeGraphData = _useContext.largeGraphData;
  var nodeDataCfg = React.useMemo(function () {
    var nodeProperties = schemaData.nodes.reduce(function (acc, cur) {
      return Object.assign(Object.assign({}, acc), cur.properties);
    }, {});
    var columns = [];
    for (var key in nodeProperties) {
      if (typeof nodeProperties[key] === 'number' || typeof nodeProperties[key] === 'string') {
        columns.push(key);
      }
    }
    /*
      在大图Mode下，表格的Table Mode源For largeGraphData
      此时刷选和点选表格时会FilteringCanvasTable Mode
    */
    var data;
    if (largeGraphData) {
      data = largeGraphData.nodes.map(function (node) {
        return Object.assign(Object.assign({}, node.data), {
          id: node.id
        });
      });
    } else {
      data = graphData.nodes.map(function (node) {
        return Object.assign(Object.assign({}, node.data), {
          id: node.id
        });
      });
    }
    return {
      fields: {
        columns: columns
      },
      data: data
    };
  }, [schemaData, graphData, largeGraphData]);
  return nodeDataCfg;
};
export default useNodeDataCfg;