function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { template } from '@antv/gi-sdk';
// Page layoutContainer
import $i18n from '../i18n';
var pageLayout = {
  id: 'UadLayout',
  type: 'GICC_LAYOUT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.UpperAndLowerLayout',
    dm: 'Upper and Lower layout'
  }),
  props: {
    containers: [{
      id: 'GI_CONTAINER_TOP',
      GI_CONTAINER: ['GremlinQuery'],
      height: 294,
      padding: '0px 0px'
    }, {
      id: 'GI_CONTAINER_SIDE',
      GI_CONTAINER: ['JSONMode'],
      tabPosition: 'right'
    }]
  }
};
var addComponents = [{
  id: 'GremlinQuery',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.GremlinQuery',
    dm: 'Gremlin query'
  }),
  props: {
    serviceId: 'GraphScope/GremlinQuery',
    isShowPublishButton: false,
    isShowLimit: true,
    isShowTimeout: true,
    saveTemplateServceId: 'GI/PublishTemplate',
    initialValue: 'g.V().limit(10)',
    height: 166,
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: true,
      title: 'Gremlin',
      isShowIcon: true,
      icon: 'icon-query',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '350px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'TableMode',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.TableMode',
    dm: 'Table mode'
  }),
  props: {
    enableCopy: true,
    isSelectedActive: true,
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: true,
      title: $i18n.get({
        id: 'basic.src.templates.Query.TableMode',
        dm: 'Table mode'
      }),
      isShowIcon: true,
      icon: 'icon-table',
      isShowTooltip: true,
      tooltip: $i18n.get({
        id: 'basic.src.templates.Query.ShowNodesAndEdgesIn',
        dm: 'Show nodes and edges in the canvas as tables'
      }),
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '400px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'JSONMode',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.CodeMode',
    dm: 'Code mode'
  }),
  props: {
    theme: 'rjv-default',
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: true,
      title: $i18n.get({
        id: 'basic.src.templates.Query.CodeMode',
        dm: 'Code mode'
      }),
      isShowIcon: true,
      icon: 'icon-table',
      isShowTooltip: true,
      tooltip: $i18n.get({
        id: 'basic.src.templates.Query.ShowDataInCode',
        dm: 'Show data in code'
      }),
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '400px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'SideTabs',
  type: 'GICC',
  name: $i18n.get({
    id: 'basic.src.templates.Query.Sidebar',
    dm: 'Sidebar'
  }),
  props: {
    GI_CONTAINER: ['FilterPanel', 'NodeImportance', 'CommunityDetection', 'PatternMatch'],
    outSideFromCanvas: true,
    tabPosition: 'left',
    defaultVisible: true,
    placement: 'LB',
    offset: [0, 0],
    height: '400px',
    width: '400px'
  }
}, {
  id: 'StructAnalysis',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.PathStructureAnalysis',
    dm: 'Path structure analysis'
  }),
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: true,
      title: $i18n.get({
        id: 'basic.src.templates.Query.PathStructureAnalysis',
        dm: 'Path structure analysis'
      }),
      isShowIcon: true,
      icon: 'icon-layout-tree',
      isShowTooltip: true,
      tooltip: $i18n.get({
        id: 'basic.src.templates.Query.AutomaticallyParseAllPathsOf',
        dm: 'Automatically parse all paths of the canvas for aggregation analysis.'
      }),
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '400px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'InfoDetection',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.InformationDetection',
    dm: 'Information Detection'
  }),
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'basic.src.templates.Query.InformationDetection',
        dm: 'Information Detection'
      }),
      isShowIcon: true,
      icon: 'icon-infomation',
      isShowTooltip: true,
      tooltip: $i18n.get({
        id: 'basic.src.templates.Query.DetectIsolatedPointsRingsEtc',
        dm: 'Detect isolated points, rings, etc. in the canvas'
      }),
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '400px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'NodeImportance',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.NodeImportance',
    dm: 'Node importance'
  }),
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'basic.src.templates.Query.NodeImportance',
        dm: 'Node importance'
      }),
      isShowIcon: true,
      icon: 'icon-rules',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '350px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'CommunityDetection',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.CommunityDiscovery',
    dm: 'Community discovery'
  }),
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'basic.src.templates.Query.CommunityDiscovery',
        dm: 'Community discovery'
      }),
      isShowIcon: true,
      icon: 'icon-associate',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '350px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'PatternMatch',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.PatternMatching',
    dm: 'Pattern matching'
  }),
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'basic.src.templates.Query.PatternMatching',
        dm: 'Pattern matching'
      }),
      isShowIcon: true,
      icon: 'icon-query-path',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '350px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'CypherQuery',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.CypherStatementQuery',
    dm: 'Cypher statement query'
  }),
  props: {
    serviceId: 'TuGraph/CypherQuery',
    isShowPublishButton: false,
    saveCypherTemplateServceId: 'GI/PublishTemplate',
    initialValue: 'MATCH n RETURN LIMIT 100',
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: true,
      title: $i18n.get({
        id: 'basic.src.templates.Query.CypherStatementQuery',
        dm: 'Cypher statement query'
      }),
      isShowIcon: true,
      icon: 'icon-query',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '350px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}];
var nodes = template.nodes,
  edges = template.edges,
  layout = template.layout,
  components = template.components;
export var config = {
  nodes: nodes,
  edges: edges,
  layout: layout,
  components: [].concat(_toConsumableArray(components), addComponents, [pageLayout])
};
export var activeAssetsKeys = {
  elements: [].concat(_toConsumableArray(config.nodes.map(function (n) {
    return n.id;
  })), _toConsumableArray(config.edges.map(function (e) {
    return e.id;
  }))),
  components: _toConsumableArray(components.map(function (c) {
    return c.id;
  })),
  layouts: ['Force2', 'Concentric', 'Dagre', 'FundForce', 'GraphinForce']
};
export default Object.assign({
  name: $i18n.get({
    id: 'basic.src.templates.Query.QueryTemplate',
    dm: 'Query Template'
  }),
  id: 'TP_QUERY',
  image: "/image/tp_query.png",
  desc: $i18n.get({
    id: 'basic.src.templates.Query.TheTemplateWithTheQuery',
    dm: 'The template with the query statement as the main body contains {activeAssetsKeysComponentsLength} ANALYSIS Assets. The page layout is distributed up and down. The top is integrated with "Gremlin" or "Cypher" query statements, it is commonly used in database query scenarios.'
  }, {
    activeAssetsKeysComponentsLength: activeAssetsKeys.components.length
  }),
  activeAssetsKeys: activeAssetsKeys
}, config);