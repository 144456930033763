import {
  AppstoreOutlined,
  BookOutlined,
  DeleteOutlined,
  DeploymentUnitOutlined,
  GlobalOutlined,
  MenuUnfoldOutlined,
  OrderedListOutlined,
  PlusOutlined,
  RocketOutlined,
} from '@ant-design/icons';

import { Menu, MenuProps } from 'antd';
import * as React from 'react';
import $i18n from '../i18n';
import './index.less';
interface ILayoutProps {}

const DATASET_ITEMS = [
  {
    label: $i18n.get({ id: 'gi-site.src.layouts.SideNav.MyData', dm: 'My Dataset' }),
    key: '/dataset/list',
    icon: <MenuUnfoldOutlined />,
  },
  {
    label: $i18n.get({ id: 'gi-site.src.layouts.SideNav.CreateData', dm: 'Create dataset' }),
    key: '/dataset/create',
    icon: <PlusOutlined />,
  },
  {
    label: $i18n.get({ id: 'gi-site.src.layouts.SideNav.CaseData', dm: 'Case dataset' }),
    key: '/dataset/case',
    icon: <AppstoreOutlined />,
  },
  {
    label: $i18n.get({ id: 'gi-site.src.layouts.SideNav.DirectSystemConnection', dm: 'System connection' }),
    key: '/dataset/SYSTEM_DIRECT_CONNECT',
    icon: <GlobalOutlined />,
  },
  {
    label: $i18n.get({ id: 'gi-site.src.layouts.SideNav.RecycleBin', dm: 'Recycle bin' }),
    key: '/dataset/delete',
    icon: <DeleteOutlined />,
  },
];

const OPEN_ITEMS = [
  {
    label: $i18n.get({ id: 'gi-site.src.layouts.SideNav.AssetManagement', dm: 'Asset pkgs' }),
    key: '/open/assets-manage',
    icon: <AppstoreOutlined />,
  },
  {
    label: $i18n.get({ id: 'gi-site.src.layouts.SideNav.AssetList', dm: 'Asset intro' }),
    key: '/open/assets-list',
    icon: <OrderedListOutlined />,
  },
  {
    label: $i18n.get({ id: 'gi-site.src.layouts.SideNav.EngineManagement', dm: 'Engine pkgs' }),
    key: '/open/engines',
    icon: <RocketOutlined />,
  },
  {
    label: $i18n.get({ id: 'gi-site.src.layouts.SideNav.Solution', dm: 'Solution' }),
    key: '/open/solution',
    icon: <BookOutlined />,
  },
  { label: 'V5 DEMO', key: '/open/g6v5demo', icon: <DeploymentUnitOutlined /> },
  // { label: '用户管理', key: '/open/user', icon: <AppstoreOutlined /> },
  // { label: '图应用', key: '/open/portal', icon: <AppstoreOutlined /> },
  // { label: '实验室', key: '/open/lab', icon: <AppstoreOutlined /> },
  // { label: 'Version Notification', key: '/open/version', icon: <AppstoreOutlined /> },
  // { label: 'Solution', key: '/open/solution', icon: <AppstoreOutlined /> },
];

const WORKBOOK_ITEMS = [
  {
    label: $i18n.get({ id: 'gi-site.src.layouts.SideNav.MyCanvas', dm: 'My Workbook' }),
    key: '/workbook/project',
    icon: <GlobalOutlined />,
  },
  {
    label: $i18n.get({ id: 'gi-site.src.layouts.SideNav.CreateACanvas', dm: 'Create workbook' }),
    key: '/workbook/create',
    icon: <PlusOutlined />,
  },
  {
    label: $i18n.get({ id: 'gi-site.src.layouts.SideNav.MyTemplate', dm: 'My template' }),
    key: '/workbook/template',
    icon: <AppstoreOutlined />,
  },
  // { label: '我的报表', key: '/workbook/report', icon: <MenuUnfoldOutlined /> },
  // { label: 'Industry cases', key: '/workbook/case', icon: <AppstoreOutlined /> },
];

const getItems = location => {
  try {
    const module = location.pathname.split('/')[1];
    if (module === 'dataset') {
      return {
        name: $i18n.get({ id: 'gi-site.src.layouts.SideNav.Dataset', dm: 'Dataset' }),
        items: DATASET_ITEMS,
      };
    }
    // if (module === 'open') {
    //   return {
    //     name: $i18n.get({ id: 'gi-site.src.layouts.SideNav.OpenMarket', dm: 'Open market' }),
    //     items: OPEN_ITEMS,
    //   };
    // }
    if (module === 'workbook') {
      return {
        name: $i18n.get({ id: 'gi-site.src.layouts.SideNav.Workbook', dm: 'Workbook' }),
        items: WORKBOOK_ITEMS,
      };
    }
    return { items: [], name: $i18n.get({ id: 'gi-site.src.layouts.SideNav.UnknownPage', dm: 'Unknown page' }) };
  } catch (error) {
    return { items: [], name: $i18n.get({ id: 'gi-site.src.layouts.SideNav.UnknownPage', dm: 'Unknown page' }) };
  }
};

const SideNav: React.FunctionComponent<ILayoutProps> = props => {
  //@ts-ignore
  const { children, location, history } = props;
  const [state, setState] = React.useState({
    active: location.pathname.split('/').splice(0, 3).join('/'),
  });

  const onClick: MenuProps['onClick'] = e => {
    setState({ active: e.key });
    history.push(e.key);
  };

  const { items, name } = getItems(location);

  const { active } = state;

  return (
    <div style={{ display: 'flex', height: '-webkit-fill-available' }}>
      <div style={{ width: '160px' }}>
        {/* <h2>{name}</h2> */}
        <Menu className="gi-layout__side" items={items} onClick={onClick} selectedKeys={[active]} />
      </div>
      <div
        style={{
          flex: 1,
          // background: 'var(--background-color)',
          padding: '0px 12px',
          width: 'calc(100% - 160px)',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default SideNav;
