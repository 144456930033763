import Component from './Component';
import registerMeta from './registerMeta';
/**   index.md 中解析得到默认值，也可用户手动修改 */
import $i18n from '../../i18n';
var info = {
  id: 'OperatorBar',
  name: $i18n.get({
    id: 'basic.components.OperatorBar.ActionBar',
    dm: 'Action Bar'
  }),
  desc: $i18n.get({
    id: 'basic.components.OperatorBar.BusinessActionBarWhichCan',
    dm: 'Business Action Bar, which can integrate many analysis components'
  }),
  icon: 'icon-tabs',
  cover: 'http://xxxx.jpg',
  category: 'container-components',
  type: 'GICC',
  docs: 'https://www.yuque.com/antv/gi/egap0htkd75mo950'
};
export default {
  info: info,
  component: Component,
  registerMeta: registerMeta
};