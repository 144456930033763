function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import locale from '@aligov/global-locale';
import stringFormat from '@aligov/global-string-format';
import strings from './strings';
var language; // Current language
var intl; // Instance of intl-universal. Create by provideIntl
function update() {
  var lang = 'en-US';
  language = lang;
  intl = stringFormat.init(lang, strings, {
    name: 'scene'
  });
}
function change(langTag) {
  locale.setLang(langTag);
  update();
}
function get(id) {
  var variable = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!intl) update();
  if (typeof id === 'string') {
    return stringFormat.format({
      id: id
    }, variable, {
      name: 'scene'
    });
  } else if (_typeof(id) === 'object' && id.dm) {
    id.defaultMessage = id.dm;
  }
  return stringFormat.format({
    id: id.id,
    defaultString: id.dm
  }, variable, {
    name: 'scene'
  });
}
export default {
  get: get,
  update: update,
  change: change,
  language: language
};