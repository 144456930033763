import $i18n from '../../i18n';
var registerMeta = function registerMeta(context) {
  return {
    rows: {
      type: 'number',
      title: $i18n.get({
        id: 'basic.layouts.Grid.registerMeta.NumberOfGridRows',
        dm: 'Number of grid rows'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {
        step: 1
      }
    },
    cols: {
      type: 'slider',
      title: $i18n.get({
        id: 'basic.layouts.Grid.registerMeta.NumberOfGridColumns',
        dm: 'Number of grid columns'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {
        step: 1
      }
    },
    sortBy: {
      type: 'select',
      title: $i18n.get({
        id: 'basic.layouts.Grid.registerMeta.Sort',
        dm: 'Sort'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        options: [{
          label: 'null',
          value: null
        }, {
          label: 'topology',
          value: 'topology'
        }, {
          label: 'degree',
          value: 'degree'
        }]
      },
      default: null
    }
  };
};
export default registerMeta;