import Component from './Component';
import registerMeta from './registerMeta';
import $i18n from '../../i18n';
var info = {
  id: 'ToggleClusterWithMenu',
  name: $i18n.get({
    id: 'basic.components.ToggleClusterWithMenu.ExpandFoldUp',
    dm: 'Expand/fold up'
  }),
  desc: $i18n.get({
    id: 'basic.components.ToggleClusterWithMenu.IntegrateInTheRightClick',
    dm: 'Integrate in the right-click menu, expand/collapse the node'
  }),
  icon: 'icon-toggle',
  cover: 'http://xxx.jpg',
  category: 'elements-interaction',
  type: 'GIAC_MENU',
  docs: 'https://www.yuque.com/antv/gi/wdg1kfpf0y7f77q3'
};
export default {
  info: info,
  component: Component,
  registerMeta: registerMeta
};