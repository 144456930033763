import $i18n from '../../i18n';
var registerMeta = function registerMeta(context) {
  return {
    rankdir: {
      type: 'string',
      title: $i18n.get({
        id: 'basic.layouts.Dagre.registerMeta.LayoutDirection',
        dm: 'Layout direction'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        options: [{
          label: $i18n.get({
            id: 'basic.layouts.Dagre.registerMeta.TopDown',
            dm: 'Top-down'
          }),
          value: 'TB'
        }, {
          label: $i18n.get({
            id: 'basic.layouts.Dagre.registerMeta.BottomUp',
            dm: 'Bottom-up'
          }),
          value: 'BT'
        }, {
          label: $i18n.get({
            id: 'basic.layouts.Dagre.registerMeta.FromLeftToRight',
            dm: 'From left to right'
          }),
          value: 'LR'
        }, {
          label: $i18n.get({
            id: 'basic.layouts.Dagre.registerMeta.FromRightToLeft',
            dm: 'From right to left'
          }),
          value: 'RL'
        }]
      },
      default: 'TB'
    },
    align: {
      type: 'string',
      title: $i18n.get({
        id: 'basic.layouts.Dagre.registerMeta.Alignment',
        dm: 'Alignment'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        options: [{
          label: 'Null',
          value: null
        }, {
          label: 'UL',
          value: 'UL'
        }, {
          label: 'UR',
          value: 'UR'
        }, {
          label: 'DL',
          value: 'DL'
        }, {
          label: 'DR',
          value: 'DR'
        }]
      },
      default: null
    },
    nodesep: {
      type: 'number',
      title: $i18n.get({
        id: 'basic.layouts.Dagre.registerMeta.NodeSpacing',
        dm: 'Node spacing'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {
        step: 1
      },
      default: 40
    },
    ranksep: {
      type: 'number',
      title: $i18n.get({
        id: 'basic.layouts.Dagre.registerMeta.LayerSpacing',
        dm: 'Layer spacing'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      'x-component-props': {
        step: 1
      },
      default: 80
    }
  };
};
export default registerMeta;