function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import $i18n from '../i18n';
export var isObjectEmpty = function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
};
/**
 * 通过 graphData 生成 Schema
 * @param graphData 图数据
 */
export var generatorSchemaByGraphData = function generatorSchemaByGraphData(graphData, defaultOptions) {
  var nodes = graphData.nodes,
    edges = graphData.edges;
  var nodeSchemas = [];
  var edgeSchemas = [];
  var _ref = defaultOptions || {},
    _ref$nodeType = _ref.nodeType,
    defaultNodeType = _ref$nodeType === void 0 ? 'UNKNOW' : _ref$nodeType,
    _ref$edgeType = _ref.edgeType,
    defaultEdgeType = _ref$edgeType === void 0 ? 'UNKNOW' : _ref$edgeType,
    defaultNodeTypeFromProperties = _ref.nodeTypeFromProperties,
    defaultEdgeTypeFromProperties = _ref.edgeTypeFromProperties;
  nodes.forEach(function (node) {
    var _node$nodeType = node.nodeType,
      nodeType = _node$nodeType === void 0 ? defaultNodeType : _node$nodeType,
      data = node.data,
      _node$nodeTypeKeyFrom = node.nodeTypeKeyFromProperties,
      nodeTypeKeyFromProperties = _node$nodeTypeKeyFrom === void 0 ? defaultNodeTypeFromProperties : _node$nodeTypeKeyFrom;
    var nodeSchema = {
      nodeType: nodeType,
      nodeTypeKeyFromProperties: nodeTypeKeyFromProperties,
      properties: {}
    };
    for (var key in data) {
      if (_typeof(data[key]) === 'object') {
        // 说明包括的是一个对象，需要再把里面的属性值解构出来
        for (var subKey in data[key]) {
          // 只处理这一层的非 object 的字段，其他的不再作为 schema 的属性
          if (_typeof(data[key][subKey]) !== 'object') {
            nodeSchema.properties["".concat(key, ".").concat(subKey)] = _typeof(data[key][subKey]);
          }
        }
      } else {
        nodeSchema.properties[key] = _typeof(data[key]);
      }
    }
    var hasCurrent = nodeSchemas.find(function (schema) {
      return schema.nodeType === nodeType;
    });
    if (!hasCurrent) {
      nodeSchemas.push(nodeSchema);
    }
  });
  edges.forEach(function (edge) {
    var _edge$edgeType = edge.edgeType,
      edgeType = _edge$edgeType === void 0 ? defaultEdgeType : _edge$edgeType,
      source = edge.source,
      target = edge.target,
      data = edge.data,
      _edge$edgeTypeKeyFrom = edge.edgeTypeKeyFromProperties,
      edgeTypeKeyFromProperties = _edge$edgeTypeKeyFrom === void 0 ? defaultEdgeTypeFromProperties : _edge$edgeTypeKeyFrom;
    var edgeSchema = {
      edgeType: edgeType,
      edgeTypeKeyFromProperties: edgeTypeKeyFromProperties,
      sourceNodeType: '',
      targetNodeType: '',
      properties: {}
    };
    // 获取当前 source 对应的 nodeType
    var currentSource = nodes.find(function (node) {
      return node.id === source;
    });
    var currentTarget = nodes.find(function (node) {
      return node.id === target;
    });
    if (!currentSource) {
      console.warn('数据不合法,找不到 Source ID：', source);
    }
    if (!currentTarget) {
      console.warn('数据不合法,找不到 Target ID：', target);
    }
    if (currentSource && currentTarget) {
      edgeSchema.sourceNodeType = currentSource.nodeType;
      edgeSchema.targetNodeType = currentTarget.nodeType;
    }
    for (var key in data) {
      if (_typeof(data[key]) === 'object') {
        // 说明包括的是一个对象，需要再把里面的属性值解构出来
        for (var subKey in data[key]) {
          // 只处理这一层的非 object 的字段，其他的不再作为 schema 的属性
          if (_typeof(data[key][subKey]) !== 'object') {
            edgeSchema.properties["".concat(key, ".").concat(subKey)] = _typeof(data[key][subKey]);
          }
        }
      } else {
        edgeSchema.properties[key] = _typeof(data[key]);
      }
    }
    var hasCurrent = edgeSchemas.find(function (schema) {
      return schema.edgeType === edgeType;
    });
    if (!hasCurrent) {
      edgeSchemas.push(edgeSchema);
    }
  });
  return {
    nodes: nodeSchemas,
    edges: edgeSchemas
  };
};
export var COLORS = ['#3056E3', '#faad14', '#a0d911', '#f5222d', '#722ed1', '#eb2f96', '#2f54eb', '#13c2c2', '#52c41a', '#fadb14', '#fa8c16', '#820014', '#873800', '#874d00', '#876800', '#3f6600', '#135200', '#00474f', '#003a8c', '#061178', '#22075e', '#780650'];
var colorMap = new Map();
var defaultNodeConfig = {
  id: 'SimpleNode',
  props: {
    size: 26,
    color: '#ddd',
    label: []
  },
  name: $i18n.get({
    id: 'sdk.src.process.schema.OfficialNode',
    dm: '官方节点'
  }),
  order: -1,
  expressions: [],
  logic: true,
  groupName: $i18n.get({
    id: 'sdk.src.process.schema.DefaultStyle',
    dm: '默认样式'
  })
};
var defaultEdgeConfig = {
  id: 'SimpleEdge',
  props: {
    size: 1,
    color: '#ddd',
    label: []
  },
  name: $i18n.get({
    id: 'sdk.src.process.schema.OfficialSide',
    dm: '官方边'
  }),
  order: -1,
  expressions: [],
  logic: true,
  groupName: $i18n.get({
    id: 'sdk.src.process.schema.DefaultStyle',
    dm: '默认样式'
  })
};
export var generatorStyleConfigBySchema = function generatorStyleConfigBySchema(schema) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _ref2 = schema || {},
    _ref2$nodes = _ref2.nodes,
    nodes = _ref2$nodes === void 0 ? [] : _ref2$nodes,
    _ref2$edges = _ref2.edges,
    edges = _ref2$edges === void 0 ? [] : _ref2$edges,
    _ref2$meta = _ref2.meta,
    meta = _ref2$meta === void 0 ? {
      defaultLabelField: 'id'
    } : _ref2$meta;
  var defaultLabelField = meta.defaultLabelField;
  var hasUnkownNodeType = false;
  var hasUnkownEdgeType = false;
  var nodesConfig = nodes.map(function (c, index) {
    var COLOR_INDEX = index % COLORS.length;
    colorMap.set(c.nodeType, COLORS[COLOR_INDEX]);
    if (c.nodeType === 'UNKNOW') {
      hasUnkownNodeType = true;
      return Object.assign({}, defaultNodeConfig);
    }
    return {
      id: 'SimpleNode',
      props: {
        size: 26,
        color: COLORS[COLOR_INDEX] || '#ddd',
        label: ["".concat(c.nodeType, "^^").concat(defaultLabelField)]
        // label:[[c.nodeType,defaultLabelField]]
      },
      name: $i18n.get({
        id: 'sdk.src.process.schema.OfficialNode',
        dm: '官方节点'
      }),
      expressions: [{
        name: c.nodeTypeKeyFromProperties,
        operator: 'eql',
        value: c.nodeType
      }],
      order: index,
      logic: true,
      groupName: "".concat(String(c.nodeType).toUpperCase(), " TYPE")
    };
  }).sort(function (a, b) {
    return a.order - b.order;
  });
  var edgesConfig = edges.map(function (c, index) {
    if (c.edgeType === 'UNKNOW') {
      hasUnkownEdgeType = true;
      return Object.assign({}, defaultEdgeConfig);
    }
    return {
      id: 'SimpleEdge',
      props: {
        size: 1,
        color: colorMap.get(c.sourceNodeType) || '#ddd',
        label: []
      },
      name: $i18n.get({
        id: 'sdk.src.process.schema.OfficialSide',
        dm: '官方边'
      }),
      expressions: [{
        name: c.edgeTypeKeyFromProperties,
        operator: 'eql',
        value: c.edgeType
      }],
      order: index,
      logic: true,
      groupName: "".concat(String(c.edgeType).toUpperCase(), " TYPE")
    };
  }).sort(function (a, b) {
    return a.order - b.order;
  });
  var nodesCfg = nodesConfig;
  var edgesCfg = edgesConfig;
  if (!hasUnkownNodeType) {
    nodesCfg = [defaultNodeConfig].concat(_toConsumableArray(nodesConfig));
  }
  if (!hasUnkownEdgeType) {
    edgesCfg = [defaultEdgeConfig].concat(_toConsumableArray(edgesConfig));
  }
  return Object.assign(Object.assign({}, config), {
    nodes: nodesCfg,
    edges: edgesCfg
  });
};
var mergeElementConfig = function mergeElementConfig(curr, prev) {
  var prevMap = new Map();
  prev.forEach(function (c) {
    var id = JSON.stringify(c.expressions);
    prevMap.set(id, c);
  });
  return curr.map(function (c) {
    var id = JSON.stringify(c.expressions);
    var prev = prevMap.get(id);
    if (prev) {
      return prev;
    } else {
      return c;
    }
  });
};
/**
 * 通过缓存策略，将之前的Config配置作用在新的Config上
 * @param curr 当前新产生的 NodesConfig or EdgeConfig
 * @param prev 之前的 NodesConfig or EdgeConfig
 * @returns
 */
export var mergeStyleConfig = function mergeStyleConfig(curr, prev) {
  var nodes = mergeElementConfig(curr.nodes, prev.nodes);
  var edges = mergeElementConfig(curr.edges, prev.edges);
  return {
    nodes: nodes,
    edges: edges
  };
};