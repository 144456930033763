function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var defaultProps = {
  donut: [],
  label: ['id'],
  donutColors: '',
  size: undefined
};
/** 数据映射函数  需要根据配置自动生成*/
var transform = function transform(nodes, nodeConfig) {
  try {
    /** 解构配置项 */
    var props = Object.assign({}, defaultProps, nodeConfig.props);
    var dountKeys = props.donut,
      labelKey = props.label,
      donutColors = props.donutColors,
      SIZE = props.size;
    var colorKeys = ['#61DDAA', '#F08BB4', '#65789B'];
    if (typeof donutColors === 'string') {
      colorKeys = donutColors.split(',') || ['#61DDAA', '#F08BB4', '#65789B'];
    }
    var donutColorMap = dountKeys.reduce(function (acc, curr, index) {
      return Object.assign(Object.assign({}, acc), _defineProperty({}, curr, colorKeys[index]));
    }, {});
    var transNodes = nodes.map(function (node) {
      var id = node.id;
      var data = node.data || node;
      /**  构造 donutAttrs */
      var donutAttrs = dountKeys.reduce(function (acc, curr) {
        return Object.assign(Object.assign({}, acc), _defineProperty({}, curr, data[curr]));
      }, {});
      var donutSumCount = dountKeys.reduce(function (acc, curr) {
        return acc + data[curr];
      }, 16);
      var size = SIZE || Math.sqrt(donutSumCount) * 5;
      return {
        id: id,
        data: data,
        type: 'donut',
        label: data[labelKey],
        donutAttrs: donutAttrs,
        donutColorMap: donutColorMap,
        size: size,
        /** G6 */
        style: {
          lineWidth: 0
        },
        labelCfg: {
          position: 'bottom'
        }
      };
    });
    return transNodes;
  } catch (error) {
    console.error('parse transform error:', error);
    return nodes;
  }
};
export default transform;